import {GrowthBook} from '@growthbook/growthbook';
import {isMobile} from './util/is-mobile';
import {getMParticleEventAttributes} from './integrations/mparticle';
import type {Context} from '@growthbook/growthbook';

import type {GrowthBookAttributes, TreatwellAttributes} from './types';
import type {MParticleIntegration} from './integrations/mparticle';
import {log} from './util/log';

export {getDeviceId} from './util/device-id';

export type {GrowthBookAttributes, TreatwellAttributes};

export type Options = {
  /**
   * Provide attributes to be set on the GrowthBook client.
   * These are defined in the GrowthBook UI.
   */
  growthbookAttributes?: GrowthBookAttributes;

  /**
   * Provide an integration to send activation events to a third-party service.
   * Currently only mParticle is supported.
   */
  activationEvent?: MParticleIntegration;
};

export async function growthbookInitialiseClient(
  growthbookContext: Context,
  deviceId: string,
  {growthbookAttributes = {}, activationEvent}: Options = {},
) {
  const isMobileDevice = isMobile(navigator.userAgent);
  const attributes: TreatwellAttributes = {
    ...growthbookAttributes.channel,
    ...growthbookAttributes.user,
    growthbook_id: deviceId,
    platform: 'web',
    is_web_mobile: isMobileDevice,
  };
  if (activationEvent) {
    growthbookContext.trackingCallback = (experiment, result) => {
      // call the activation event for the mParticle integration
      if (activationEvent.type === 'mparticle') {
        const eventAttributes = getMParticleEventAttributes(
          experiment.key,
          result.key,
          attributes,
        );
        activationEvent.send(eventAttributes);
        if (growthbookContext.debug) {
          log(
            experiment.key,
            `${result.name} (key ${result.key})`,
            eventAttributes,
          );
        }
      }
    };
  }

  if (localStorage.getItem('growthbook_debug') === 'true') {
    growthbookContext.debug = true;
  }

  const growthbook = new GrowthBook(growthbookContext);
  growthbook.setAttributes(attributes);

  // give GrowthBook two seconds to initialise. In case of error it does not throw
  // but instead just sets all features etc to null
  // https://docs.growthbook.io/lib/js#error-handling
  await growthbook.init({timeout: 2000});
  return growthbook;
}
