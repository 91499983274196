import React from 'react';
import { IconUser, MediaObject, Viewport, VisuallyHidden } from '@treatwell/ui';
import clsx from 'clsx';
import styles from './styles.module.css';
import { trackHeaderLink, HeaderLink } from './tracking';

interface Link {
  label: string;
  uri: string;
}
export interface Links {
  business?: Link;
  connect?: Link;
  login?: Link;
  account?: Link;
}

export interface Props {
  isAuthenticated?: boolean | null;
  isConnectAccount?: boolean;
  links: Links;
  onLoginLinkClick?: () => void;
}

export function HeaderLinks(props: Props): React.ReactElement | null {
  const {
    isAuthenticated = null,
    isConnectAccount = false,
    links,
    onLoginLinkClick,
  } = props;

  function track(event: React.MouseEvent, callback?: () => void): void {
    event.preventDefault();
    const linkType = (event.target as HTMLAnchorElement).dataset.linkType;

    if (linkType) {
      trackHeaderLink(linkType as HeaderLink);
    }

    if (callback) {
      callback();
    } else {
      window.location.href = (event.target as HTMLAnchorElement).href;
    }
  }

  function renderHeaderLink(
    text: string,
    href: string,
    linkType: HeaderLink,
    styleClass: string,
    onClick: (event: React.MouseEvent) => void
  ): React.ReactNode {
    return (
      <a
        href={href}
        className={clsx(styleClass, styles.link)}
        data-link-type={linkType}
        onClick={onClick}
      >
        {text}
      </a>
    );
  }

  function createTrackingFunction(
    callback?: () => void
  ): (event: React.MouseEvent) => void {
    return function trackingFunction(event: React.MouseEvent): void {
      track(event, callback);
    };
  }

  function renderNonAuthenticated(): React.ReactNode {
    return (
      <React.Fragment>
        <Viewport device={['desktop', 'tablet']}>
          {links.business &&
            renderHeaderLink(
              links.business.label,
              links.business.uri,
              HeaderLink.Business,
              styles.business,
              createTrackingFunction()
            )}
        </Viewport>
        {links.login &&
          renderHeaderLink(
            links.login.label,
            links.login.uri,
            HeaderLink.Login,
            styles.login,
            createTrackingFunction(onLoginLinkClick)
          )}
      </React.Fragment>
    );
  }

  function renderMyAccountLink(): React.ReactNode {
    if (!links.account) {
      return;
    }

    const className = isConnectAccount
      ? styles.connectMyBookings
      : styles.myAccount;

    const headerLink = isConnectAccount
      ? HeaderLink.Connect
      : HeaderLink.Account;

    return (
      <>
        <Viewport device={['tablet', 'desktop']}>
          <a
            href={links.account.uri}
            className={clsx(className, styles.link)}
            data-link-type={headerLink}
            onClick={() => createTrackingFunction()}
          >
            {isConnectAccount ? (
              <>{links.account.label}</>
            ) : (
              <MediaObject align="center" space="sm" reverse>
                <IconUser size={24} />
                <div>{links.account.label}</div>
              </MediaObject>
            )}
          </a>
        </Viewport>
        <Viewport device="mobile">
          <a
            href={links.account.uri}
            className={clsx(className)}
            data-link-type={headerLink}
            onClick={() => createTrackingFunction()}
          >
            <VisuallyHidden>{links.account.label}</VisuallyHidden>
            <IconUser size={24} />
          </a>
        </Viewport>
      </>
    );
  }

  function renderConnect(): React.ReactNode {
    if (!isConnectAccount) {
      return null;
    }

    if (!links.connect) {
      return;
    }

    return renderHeaderLink(
      links.connect.label,
      links.connect.uri,
      HeaderLink.Connect,
      styles.connect,
      createTrackingFunction()
    );
  }

  function renderAuthenticated(): React.ReactNode {
    return (
      <React.Fragment>
        <Viewport device={['desktop', 'tablet']}>{renderConnect()}</Viewport>
        {renderMyAccountLink()}
      </React.Fragment>
    );
  }

  // Authentication status is derived asynchronously.
  // To prevent flicker, we wait until we have the
  // authenication status before rendering links
  if (isAuthenticated === null) {
    return null;
  }

  const headerLinks = isAuthenticated
    ? renderAuthenticated()
    : renderNonAuthenticated();

  return <div className={styles.headerLinks}>{headerLinks}</div>;
}
