import { StateData } from 'js/model/rainbow/StateData';
import { i18nFunction } from '../LocaleWrapper';

export const getHeaderLinks = ({
  isBrowser,
  i18n,
  generateUri,
  pageData,
}: {
  isBrowser: boolean;
  i18n: i18nFunction;
  generateUri: any;
  pageData: StateData;
}) => {
  return {
    login: {
      label: i18n('common.header-links.login'),
      uri: `${generateUri('protectedAction')}?route=${
        isBrowser ? encodeURI(window.location.pathname) : ''
      }`,
    },
    business: {
      label: i18n('common.header-links.list-your-business-text'),
      uri: i18n('common.header-links.list-your-business-uri'),
    },
    connect: {
      label: i18n('common.header-links.go-to-connect'),
      uri: `${pageData.channel.connectDomain!.includes('http') ? '' : '//'}${
        pageData.channel.connectDomain
      }`,
    },
    account: {
      label: i18n('common.header-links.my-account'),
      uri: generateUri('account'),
    },
  };
};
