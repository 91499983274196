import { AccountRelevantReduxState } from 'js/redux-modules/account/AccountRelevantReduxState';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { BackButtonLabel } from 'js/model/state/layout';
import {
  AccountVerificationApiResponse,
  AccountVerificationApiError,
} from 'js/model/rainbow/AccountVerifyOutput';

import { layoutConfigUpdate } from 'js/redux-modules/layout/actions';
import { getLayoutConfig } from 'js/redux-modules/layout/selectors';
import { getAccountVerification } from 'js/redux-modules/account/selectors';

import { postAccountVerify } from 'js/service/account';

export const ACCOUNT_VERIFICATION_REQUEST = 'ACCOUNT_VERIFICATION_REQUEST';
export const ACCOUNT_VERIFICATION_SUCCESS = 'ACCOUNT_VERIFICATION_SUCCESS';
export const ACCOUNT_VERIFICATION_FAILURE = 'ACCOUNT_VERIFICATION_FAILURE';
export const ACCOUNT_VERIFICATION_CLEAR_ERROR =
  'ACCOUNT_VERIFICATION_CLEAR_ERROR';

export interface AccountVerificationRequestAction {
  type: typeof ACCOUNT_VERIFICATION_REQUEST;
  email: string;
}

export interface AccountVerificationSuccessAction {
  type: typeof ACCOUNT_VERIFICATION_SUCCESS;
  response: AccountVerificationApiResponse;
}

export interface AccountVerificationFailureAction {
  type: typeof ACCOUNT_VERIFICATION_FAILURE;
  error: AccountVerificationApiError;
}

export interface AccountVerificationClearErrorAction {
  type: typeof ACCOUNT_VERIFICATION_CLEAR_ERROR;
}

export type AccountVerificationAction =
  | AccountVerificationClearErrorAction
  | AccountVerificationRequestAction
  | AccountVerificationSuccessAction
  | AccountVerificationFailureAction;

export function accountVerificationClearError(): AccountVerificationClearErrorAction {
  return {
    type: ACCOUNT_VERIFICATION_CLEAR_ERROR,
  };
}

export function accountVerificationRequest(
  email: string
): ThunkAction<Promise<unknown>, AccountRelevantReduxState, unknown, Action> {
  return async (
    dispatch: ThunkDispatch<AccountRelevantReduxState, unknown, Action>,
    getState
  ) => {
    dispatch({ type: ACCOUNT_VERIFICATION_REQUEST, email });
    try {
      const response = await postAccountVerify(email);
      if (response && !response.shouldRedirectToLogin) {
        const layout = getLayoutConfig(getState());
        dispatch(
          layoutConfigUpdate({
            ...layout,
            header: {
              ...layout.header,
              backButton: {
                show: true,
                override: true,
                label: BackButtonLabel.Back,
              },
            },
          })
        );
      }
      return dispatch({ type: ACCOUNT_VERIFICATION_SUCCESS, response });
    } catch (error) {
      return dispatch({ type: ACCOUNT_VERIFICATION_FAILURE, error });
    }
  };
}

export function submitAccountVerification(
  email: string
): ThunkAction<Promise<unknown>, AccountRelevantReduxState, unknown, Action> {
  return async (
    dispatch: ThunkDispatch<AccountRelevantReduxState, unknown, Action>,
    getState
  ) => {
    const verify = getAccountVerification(getState());
    if (!verify.isSubmitting && !verify.isSubmitted) {
      return dispatch(accountVerificationRequest(email));
    }
  };
}
