import * as React from 'react';

import {useToast} from './ToastHook';
import {ToastContextProvider} from './ToastContext';

type Props = {
  children: React.ReactNode;
};

const ToastProvider = (props: Props) => (
  <ToastContextProvider {...props} value={useToast()} />
);

export default ToastProvider;
