import React, {useContext, createContext} from 'react';

export type Flag = {
  name: string;
  enabled: boolean;
};

type ProviderProps = {
  features?: Flag[];
  children: React.ReactNode;
};

type HookProps = {
  enabledFlag: string | string[];
};

type FeatureFlagProps = HookProps & {
  children: React.ReactNode;
};

const FeatureFlagContext = createContext<Flag[] | null>(null);

const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);
  if (context === null) {
    throw new Error(
      'useFeatureFlagContext must be used within a FeatureFlagProvider'
    );
  }
  return context;
};

export const FeatureFlagProvider = ({
  children,
  features = [],
}: ProviderProps) => {
  return (
    <FeatureFlagContext.Provider value={features}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = ({enabledFlag}: HookProps): boolean => {
  const availableFlags = useFeatureFlagContext();
  if (!availableFlags.length) {
    return false;
  }

  const flagsToCompare = Array.isArray(enabledFlag)
    ? enabledFlag
    : [enabledFlag];
  const filteredAgainstAvailableFlags = availableFlags.filter((availableFlag) =>
    flagsToCompare.includes(availableFlag.name)
  );

  const isFeatureActive = filteredAgainstAvailableFlags.length
    ? availableFlags.every((availableFlag) => availableFlag.enabled)
    : false;

  return isFeatureActive;
};

export const FeatureFlag = ({enabledFlag, children}: FeatureFlagProps) => {
  const isFeatureActive = useFeatureFlag({enabledFlag});
  if (!isFeatureActive) {
    return null;
  }
  return <>{children}</>;
};

FeatureFlagProvider.displayName = 'FeatureFlagProvider';
FeatureFlag.displayName = 'FeatureFlag';
