import { CreateReviewState } from 'js/model/state/createReview';
import { CreateReviewAction } from 'js/redux-modules/create-review/actions';
import { dataReducer } from 'js/redux-modules/create-review/reducers/dataReducer';
import { userReducer } from 'js/redux-modules/create-review/reducers/userReducer';
import { transactionalReducer } from 'js/redux-modules/create-review/reducers/transactionalReducer';

/**
 * The naming of this function can be confusing.
 * This is NOT a function creating a reducer, but
 * the reducer for the "create review" page.
 */
export function createReviewReducer(
  state: CreateReviewState | undefined,
  action: CreateReviewAction
): CreateReviewState {
  return {
    data: dataReducer(state?.data, action),
    user: userReducer(
      state?.user,
      action,
      state?.data.treatmentCategories.length,
      !state?.data.employee
    ),
    transactional: transactionalReducer(state?.transactional, action),
  };
}
