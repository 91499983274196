import React from 'react';
import clsx from 'clsx';

import {Text} from '../../Atoms/Text';
import {Icon, IconClose} from '../../Atoms/Icon';
import styles from './Toast.module.css';
import {Dismiss, Toast as ToastProps} from './types';
import {Message, Props as MessageProps} from '../Message/Message';
import {VisuallyHidden} from '../../Atoms';
import {Spacing} from '@treatwell/design-tokens';

type Props = ToastProps & {
  dismiss: Dismiss;
  icon?: Icon;
  reverse?: boolean;
  justify?: MessageProps['justify'];
  closeLabel?: string;
  space?: Spacing;
  className?: string;
} & CanDismissProps;

export type CanDismissProps =
  | {
      canDismiss: true;
      onDismiss?: () => void;
      children: React.ReactNode;
    }
  | {
      canDismiss?: false;
      onDismiss?: never;
      children?: never;
    };

export const Toast = ({
  type,
  message,
  dismiss,
  id,
  icon,
  reverse = true,
  justify = 'between',
  timeout = 4000,
  canDismiss = false,
  onDismiss,
  children,
  closeLabel = 'Close',
  space = 'xs',
  className,
}: Props) => {
  React.useEffect(() => {
    {
      let timer: number;
      if (!canDismiss) {
        timer = window.setTimeout(() => dismiss(id), timeout);
      }
      return () => clearTimeout(timer);
    }
  }, [canDismiss, dismiss, timeout]);

  return (
    <div
      className={className ? clsx(styles.wrapper, className) : styles.wrapper}
      data-cy="Toast"
    >
      <Message
        icon={icon}
        className={clsx(styles.root, styles[type])}
        reverse={reverse}
        justify={justify}
        space={space}
        cta={
          canDismiss ? (
            <button
              className={styles.closeButton}
              onClick={() => {
                dismiss(id);
                onDismiss?.();
              }}
              data-cy="CloseToast"
            >
              <VisuallyHidden>{closeLabel}</VisuallyHidden>
              <IconClose className={styles.closeIcon} size={24} />
            </button>
          ) : undefined
        }
      >
        {canDismiss ? (
          children
        ) : (
          <Text as="p" type="caption">
            {message}
          </Text>
        )}
      </Message>
    </div>
  );
};

Toast.displayName = 'Toast';
