import React from 'react';
import clsx from 'clsx';
import styles from './NavigationTab.module.css';
import { TrackMerchSlot } from './tracking';
import { Tabs } from './Navigation';

interface Props {
  selectedTab: number | null;
  index: number;
  toggleNavigationTab: (
    selectedTabIndex: number | null,
    newSelectedTabIndex: number | null,
    newSelectedTabLabel: string | null
  ) => void;
  uriLocationReference: string;
  tab: Tabs;
  merchSlotNumber?: number;
}

export function NavigationTab(props: Props): React.ReactElement {
  const linkRef = React.createRef<HTMLAnchorElement>();

  const getScrollOffset = (el: HTMLElement, parentEl: HTMLElement): number => {
    if (!window && !document) {
      return 0;
    }

    const rect = el.getBoundingClientRect();
    const parentRect = parentEl.getBoundingClientRect();
    const offset = parentRect.left;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const parentPaddingLeft = 16;

    if (rect.left - offset >= 0 && rect.right + offset <= windowWidth) {
      return 0;
    }
    if (rect.left < 0) {
      return rect.left - offset - parentPaddingLeft;
    }
    return offset + (rect.left + rect.width) - windowWidth + parentPaddingLeft;
  };

  const handleClick = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!linkRef.current || !linkRef.current.parentElement) {
      return;
    }

    const parentEl = linkRef.current.parentElement.parentElement;

    if (parentEl) {
      // scroll tab into view if partially offscreen
      parentEl.scrollLeft += getScrollOffset(linkRef.current, parentEl);
    }

    if (!shouldDisplayChildItems() && props.tab.uri && props.merchSlotNumber) {
      const redirectUri = (event.target as HTMLAnchorElement).href;
      await TrackMerchSlot(props.merchSlotNumber, linkRef.current.innerText);
      window.location.href = redirectUri;
    } else {
      props.toggleNavigationTab(
        props.selectedTab,
        props.index,
        props.tab.label
      );
    }
  };

  const generateLocationURI = (
    uriTemplate: string,
    locationOrPostalRef?: string
  ) => {
    const locationRef = locationOrPostalRef || '';
    const uri = uriTemplate.replace('{{locationOrPostalRef}}', locationRef);

    return uri.replace('//', '/');
  };

  const shouldDisplayChildItems = (): boolean => {
    return (
      props.tab['child-items-enabled'] === 'true' &&
      props.tab['child-items'].length > 0
    );
  };

  const active = props.selectedTab === props.index;
  const singleClass = shouldDisplayChildItems() ? '' : styles.single;
  const activeClass = active ? styles.active : '';

  return (
    <a
      ref={linkRef}
      key={props.index}
      className={clsx(styles.link, singleClass, activeClass)}
      href={generateLocationURI(props.tab.uri, props.uriLocationReference)}
      onClick={handleClick}
    >
      {props.tab.label}
    </a>
  );
}
