import { MapVenue } from 'js/model/view/map-view/MapVenue';

// actions
enum actionType {
  SET_SELECTED_MAP_VENUE = 'SET_SELECTED_MAP_VENUE',
  SET_HOVERED_MAP_VENUE = 'SET_HOVERED_MAP_VENUE',
}

interface SetSelectedMapVenue {
  type: actionType;
  mapVenue: MapVenue | null;
}

type SetHoveredMapVenue = SetSelectedMapVenue;

export const setSelectedMapVenue = (
  mapVenue: MapVenue | null
): SetSelectedMapVenue => ({
  type: actionType.SET_SELECTED_MAP_VENUE,
  mapVenue,
});

export const setHoveredMapVenue = (
  mapVenue: MapVenue | null
): SetSelectedMapVenue => ({
  type: actionType.SET_HOVERED_MAP_VENUE,
  mapVenue,
});

export type VenuesModuleAction = SetSelectedMapVenue | SetHoveredMapVenue;

// state
interface VenuesModuleState {
  selectedMapVenue: MapVenue | null;
  hoveredMapVenue: MapVenue | null;
}

const initialState: VenuesModuleState = {
  selectedMapVenue: null,
  hoveredMapVenue: null,
};

// reducer
export const venuesModule = (
  state = initialState,
  action: VenuesModuleAction
): VenuesModuleState => {
  switch (action.type) {
    case actionType.SET_SELECTED_MAP_VENUE:
      return { ...state, selectedMapVenue: action.mapVenue };
    case actionType.SET_HOVERED_MAP_VENUE:
      return { ...state, hoveredMapVenue: action.mapVenue };

    default:
      return state;
  }
};
