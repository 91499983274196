import { LocationTree } from 'js/model/rainbow/LocationTree';
import { assertUnreachable } from 'js/types/assert-unreachable';

const NEW_LINE_CHANNEL_CODES = ['WHN_GB', 'WHN_IE', 'WDG_GB', 'WDG_IE'];

export interface FormatterChannelData {
  decimalSeparator?: string;
  country?: LocationTree;
}

export function formatNumber(
  numberValue: number | string,
  decimals = 0,
  channelData: FormatterChannelData
): string {
  // TODO can numberValue really be a string?
  const separator = channelData.decimalSeparator || '.';
  const numberNumber: number =
    typeof numberValue === 'number' ? numberValue : parseFloat(numberValue);
  const roundedNumber = numberNumber.toFixed(decimals);
  const formattedNumber = roundedNumber.replace('.', separator);

  return `${formattedNumber}`;
}

export function formatDistance(
  distance: number,
  channelData: FormatterChannelData
): string {
  if (distance < 0.1) {
    return formatNumber(0.1, 1, channelData);
  }
  if (distance < 5) {
    return formatNumber(distance, 1, channelData);
  }
  return formatNumber(Math.round(distance), 0, channelData);
}

export function formatAddress(
  address: string[],
  postalCode: string | undefined,
  postalCodePosition: 'newline' | 'prefix'
): string[] {
  if (postalCode === undefined) {
    return address;
  }

  if (postalCodePosition === 'newline') {
    return [...address, postalCode];
  }
  if (postalCodePosition === 'prefix') {
    return [
      ...address.slice(0, -1),
      postalCode +
        (address.length > 0 ? ` ${address[address.length - 1]}` : ''),
    ];
  }

  return assertUnreachable(postalCodePosition);
}

export function formatAddressInline(
  address?: string[],
  postalCode?: string,
  channelCode?: string
): string | null {
  if (!address) {
    return null;
  }

  const isNewLinePostCode =
    channelCode && NEW_LINE_CHANNEL_CODES.indexOf(channelCode) > -1;
  const postCode = postalCode || '';
  const isNewLine = isNewLinePostCode || address.length === 1;
  const lastLine = address[address.length - 1];
  const finalAddress = address.slice(0, address.length - 1);

  if (isNewLine) {
    finalAddress.push(lastLine);

    if (postCode) {
      finalAddress.push(postCode);
    }
  } else {
    finalAddress.push(`${postCode} ${lastLine}`);
  }

  return finalAddress.join(', ');
}

// TODO passing in string to hours?
function getHourText(
  hours: number | string,
  cms: { hour: string; hours: string }
): string {
  if (hours === 1) {
    return cms.hour;
  }
  if (hours !== '') {
    return cms.hours;
  }
  return '';
}

export function formatDuration(
  duration: number,
  cms: { hour: string; hours: string; minutes: string }
): string {
  const m = duration;
  const hoursDecimal = m / 60;
  const h = Math.floor(hoursDecimal);
  const hours = h > 0 ? h : '';
  const hourText = getHourText(hours, cms);
  const minuteText = cms.minutes;
  const minutes = Math.round((hoursDecimal % 1) * 60);
  const minutesAndText = minutes > 0 ? `${minutes} ${minuteText}` : '';

  return `${hours} ${hourText} ${minutesAndText}`;
}

export function camelToSnake(value: string): string {
  return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function snakeToCamel(value: string): string {
  return value
    .toLowerCase()
    .replace(/([-|_][a-z])/g, s => s.replace(/(-|_)/g, '').toUpperCase());
}

export function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '');
}

export function getDurationCms(
  i18n: (key: string) => string
): { minutes: string; hour: string; hours: string } {
  return {
    minutes: i18n('venue.menu.labels.minutes'),
    hour: i18n('venue.menu.labels.hour'),
    hours: i18n('venue.menu.labels.hours'),
  };
}
