import { RequestData } from 'js/helpers/service';
import { StateData } from 'js/model/rainbow/StateData';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';

export function getPageType({ error, pageType }: RequestData): string {
  return (!error && pageType) || (error?.notFound ? '404' : '500');
}

export function getIsWidget({ channel }: RequestData): boolean {
  return channel && channel.type === 'widget';
}

export function getVenueData({ venue }: StateData): VenueOutput {
  return venue && venue.venue;
}
