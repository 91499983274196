import React from 'react';
import clsx from 'clsx';
import {Spacing} from '@treatwell/design-tokens';

import styles from './Stack.module.css';
import {Base, BaseProps} from '../../Internal/Base';
import {
  justifyMap,
  alignMap,
  Props as InlineProps,
  AllowedTags,
  defaultTag,
} from '../Inline/Inline';

export type StackProps<T extends AllowedTags = typeof defaultTag> = {
  children: React.ReactNode;
  fullHeight?: boolean;
  /** Sets the space between elements based on values from the design-tokens by default. */
  space?: Spacing;
} & Pick<InlineProps, 'justify' | 'align'> &
  BaseProps<T>;

export const Stack = <T extends AllowedTags>({
  space,
  children,
  className,
  fullHeight = false,
  justify = 'start',
  align = 'normal',
  ...restProps
}: StackProps<T>) => {
  const justifyContent = justifyMap[justify];
  const alignItems = alignMap[align];
  const stackClassName = clsx(
    styles.stack,
    className,
    space && styles[space],
    alignItems,
    justifyContent,
    fullHeight && styles.fullHeight
  );
  return (
    <Base className={stackClassName} {...restProps}>
      {children}
    </Base>
  );
};

Stack.displayName = 'Stack';
