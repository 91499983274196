import React, { createContext, useContext } from 'react';

export const LOYALTY_BANNER_DISMISSED_COOKIE = 'loyalty-banner-dismissed';

const CookieContext = createContext<string | Record<string, string> | null>(
  null
);

export const CookieProvider = ({
  cookies,
  children,
}: {
  cookies: string;
  children: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <CookieContext.Provider value={cookies}>{children}</CookieContext.Provider>
  );
};

export const useCookieContext = ({
  cookieKey,
}: { cookieKey?: string } = {}) => {
  const context = useContext(CookieContext);
  if (context === null) {
    throw new Error('useCookieContext must be used within a CookieProvider');
  }

  if (!cookieKey) {
    return context;
  }
  const cookieName = `${cookieKey}=`;
  if (typeof context === 'object') {
    return context[LOYALTY_BANNER_DISMISSED_COOKIE];
  }
  const cookieParts = context.split(';');

  for (let cookie of cookieParts) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookie.substring(cookieName.length, cookie.length)
      );
    }
  }
};
