import {
  isReviewScreen,
  ReviewScreen,
} from 'js/pages/CreateReviewPage/ReviewScreen';
import {
  Dictionary,
  isDictionary,
  isTypedArray,
  isTypedDictionary,
  ReadonlyDictionary,
  ReadonlyIndexDictionary,
} from 'js/types/generic-types';
import { isBoolean, isNumber, isString } from 'js/types/primitive-predicates';

interface Sentiment {
  readonly id: number;
  readonly label: string;
}

export interface CreateReviewUserState {
  readonly currentScreen: ReviewScreen;
  readonly rating: number;
  readonly reviewerName: string | null;
  readonly isAnonymous: boolean;
  readonly publicReviewComment: string;
  /**
   * Although availableSentiments are populated from the API response and not modified by the user,
   * they have to be stored with the user data. The API will return a new list of randomized
   * sentiments on each call. availableSentiments is the list that the user saw for the first time.
   * By persisting it in local storage, we can guarantee that the user always sees the same sentiments
   * selection when coming back to an unfinished review flow.
   */
  readonly availableSentiments: ReadonlyDictionary<readonly Sentiment[]>;
  readonly selectedSentiments: ReadonlyDictionary<
    ReadonlyIndexDictionary<boolean>
  >;
  readonly typeRatings: ReadonlyDictionary<number>;
  readonly treatmentRatings: ReadonlyIndexDictionary<number>;
}

export function isCreateReviewUserState(
  candidate: unknown
): candidate is CreateReviewUserState {
  return (
    isDictionary(candidate) &&
    isReviewScreen(candidate.currentScreen) &&
    isNumber(candidate.rating) &&
    (candidate.reviewerName === null || isString(candidate.reviewerName)) &&
    isBoolean(candidate.isAnonymous) &&
    isString(candidate.publicReviewComment) &&
    isTypedDictionary(
      candidate.availableSentiments,
      (value: unknown): value is Sentiment[] => isTypedArray(value, isSentiment)
    ) &&
    isTypedDictionary(
      candidate.selectedSentiments,
      (value: unknown): value is Dictionary<boolean> =>
        isTypedDictionary(value, isBoolean)
    ) &&
    isTypedDictionary(candidate.typeRatings, isNumber) &&
    isTypedDictionary(candidate.treatmentRatings, isNumber)
  );
}

function isSentiment(candidate: unknown): candidate is Sentiment {
  return (
    isDictionary(candidate) &&
    isNumber(candidate.id) &&
    isString(candidate.label)
  );
}
