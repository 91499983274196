import React from 'react';
import { KEY_CODE_ESCAPE } from 'js/constants/keycodes';
import styles from './PageOverlay.module.css';

interface Props {
  onDismiss: (
    selectedTabIndex: number | null,
    newSelectedTabIndex: number | null
  ) => void;
}

export function PageOverlay(props: Props): React.ReactElement {
  const overlay = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('keyup', escapeDismiss);
    window.document.body.classList.add(styles.pageOverlayBody);

    const height = window.document.body.offsetHeight - 300;

    // Some pages use a lot of absolute elements so the page height ends
    // up as zero, in that case we use 'bottom: 0'
    if (overlay.current) {
      if (height) {
        overlay.current.style.height = `${height}px`;
      } else {
        overlay.current.style.height = 'auto';
        overlay.current.style.bottom = '0';
      }
    }
    setTimeout(() => {
      if (overlay.current) {
        overlay.current.style.opacity = '0.4';
      }
    }, 1);

    return () => {
      window.removeEventListener('keyup', escapeDismiss);
      window.document.body.classList.remove(styles.pageOverlayBody);
      if (overlay.current) {
        overlay.current.style.opacity = '0';
      }
    };
  }, []);

  const escapeDismiss = (event: KeyboardEvent): void => {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      props.onDismiss(null, null);
    }
  };

  const handleOnClick = (): void => {
    props.onDismiss(null, null);
  };

  return (
    <div ref={overlay} className={styles.wrapper} onClick={handleOnClick} />
  );
}
