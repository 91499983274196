/* eslint-disable no-param-reassign */
import { translatedError } from './translatedError';

export interface TranslatedError {
  title: string;
  message: string;
  buttonLabel: string;
}

const tlds: { [key: string]: string } = {
  '.co.uk': 'en',
  '.ie': 'en',
  '.de': 'de',
  '.at': 'de',
  '.ch': 'de',
  '.es': 'es',
  '.fr': 'fr',
  '.it': 'it',
  '.lt': 'lt',
  '.nl': 'nl',
  '.be': 'nl',
  '.lv': 'lv',
  '.dk': 'dk',
};

const multilingualTlds: { [key: string]: string[] } = {
  '.nl': ['nl', 'en'],
  '.be': ['nl', 'fr', 'en'],
};

/*
  Get error text suitable for a last resort error page.

  The text is not from cms because things have gone so badly wrong
  that we don't have any cms data. Nor do we necessarily know what
  the channel is.

  So break with our normal approach to such things, and simply do
  the best that we reasonably can.
  If all else fails, default to English.
*/
export function getTranslatedError(
  hostname: string,
  path: string,
  languageCode?: string
): TranslatedError {
  if (languageCode !== undefined) {
    return translatedError[languageCode] || translatedError.en;
  }

  let currentTld = '.co.uk';
  languageCode = 'en';

  for (const tld of Object.keys(tlds)) {
    if (hostname.includes(tld)) {
      currentTld = tld;
      languageCode = tlds[tld];
      break;
    }
  }

  /**
   * When using Treatwell in Netherlands or Belgium, user has the ability to switch language.
   * If language switcher is used, we need to override the languageCode with the one that is prepended in url path.
   * For NL: Dutch (default) or English (en).
   * For BE: Dutch (default), French (fr) or English (en).
   */
  if (Object.keys(multilingualTlds).includes(currentTld)) {
    for (const lang of multilingualTlds[currentTld]) {
      if (path.includes(`/${lang}/`)) {
        languageCode = lang;
        break;
      }
    }
  }

  return translatedError[languageCode] || translatedError.en;
}
