export enum ButtonColour {
  Orange = 'orange_',
  OrangeOutline = 'outlineOrange',
  Blue = 'blue_',
  BlueOutline = 'outlineBlue',
  White = 'outline',
  WhiteOutline = 'outlineWhite',
  TealOutline = 'outlineTeal',
  Teal = 'teal_',
  OnBackground = 'onbackground',
  OnBackgroundSelected = 'onbackgroundSelected',
  Grey = 'grey',
  WhiteUndecorated = 'whiteUndecorated',
  NoOutlineBlue = 'noOutlineBlue',
}

export function isButtonColour(candidate: string): candidate is ButtonColour {
  return (Object.values(ButtonColour) as string[]).includes(candidate);
}
