import { connect } from 'react-redux';
import { getLayoutConfig } from 'js/redux-modules/layout/selectors';
import { ReduxState } from 'js/model/state';
import { Footer } from './Footer';

const mapStateToProps = (state: ReduxState) => ({
  layoutConfig: getLayoutConfig(state),
});

export const connectFooter = connect(mapStateToProps, {})(Footer);
