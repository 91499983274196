import { LayoutConfigState } from 'js/model/state/layout';
import {
  LAYOUT_CONFIG_UPDATE,
  LAYOUT_HEADER_BACK_CLICKED,
  LayoutAction,
} from './actions';

export function layout(
  state: LayoutConfigState = [],
  action: LayoutAction
): LayoutConfigState {
  switch (action.type) {
    case LAYOUT_CONFIG_UPDATE:
      return [...state, action.config];
    case LAYOUT_HEADER_BACK_CLICKED:
      if (state.length > 1) {
        return state.slice(0, -1);
      }
      return state;
    default:
      return state;
  }
}
