export function isNumber(candidate: unknown): candidate is number {
  return typeof candidate === 'number';
}

export function isBoolean(candidate: unknown): candidate is boolean {
  return typeof candidate === 'boolean';
}

export function isString(candidate: unknown): candidate is string {
  return typeof candidate === 'string';
}
