import React from 'react';
import clsx from 'clsx';
import styles from './SiteWideBanner.module.css';

interface Props {
  colour: string;
  onClick: () => void;
}

export function IconClose({ colour, onClick }: Props): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className={clsx(styles.button, styles.close)}
      onClick={onClick}
    >
      <path
        fill={colour}
        fillRule="evenodd"
        d="M17.793 5.293l1.414 1.414L13.914 12l5.293 5.293-1.414 1.414-5.293-5.293-5.293 5.293-1.414-1.414L11.086 12 5.793 6.707l1.414-1.414 5.293 5.293 5.293-5.293z"
      />
    </svg>
  );
}
