import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  Venue = 'venue',
}

enum Property {
  VenueHeaderExperiment = 'venue_header_experiment',
  VenueHeaderExperimentBack = 'venue_header_experiment_back',
  VenueHeaderMobileBack = 'venue_header_mobile_back',
}

enum Action {
  Click = 'click',
  Load = 'load',
}

export enum Label {
  ExperimentEnabled = 'venue_page_header_experiment_enabled',
  ExperimentDisabled = 'venue_page_header_experiment_disabled',
}

function trackEvent(
  category: Category,
  property: Property,
  action: Action,
  label?: string,
  value?: string
) {
  return trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export const trackVenueHeaderExperiment = (label: string) =>
  trackEvent(
    Category.Venue,
    Property.VenueHeaderExperiment,
    Action.Load,
    label
  );

export const trackVenueHeaderExperimentBackClick = (label: string) =>
  trackEvent(
    Category.Venue,
    Property.VenueHeaderExperimentBack,
    Action.Click,
    label
  );
