import {cookieName} from './constants';

function getDeviceIdCookie() {
  const cookieRegex = new RegExp(`${cookieName}=([a-z0-9-]+);?`);
  const deviceIdValue = document.cookie.match(cookieRegex);
  return deviceIdValue ? deviceIdValue[1] : null;
}

function setDeviceIdCookie(value: string) {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 2);
  document.cookie = `${cookieName}=${value}; expires=${expires}; path=/; secure;`;
}

/**
 * If no deviceId cookie is present, generate a new one and set it.
 * Only imports the `uuid` library when needed.
 */
export async function getDeviceId() {
  const cookieValue = getDeviceIdCookie();
  if (cookieValue) {
    return cookieValue;
  }
  const uuid = await import(/* webpackChunkName: "uuid" */ 'uuid');
  const newId = uuid.v4();
  setDeviceIdCookie(newId);
  return newId;
}
