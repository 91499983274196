import React from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import styles from './PageSection.module.css';

export enum SectionType {
  VeryShort = 'veryShort',
}

interface Props {
  id: string;
  children: React.ReactElement<HTMLDivElement>;
  type?: SectionType;
  title?: string;
}

export class PageSection extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { id, title, children, type } = this.props;

    const classes = clsx(styles.section, {
      [styles.veryShort]: type === SectionType.VeryShort,
    });

    return (
      <section id={id} className={classes}>
        {title && (
          <Text as="h2" type="mdHeader" className={styles.header}>
            {title}
          </Text>
        )}
        {children}
      </section>
    );
  }
}
