import React, {
  Children,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  ReactElement,
  ReactNode,
} from 'react';
import {Spacing} from '@treatwell/design-tokens';
import clsx from 'clsx';
import styles from './Dropdown.module.css';
import {initialiseReducer, reducer, actions, InitialState} from './reducer';
import {Stack} from '../../Layout';
import {IconChevronDown, IconChevronUp} from '../../Atoms';
import {useKeyPress, useClickOutside} from '../../../hooks';

export type DropdownProps = {
  children: ReactElement | ReactElement[];

  /**
   * The id of an element responsible for labelling the dropdown. Used for ARIA
   * within the dropdown
   */
  labelId: string;

  /**
   * The value of the `DropdownItem` that should be selected on initial render,
   * defaults to the first item
   */
  initialSelected?: string;

  /**
   * The Dropdown will respect the `--Dropdown-maxHeight` and set a scroll.
   *
   * **Note** Currently when using the arrow keys to navigate a scolled menu it
   * will not adjust to scroll to follow the highlighted item. Yet to be
   * implemented
   */
  enableScroll?: boolean;

  /**
   * Callback triggered each time a `DropdownItem` is selected.
   */
  onSelected?: (value: string) => void;

  /**
   * `Spacing` value for the `Stack` holding each `DropdownItem` component
   */
  space?: Spacing;

  /**
   * Callback triggered each time the Dropdown is opened or closed
   */
  onToggle?: (collapsed: boolean) => void;

  /**
   * Dims the content of the dropdown and prevents interaction
   */
  disabled?: boolean;

  /**
   * Allows the menu items to have their natural width,
   * rather than constrain them to the same width as the button item.
   */
  menuWidthAuto?: boolean;

  /**
   * The size of the icon used to show the open/closed state.
   */
  caretSize?: 16 | 24;

  /**
   * Allows for any specific styling required to be applied to the dropdown menu
   */
  menuStyle?: React.CSSProperties;

  /**
   * Render the dropdown in open state initially
   */
  isInitialOpen?: boolean;
};

export const Dropdown = ({
  children,
  labelId,
  disabled,
  initialSelected,
  enableScroll,
  onSelected,
  space,
  onToggle,
  menuWidthAuto,
  menuStyle,
  isInitialOpen,
  caretSize = 24,
}: DropdownProps) => {
  const elementArray = Children.toArray(children) as ReactElement[];
  const initialState: InitialState = {
    elementArray,
    initialSelected,
    isOpen: isInitialOpen || false,
  };
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    initialiseReducer
  );
  const {selectedElement, index: selectedIndex, isOpen: isDropdownOpen} = state;

  const handleArrowPress = useCallback(
    (event: KeyboardEvent) => {
      if (isDropdownOpen) {
        event.preventDefault();
      }
    },
    [isDropdownOpen]
  );

  const isDownKeyPressed = useKeyPress({
    key: 'ArrowDown',
    onPress: handleArrowPress,
  });
  const isUpKeyPressed = useKeyPress({
    key: 'ArrowUp',
    onPress: handleArrowPress,
  });
  const isEnterKeyPressed = useKeyPress({key: 'Enter'});
  const isEscapeKeyPressed = useKeyPress({key: 'Escape'});
  const isTabKeyPressed = useKeyPress({key: 'Tab'});

  const Caret = isDropdownOpen ? IconChevronUp : IconChevronDown;

  // Recalulate the selected element if initialSelected changes
  useEffect(() => {
    dispatch({
      type: actions.refresh,
      payload: {value: initialSelected, children: elementArray},
    });
  }, [initialSelected]);

  // If the list of items changes in length then recalculate
  // which element to select
  useEffect(() => {
    dispatch({
      type: actions.refresh,
      payload: {children: elementArray, value: initialSelected},
    });
  }, [elementArray.length, initialSelected]);

  const indexOfSelected = elementArray.findIndex((item) => {
    return item.props.value === selectedElement.props.value;
  });

  // If the selected element is moved to another position then recalculate
  // which element to highlight in the dropdown
  useEffect(() => {
    // If the element can't be found then default to first item
    // Can happen when user removes last element in the list for example
    if (indexOfSelected === -1) {
      dispatch({type: actions.setIndex, payload: {index: 0}});
      return;
    }
    dispatch({type: actions.setIndex, payload: {index: indexOfSelected}});
  }, [indexOfSelected]);

  // Close dropdown when detecting a click outside of it
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const highlightedRowRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      // making sure the dropdown does not close immediately after opening it
      if (event.target && buttonRef.current?.contains(event.target as Node)) {
        return;
      }
      if (isDropdownOpen) {
        dispatch({type: actions.toggleVisibility, payload: {isOpen: false}});
        // If the user has changed highlight position but then closes the menu
        // ensure we reset the index to the current selected item
        dispatch({type: actions.setIndex, payload: {index: indexOfSelected}});
      }
    },
    [indexOfSelected, isDropdownOpen]
  );
  useClickOutside({ref: menuRef, handler: handleClickOutside});

  // Keep track of which index is highlighted on ArrowDown
  useEffect(() => {
    if (isDownKeyPressed && isDropdownOpen) {
      dispatch({type: actions.decrementIndex});
    }
  }, [isDownKeyPressed, isDropdownOpen]);

  // Keep track of which index is highlighted on ArrowUp
  useEffect(() => {
    if (isUpKeyPressed && isDropdownOpen) {
      dispatch({type: actions.incrementIndex});
    }
  }, [isUpKeyPressed, isDropdownOpen]);

  useEffect(() => {
    if (isDropdownOpen && highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selectedIndex, isDropdownOpen]);

  // Select on Enter
  useEffect(() => {
    // Prevent this action being dispatched just by opening the dropdown.
    // Instead ensure the user has selected a different item
    const hasSelectedElementChanged =
      elementArray[selectedIndex].props.value !== selectedElement.props.value;
    if (isEnterKeyPressed && isDropdownOpen && hasSelectedElementChanged) {
      dispatch({
        type: actions.setElement,
        payload: {element: elementArray[selectedIndex], index: selectedIndex},
      });
      onSelected?.(elementArray[selectedIndex].props.value);
    }
  }, [selectedIndex, isEnterKeyPressed, isDropdownOpen]);

  // Close dropdown on Escape
  useEffect(() => {
    if (isEscapeKeyPressed && isDropdownOpen) {
      dispatch({type: actions.toggleVisibility, payload: {isOpen: false}});
      // If the user has changed highlight position but then closes the menu
      // ensure we reset the index to the current selected item
      dispatch({type: actions.setIndex, payload: {index: indexOfSelected}});
    }
  }, [isEscapeKeyPressed, isDropdownOpen, indexOfSelected]);

  // Close dropdown on Tab
  useEffect(() => {
    if (isTabKeyPressed && isDropdownOpen) {
      dispatch({type: actions.toggleVisibility, payload: {isOpen: false}});
      // If the user has changed highlight position but then closes the menu
      // ensure we reset the index to the current selected item
      dispatch({type: actions.setIndex, payload: {index: indexOfSelected}});
    }
  }, [isTabKeyPressed, isDropdownOpen, indexOfSelected]);

  const dropdownId = useRef(`dropdownId-${labelId}`).current;

  const handleButtonClick = () => {
    if (disabled) {
      return;
    }
    dispatch({
      type: actions.toggleVisibility,
      payload: {isOpen: !isDropdownOpen},
    });
    onToggle?.(!isDropdownOpen);
  };

  return (
    <div className={styles.root}>
      <button
        type="button"
        id={dropdownId}
        ref={(node) => {
          if (!node) {
            return;
          }
          buttonRef.current = node;
          // if the menu is set to open then also focus the button so that keyboard
          // plus enter can be used. This helps simulate
          // the user pressing enter on the button and interacting with the dropdown
          if (isDropdownOpen && document.activeElement !== node) {
            node.focus();
          }
        }}
        disabled={disabled}
        aria-labelledby={`${labelId} ${dropdownId}`}
        aria-expanded={isDropdownOpen}
        aria-haspopup="listbox"
        className={clsx(styles.button, {
          [styles.isOpen]: isDropdownOpen,
        })}
        onClick={handleButtonClick}
      >
        {React.cloneElement(selectedElement, {
          isSelected: true,
          isHighlighted: false,
          isDropdownOption: false,
          children: React.cloneElement(selectedElement.props.children, {
            isSelected: true,
            isHighlighted: false,
            isDropdownOption: false,
            isDropdownOpen,
          }),
        })}
        <Caret className={styles.caret} size={caretSize} />
      </button>
      <div
        className={clsx(styles.menu, {
          [styles.maxHeight]: enableScroll,
          [styles.autoWidth]: menuWidthAuto,
        })}
        style={menuStyle}
        hidden={!isDropdownOpen}
        role="listbox"
        ref={menuRef}
        aria-activedescendant={`${dropdownId}-${selectedElement.props.value.toLowerCase()}`}
        aria-labelledby={labelId}
      >
        <Stack space={space}>
          {elementArray.map((dropdownItem, index) => {
            const {children: dropdownItemChildren} = dropdownItem.props;

            // If the user removes the last item in the list then we can end up
            // searching for 4th element in an array length of 3, so just skip
            // and let the component re-render with the first item selected
            if (typeof elementArray[selectedIndex] === 'undefined') {
              return;
            }

            const isHighlighted =
              elementArray[selectedIndex].props.value ===
              dropdownItem.props.value;
            const isSelected =
              dropdownItem.props.value === selectedElement.props.value;
            const isDropdownOption = true;

            // Return a cloned <DropdownItem /> with some additional props
            return React.cloneElement(dropdownItem, {
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                onSelected?.(dropdownItem.props.value);
                // Do nothing if the user clicks the same dropdown item again
                if (dropdownItem.props.value !== selectedElement.props.value) {
                  dispatch({
                    type: actions.setElement,
                    payload: {
                      element: dropdownItem,
                      index,
                    },
                  });
                }
                dispatch({
                  type: actions.toggleVisibility,
                  payload: {isOpen: false},
                });
              },
              isSelected,
              isHighlighted,
              isDropdownOption,
              id: `${dropdownId}-${dropdownItem.props.value.toLowerCase()}`,
              ref: isHighlighted ? highlightedRowRef : null,

              // Also clone whatever the user passed and give that additional
              // props too
              children: React.cloneElement(dropdownItemChildren, {
                isHighlighted,
                isSelected,
                isDropdownOption,
                isDropdownOpen,
              }),
            });
          })}
        </Stack>
      </div>
    </div>
  );
};

export type DropdownItemProps = {
  children: ReactNode;
  value: string;
  id?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected?: boolean;
  isHighlighted?: boolean;
  isDropdownOption?: boolean;
};

export const DropdownItem = React.forwardRef(
  (
    {
      children,
      onClick,
      id,
      className,
      isSelected,
      isDropdownOption,
      isHighlighted,
    }: DropdownItemProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const props: Omit<DropdownItemProps, 'value'> & {
      role?: string;
      'aria-selected'?: boolean;
    } = {
      className: clsx(styles.item, className, {
        [styles.highlight]: isHighlighted,
        [styles.dropdownOption]: isDropdownOption,
      }),
      onClick,
      children,
      id,
      'aria-selected': isSelected && isDropdownOption,
      role: 'option',
    };

    if (isSelected && !isDropdownOption) {
      delete props['aria-selected'];
      delete props.role;
      delete props.id;
    }

    return (
      <div {...props} ref={ref}>
        {children}
      </div>
    );
  }
);

DropdownItem.displayName = 'DropdownItem';
