import { ReduxState } from 'js/model/state';
import { AccountDetailsState } from 'js/model/state/account/details';
import { loadAccountDetails } from 'js/redux-modules/account/details/actions';
import { getAccountDetails } from 'js/redux-modules/account/selectors';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Context } from 'js/components/LocaleWrapper';
import { getPageType } from 'js/helpers/page-data';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { IconChevronLeft, Viewport, useDevice } from '@treatwell/ui';
import { t } from 'i18next';
import { SimpleHeader } from './SimpleHeader';
import { getHeaderLinks } from '../getHeaderLinks';
import HeaderWrapper from '../HeaderWrapper';
import {
  NavigateAnchorLink,
  generateNavigationTextAndLink,
} from '../VenuePageHeader/VenuePageHeaderMobileContainer';
import styles from './SimpleHeader.module.css';

const SimpleHeaderContainer = ({
  showExperimentVenuePageHeader,
  accountDetails,
  loadAccountDetails,
}: {
  showExperimentVenuePageHeader?: boolean;
  accountDetails: AccountDetailsState;
  loadAccountDetails: () => unknown;
}) => {
  const { i18n, generateUri, pageData } = React.useContext(Context);

  useEffect(() => {
    loadAccountDetails();
  }, [loadAccountDetails]);

  const isBrowser = useIsMounted();
  const headerLinks = getHeaderLinks({
    isBrowser,
    i18n,
    generateUri,
    pageData,
  });
  const pageType = getPageType(pageData) as keyof typeof pageData;
  const alternateLocaleUris =
    (pageData[pageType] && pageData[pageType].alternateLocaleUris) || [];
  const { user } = accountDetails;
  let isAuthenticated = null;
  let isConnectAccount = false;
  if (user) {
    isAuthenticated = user.isAuthenticated;
    isConnectAccount = user.isConnectAccount;
  }
  const { isMobile } = useDevice();
  const {
    navigateLink,
    navigateText,
    onNavigateLinkClick,
  } = generateNavigationTextAndLink({
    pageData,
    t,
    i18n,
  });

  return (
    <>
      {showExperimentVenuePageHeader ? (
        <>
          <Viewport device={['mobile', 'tablet']} serverRender={isMobile}>
            <div className={styles.experimentVenuePageHeader}>
              <SimpleHeader
                channel={pageData.channel}
                key="simple-logo-header-venue-experiment"
                headerLinks={{
                  alternateLocaleUris,
                  headerLinks,
                  isAuthenticated,
                  isConnectAccount,
                }}
              />
              {!pageData.venuePageReferrer && (
                <div className={styles.anchorLink}>
                  <NavigateAnchorLink
                    navigateLink={navigateLink}
                    navigateText={navigateText}
                    onNavigateLinkClick={onNavigateLinkClick}
                    icon={<IconChevronLeft size={16} />}
                  />
                </div>
              )}
            </div>
          </Viewport>
          <Viewport device="desktop" serverRender={!isMobile}>
            <HeaderWrapper key="header" pageType={pageType} />
          </Viewport>
        </>
      ) : (
        <SimpleHeader channel={pageData.channel} key="simple-logo-header" />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  accountDetails: getAccountDetails(state),
});

const mapDispatchToProps = {
  loadAccountDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleHeaderContainer);
