import React from 'react';
import { Tabs } from './Navigation';
import styles from './NavigationDropdown.module.css';
import { NavigationDropdownContent } from './NavigationDropdownContent';

interface Props {
  tabs: Tabs[];
  selected: number | null;
  uriLocationReference: string;
  onSubNavLinkClick: (
    label: string,
    index: number,
    redirectUri: string
  ) => void;
  toggleNavigationTab: (
    selectedTabIndex: number | null,
    newSelectedTabIndex: number | null,
    newSelectedTabLabel: string | null
  ) => void;
}

export function NavigationDropdown(props: Props): React.ReactElement {
  const domNode = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: MouseEvent): void {
    if (!domNode || !domNode.current || !domNode.current.parentNode) {
      return;
    }

    const isOutside =
      !domNode.current.contains(event.target as HTMLElement) &&
      !domNode.current.parentNode.contains(event.target as HTMLElement);

    if (!domNode || isOutside) {
      props.toggleNavigationTab(props.selected, null, null);
    }
  }

  return (
    <div className={styles.wrapper} ref={domNode}>
      {props.tabs.map((tab, index) => {
        if (!tab['child-items'] || !tab['child-items'].length) {
          return null;
        }

        return (
          <NavigationDropdownContent
            key={index}
            tabData={tab}
            active={index === props.selected}
            uriLocationReference={props.uriLocationReference}
            onSubNavLinkClick={props.onSubNavLinkClick}
          />
        );
      })}
    </div>
  );
}
