import React from 'react';
import styles from './InlineLoading.module.css';

interface Props {
  color: string;
  size: number | string;
}

export class InlineLoading extends React.Component<Props> {
  public render(): React.ReactNode {
    const { color, size } = this.props;

    const normalizedSize = typeof size === 'string' ? size : `${size}px`;

    const dotStyle: React.CSSProperties = {
      color,
      width: normalizedSize,
      height: normalizedSize,
      marginRight: `calc(${normalizedSize} * 0.8)`,
    };

    return (
      <div className={styles.container}>
        <span className={styles.dot} style={dotStyle} />
        <span className={styles.dot} style={dotStyle} />
        <span
          className={styles.dot}
          style={{ ...dotStyle, marginRight: undefined }}
        />
      </div>
    );
  }
}
