import {useEffect, RefObject} from 'react';

export const useClickOutside = ({
  ref,
  handler,
}: {
  ref: RefObject<HTMLElement>;
  handler: (event: MouseEvent) => void;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.target) {
        return;
      }
      const element = ref.current;
      if (element && !element.contains(event.target as Node)) {
        handler(event);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handler]);
};
