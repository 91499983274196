import fromEntries from 'fromentries';

const decodeCookie = (cookieString: string) => {
  return decodeURIComponent(cookieString);
};

// https://gist.github.com/rendro/525bbbf85e84fa9042c2?permalink_comment_id=3076403#gistcomment-3076403
export const parseCookie = (cookie: string, decode = decodeCookie) => {
  return fromEntries(
    cookie.split(/; */).map((c) => {
      const [key, ...v] = c.split('=');
      return [key, decode(v.join('='))];
    })
  );
};

export const getCsrfCookie = (cookieString: string) => {
  // Do not decode URI encoded cookies.
  // Rainbow AuthTokenService expects an exact match between the CSRF header
  // and the ident cookie.
  const cookies = parseCookie(cookieString, (cookieString) => cookieString);
  const identCookieName = Object.keys(cookies).find((cookieName) =>
    cookieName.endsWith('ITKT')
  );
  if (identCookieName) {
    return cookies[identCookieName];
  }
};

type EmptyObject = Record<string, never>;

export const getCsrfTokenHeader = (
  cookieValue: string
): Record<'X-CSRF-TOKEN', string> | EmptyObject => {
  const cookie = getCsrfCookie(cookieValue);
  if (cookie) {
    return {'X-CSRF-TOKEN': cookie};
  }
  return {};
};

export const setCookie = ({
  name,
  value,
  expires,
  domain,
}: {
  name: string;
  value: string;
  expires?: string;
  domain?: string;
}) => {
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; ${domain ? `domain=${domain};` : ''} path=/; secure;`;
};

export const getCookie = (cookieKey: string) => {
  const cookieName = `${cookieKey}=`;
  const cookieParts = document.cookie.split(';');

  for (let cookie of cookieParts) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookie.substring(cookieName.length, cookie.length)
      );
    }
  }
};

export const getExpiryDate = (expiryDays: number) => {
  const dateNow = new Date();
  const expiryDate = new Date(dateNow.toUTCString());
  expiryDate.setDate(dateNow.getDate() + expiryDays);
  return expiryDate.toUTCString();
};
