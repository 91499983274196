import React from 'react';
import { Logo, Size } from 'js/components/Logo';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import styles from './AccountHeader.module.css';

interface Props {
  showBackButton?: boolean;
  backUri: string;
  backLabel: string;
  onBackClick(event: React.MouseEvent<HTMLAnchorElement>): Promise<void>;
  logoUri: string;
  onLogoClick(event: React.MouseEvent<HTMLAnchorElement>): Promise<void>;
}

export const AccountHeader = (props: Props) => {
  const {
    showBackButton,
    backUri,
    backLabel,
    onBackClick,
    logoUri,
    onLogoClick,
  } = props;
  return (
    <header className={styles.container}>
      <div className={styles.back}>
        {showBackButton && (
          <a href={backUri} onClick={onBackClick} data-ajax="false">
            <div className={styles.chevronContainer}>
              <Chevron colour="bloomBaseCoral600" direction={Direction.Left} />
            </div>
            {backLabel}
          </a>
        )}
      </div>
      <Logo onClick={onLogoClick} uri={logoUri} size={Size.Small} />
    </header>
  );
};
