const SESSION_STORAGE = 'sessionStorage';
const LOCAL_STORAGE = 'localStorage';
type StorageType = typeof SESSION_STORAGE | typeof LOCAL_STORAGE;

const getItem = (storage: StorageType) => (key: string) => {
  let item: string | null = null;
  try {
    item = window[storage].getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return item;
  } catch {
    return item;
  }
};

const isStringifyNeeded = (value: unknown) => {
  return (
    toString.call(value).includes('Object') ||
    toString.call(value).includes('Array')
  );
};
const setItem = (storage: StorageType) => (key: string, value: unknown) => {
  try {
    let storageValue: string;
    if (isStringifyNeeded(value)) {
      storageValue = JSON.stringify(value);
    } else if (typeof value === 'string') {
      storageValue = value;
    } else {
      storageValue = String(value);
    }
    window[storage].setItem(key, storageValue);
  } catch {}
};

const removeItem = (storage: StorageType) => (key: string) => {
  try {
    window[storage].removeItem(key);
  } catch {}
};

export const storage = {
  session: {
    getItem: getItem(SESSION_STORAGE),
    setItem: setItem(SESSION_STORAGE),
    removeItem: removeItem(SESSION_STORAGE),
  },
  local: {
    getItem: getItem(LOCAL_STORAGE),
    setItem: setItem(LOCAL_STORAGE),
    removeItem: removeItem(LOCAL_STORAGE),
  },
};
