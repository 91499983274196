export enum LoginStatus {
  profileAuthenticated = 'profile_authenticated',
  accountAuthenticated = 'account_authenticated',
  notRecognised = 'not_recognised',
}

export type UserType = 'EXISTING' | 'GUEST' | 'NEW';

export interface AccountDetailsApiResponse {
  accountId?: number;
  firstName?: string;
  lastName?: string;
  intlPhoneNumber?: string;
  gender?: string;
  email?: string;
  connectAccountAssociated: boolean;
  loginStatus: LoginStatus;
  userTypePriorLogin: UserType;
}

export type LoginResponse = {
  result: AccountLoginResult;
  userTypePriorLogin: UserType;
};

// For more detail see:
// https://git.treatwell.net/dev/service/blob/dd7c0ada7bb0e8b7225f8dabdc49a67a20b3101c/site/webapp/src/main/java/com/wahanda/site/ajax/account/domain/AccountLoginResult.java
export enum AccountLoginResult {
  ACCOUNT_AUTHENTICATED = 'ACCOUNT_AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  NOT_RECOGNISED = 'NOT_RECOGNISED',
  CHANNEL_MISMATCH = 'CHANNEL_MISMATCH',
  REQUIRES_PASSWORD_VERIFICATION = 'REQUIRES_PASSWORD_VERIFICATION',
  FACEBOOK_AND_NO_PASSWORD = 'FACEBOOK_AND_NO_PASSWORD',
  FACEBOOK_EMAIL_DENIED = 'FACEBOOK_EMAIL_DENIED',
  LOCKED = 'LOCKED',
  REQUIRES_RESET = 'REQUIRES_RESET',
  GOOGLE_AND_NO_PASSWORD = 'GOOGLE_AND_NO_PASSWORD',
}
