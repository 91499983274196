export function getMediaUri(
  channel: { firstMediaDomain: string },
  path = ''
): string {
  if (!path) {
    console.warn('Attempt to get media uri for empty path');
    return '';
  }

  const domain = channel.firstMediaDomain;

  // When running UI tests and visual regression test, in docker-composed
  // containers, support for https is not configured.
  const protocol =
    domain.startsWith('media-server') || domain.startsWith('localhost')
      ? 'http'
      : 'https';

  return `${protocol}://${domain}${path.trim()}`;
}
