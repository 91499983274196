/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M9.485 8.071l5.657 5.657-1.414 1.414-5.657-5.657-5.657 5.657L1 13.728l5.657-5.657L1 2.414 2.414 1l5.657 5.657L13.728 1l1.414 1.414z',
  ],

  24: [
    'M17.293 5.293l1.414 1.414L13.414 12l5.293 5.293-1.414 1.414L12 13.414l-5.293 5.293-1.414-1.414L10.586 12 5.293 6.707l1.414-1.414L12 10.586l5.293-5.293z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconClose = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconClose.displayName = 'IconClose';
