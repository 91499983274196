export type Dictionary<T> = {
  [key: string]: T | undefined;
};

export type ReadonlyDictionary<T> = Readonly<Dictionary<T>>;

export type IndexDictionary<T> = {
  [index: number]: T | undefined;
};

export type ReadonlyIndexDictionary<T> = Readonly<IndexDictionary<T>>;

export type NonNullableProperties<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export function isDictionary(
  candidate: unknown
): candidate is Dictionary<unknown> {
  return typeof candidate === 'object' && candidate !== null;
}

export function isTypedDictionary<T>(
  dictionaryCandidate: unknown,
  isType: (valueCandidate: unknown) => valueCandidate is T
): dictionaryCandidate is Dictionary<T> {
  if (!isDictionary(dictionaryCandidate)) {
    return false;
  }

  for (const key in dictionaryCandidate) {
    if (!isType(dictionaryCandidate[key])) {
      return false;
    }
  }

  return true;
}

export function isArray(candidate: unknown): candidate is unknown[] {
  return (
    typeof candidate === 'object' &&
    candidate !== null &&
    candidate instanceof Array
  );
}

export function isTypedArray<T>(
  arrayCandidate: unknown,
  isType: (itemCandidate: unknown) => itemCandidate is T
): arrayCandidate is T[] {
  if (!isArray(arrayCandidate)) {
    return false;
  }

  for (const itemCandidate of arrayCandidate) {
    if (!isType(itemCandidate)) {
      return false;
    }
  }

  return true;
}
