import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

const trackNewsletterPopupEvent = (
  category: Category,
  action: Action,
  label?: Label,
  value?: number
) =>
  trackStructuredEvent({
    category,
    undefined,
    action,
    label,
    value,
  });

export enum Action {
  ViewModal = 'view_modal',
  CloseModal = 'close_modal',
  SubscriptionSubmitted = 'subscription_submitted',
}

export enum Category {
  NewsletterModal = 'newsletter_modal',
}

export enum Label {
  SubscriptionCreated = 'subscription_created',
  SubscriptionFailed = 'subscription_failed',
}

export function newsletterPopupOpen(): void {
  trackNewsletterPopupEvent(Category.NewsletterModal, Action.ViewModal);
}

export function newsletterPopupClose(): void {
  trackNewsletterPopupEvent(Category.NewsletterModal, Action.CloseModal);
}

export function newsletterPopupSuccess(): void {
  trackNewsletterPopupEvent(
    Category.NewsletterModal,
    Action.SubscriptionSubmitted,
    Label.SubscriptionCreated
  );
}

export function newsletterPopupFailure(): void {
  trackNewsletterPopupEvent(
    Category.NewsletterModal,
    Action.SubscriptionSubmitted,
    Label.SubscriptionFailed
  );
}
