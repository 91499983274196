// Persisted location used to replace a placeholder in some top nav
// cms item urls.

import { storage } from '@treatwell/ui';

export interface SaveableLocation {
  externalLocation?: number;
  location?: string;
  postalReference?: string;
  searchAreaGeocode?: string;
}

interface Location {
  type:
    | 'externalLocation'
    | 'location'
    | 'postalReference'
    | 'searchAreaGeocode';
  value: string;
}

const keyPrefix = 'navigation-location';
let key = keyPrefix;

export function save(
  saveableLocation: SaveableLocation,
  languageCode: string
): void {
  key = `${keyPrefix}-${languageCode}`;
  if (saveableLocation.externalLocation) {
    persist('externalLocation', saveableLocation.externalLocation.toString());
  } else if (saveableLocation.location) {
    persist('location', saveableLocation.location);
  } else if (saveableLocation.postalReference) {
    persist('postalReference', saveableLocation.postalReference);
  } else if (saveableLocation.searchAreaGeocode) {
    persist('searchAreaGeocode', saveableLocation.searchAreaGeocode);
  }
}

export function removeAllExcept(languageCode: string): void {
  try {
    for (let i = 0; i < localStorage.length; i++) {
      if (
        localStorage.key(i)!.startsWith(keyPrefix) &&
        localStorage.key(i) !== `${keyPrefix}-${languageCode}`
      ) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

function persist(type: Location['type'], value: string): void {
  const location: Location = { type, value };
  storage.local.setItem(key, JSON.stringify(location));
}

export function browsePathLocationFragment(
  locationPrefix: string,
  postalReferencePrefix: string,
  languageCode: string
): string | undefined {
  const location: Location | null = storage.local.getItem(
    `${keyPrefix}-${languageCode}`
  );
  if (!location) {
    return undefined;
  }

  switch (location.type) {
    case 'location':
      return `${locationPrefix}-${location.value}`;
    case 'postalReference':
      return `${postalReferencePrefix}-${location.value}`;
    case 'externalLocation':
      return `ext-in-${location.value}`;
    case 'searchAreaGeocode':
      return `geocode-${location.value}`;
    default:
      return undefined;
  }
}
