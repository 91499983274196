import React from 'react';

import { Text, Inline } from '@treatwell/ui';
import styles from './styles.module.css';

interface Props {
  message: string;
}

export function ErrorPage({ message }: Props): React.ReactElement {
  return (
    <Inline justify="center" className={styles.descriptionContainer}>
      <Text type="lgHeader" className={styles.description}>
        {message}
      </Text>
    </Inline>
  );
}
