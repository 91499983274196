import { getReviewScreenOrder } from 'js/pages/CreateReviewPage/ReviewScreen';
import { ReadonlyDictionary } from 'js/types/generic-types';
import {
  CreateReviewState,
  UnavailableReviewType,
} from 'js/model/state/createReview';
import { ReviewFacetSentimentOutput } from 'js/model/rainbow/create-review-page/ReviewFacetSentimentOutput';
import { SentimentOutput } from 'js/model/rainbow/create-review-page/SentimentOutput';
import {
  ApiErrorOutput,
  isApiErrorOutput,
} from 'js/model/rainbow/page/ApiErrorOutput';
import { VenueReviewCreatorCheckoutConfig } from 'server/routes/page/types';

function initErrorData(
  unavailableReviewType: UnavailableReviewType
): CreateReviewState['data'] {
  return {
    venue: {
      id: 1,
      name: '',
      image: '',
    },
    referenceToken: '',
    employee: {
      id: 0,
      name: '',
    },
    multipleEmployees: false,
    isGoldenTicketReview: false,
    rating: 0,
    unavailableReviewType,
    suggestedReviewerName: '',
    mainTreatmentGroupId: 0,
    treatmentCategories: [],
    supportedVenueReviewRatingTypes: [],
    referralEntryPoint: { canRefer: false },
  };
}

function chooseUnavailableReviewType(
  data: ApiErrorOutput | undefined
): CreateReviewState['data'] {
  const {
    OrderAlreadyReviewed,
    VenueAlreadyReviewed,
    ReviewRequestExpired,
    AppointmentAlreadyReviewed,
  } = UnavailableReviewType;
  const errorName = data?.errors[0].name;
  switch (errorName) {
    case ReviewRequestExpired:
      return initErrorData(ReviewRequestExpired);
    case VenueAlreadyReviewed:
      return initErrorData(VenueAlreadyReviewed);
    case OrderAlreadyReviewed:
      return initErrorData(OrderAlreadyReviewed);
    case AppointmentAlreadyReviewed:
      return initErrorData(AppointmentAlreadyReviewed);
    default:
      return initErrorData(VenueAlreadyReviewed);
  }
}

export function initCreateReviewDataState(
  data?: VenueReviewCreatorCheckoutConfig
): CreateReviewState['data'] {
  if (data === undefined) {
    return initErrorData(UnavailableReviewType.VenueAlreadyReviewed);
  }

  if (data.venue === undefined) {
    throw new Error('venue missing in api response');
  }

  return {
    venue: {
      id: data.venue.id,
      name: data.venue.name,
      image: data.venue.primaryImage && data.venue.primaryImage.uris['360x240'],
    },
    referenceToken: data.referenceToken,
    employee: data.employeeDetails && data.employeeDetails[0],
    multipleEmployees: data.employeeDetails && data.employeeDetails.length > 1,
    isGoldenTicketReview: data.source === 'review_request',
    rating: data.rating || 0,
    unavailableReviewType: undefined,
    suggestedReviewerName: data.suggestedReviewerName,
    mainTreatmentGroupId: data.treatmentCategories.length
      ? data.treatmentCategories[0].groupId
      : 100,
    treatmentCategories: data.treatmentCategories,
    supportedVenueReviewRatingTypes: data.supportedVenueReviewRatingTypes,
    loyaltyReviewRewardPoints: data.loyaltyReviewRewardPoints,
    minCharactersForEarningLoyaltyPoints:
      data.minCharactersForEarningLoyaltyPoints,
    referralEntryPoint: data.referralEntryPoint,
  };
}

export function initCreateReview(
  createReviewPageData?: VenueReviewCreatorCheckoutConfig | ApiErrorOutput
): CreateReviewState {
  return {
    data:
      isApiErrorOutput(createReviewPageData) ||
      createReviewPageData === undefined
        ? chooseUnavailableReviewType(createReviewPageData)
        : initCreateReviewDataState(createReviewPageData),
    user: {
      rating: 0,
      currentScreen: getReviewScreenOrder(0, false)[0],
      reviewerName: null,
      isAnonymous: false,
      publicReviewComment: '',
      availableSentiments: initCreateReviewSentimentsState(
        isApiErrorOutput(createReviewPageData)
          ? undefined
          : createReviewPageData?.sentiments
      ),
      selectedSentiments: {},
      typeRatings: {},
      treatmentRatings: {},
    },
    transactional: {
      submitting: false,
      error: undefined,
      publicReviewCommentError: '',
    },
  };
}

export function initCreateReviewSentimentsState(
  sentiments?: ReviewFacetSentimentOutput[]
): ReadonlyDictionary<readonly SentimentOutput[]> {
  if (!sentiments) {
    return {};
  }

  return sentiments.reduce(
    (sentiments, facet) => ({
      ...sentiments,
      [facet.reviewFacet]: facet.sentiments,
    }),
    {} as ReadonlyDictionary<SentimentOutput[]>
  );
}
