import React from 'react';
import escape from 'escape-html';
import { isProductionEnvironment } from 'js/helpers/environment';

import { useTrackPageAndSessionView } from 'js/hooks/useTrackPageAndSessionView';
import styles from './styles.module.css';
import { LastResortErrorPage } from './LastResortErrorPage/LastResortErrorPage';

// In the interests of minimising the risk of errors during error
// rendering, do not attempt to reference react context, but go
// straight to the state provided by the server rendering.

interface Props {
  error?:
    | {
        message: string;
        stack: string;
      }
    | Error;
  info?: React.ErrorInfo;
  ruid?: string;
}

export function ErrorPage(props: Props): React.ReactElement {
  useTrackPageAndSessionView();
  const { error, info, ruid } = props;

  function renderDetails(): React.ReactNode {
    if (isProductionEnvironment) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: `<!-- ${escape(ruid || '')} -->` }}
        />
      );
    }

    return (
      <>
        {error && <div className={styles.errorMessage}>{error.message}</div>}
        {error && <div className={styles.stack}>{error.stack}</div>}
        {info && <div className={styles.stack}>{info.componentStack}</div>}
        {ruid && <div className={styles.ruid}>{ruid}</div>}
      </>
    );
  }

  return (
    <div className={styles.wrapper}>
      <LastResortErrorPage />
      {renderDetails()}
    </div>
  );
}
