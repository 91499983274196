import { CreateReviewUserState } from 'js/model/state/CreateReviewUserState';
import {
  getReviewScreenOrder,
  ReviewScreen,
} from 'js/pages/CreateReviewPage/ReviewScreen';
import {
  CreateReviewAction,
  CreateReviewActionType,
} from 'js/redux-modules/create-review/actions';

export function userReducer(
  state: CreateReviewUserState | undefined,
  action: CreateReviewAction,
  numTreatmentCategories: number | undefined,
  thereAreNoEmployees: boolean | undefined
): CreateReviewUserState {
  if (!state) {
    return {
      currentScreen: ReviewScreen.AlreadyReviewed,
      rating: 0,
      reviewerName: null,
      isAnonymous: false,
      publicReviewComment: '',
      availableSentiments: {},
      selectedSentiments: {},
      typeRatings: {},
      treatmentRatings: {},
    };
  }

  switch (action.type) {
    case CreateReviewActionType.RequestSuccess:
      return {
        ...state,
        currentScreen: ReviewScreen.Completion,
      };

    case CreateReviewActionType.SetOverallRating:
      return {
        ...state,
        rating: action.rating,
      };

    case CreateReviewActionType.SetVenueRating:
      return {
        ...state,
        typeRatings: {
          ...state.typeRatings,
          [action.facet]: action.rating,
        },
      };

    case CreateReviewActionType.SetTreatmentRating:
      return {
        ...state,
        treatmentRatings: {
          ...state.treatmentRatings,
          [action.treatmentId]: action.rating,
        },
      };

    case CreateReviewActionType.SetEmployeeRating:
      return {
        ...state,
        typeRatings: {
          ...state.typeRatings,
          staff: action.rating,
        },
      };

    case CreateReviewActionType.SetPublicReviewComment:
      return {
        ...state,
        publicReviewComment: action.comment,
      };

    case CreateReviewActionType.SetPublicReviewName:
      return {
        ...state,
        reviewerName: action.name,
      };

    case CreateReviewActionType.SetPublicReviewIsAnonymous:
      return {
        ...state,
        isAnonymous: action.isAnonymous,
      };

    case CreateReviewActionType.SetEmployeeSentiment: {
      const previousEmployeeSelectedSentiments =
        state.selectedSentiments.employee || {};

      return {
        ...state,
        selectedSentiments: {
          ...state.selectedSentiments,
          employee: {
            [action.sentimentId]: !(
              previousEmployeeSelectedSentiments[action.sentimentId] === true
            ),
          },
        },
      };
    }

    case CreateReviewActionType.UpdateScreen: {
      if (
        numTreatmentCategories === undefined ||
        thereAreNoEmployees === undefined
      ) {
        return state;
      }

      const screenOrder = getReviewScreenOrder(
        numTreatmentCategories,
        thereAreNoEmployees
      );

      const currentScreenIndex = screenOrder.indexOf(state.currentScreen);
      if (currentScreenIndex < 0) {
        return state;
      }

      const nextScreenIndex =
        currentScreenIndex + (action.showPreviousScreen ? -1 : 1);

      const nextScreen: ReviewScreen | undefined = screenOrder[nextScreenIndex];
      if (nextScreen === undefined) {
        return state;
      }

      return {
        ...state,
        currentScreen: nextScreen,
      };
    }

    case CreateReviewActionType.ReplaceUserState:
      return action.state;

    default:
      return state;
  }
}
