import { CreateReviewTransactionalState } from 'js/model/state/createReview';
import {
  CreateReviewAction,
  CreateReviewActionType,
} from 'js/redux-modules/create-review/actions';

export function transactionalReducer(
  state: CreateReviewTransactionalState | undefined,
  action: CreateReviewAction
): CreateReviewTransactionalState {
  if (!state) {
    return {
      submitting: false,
      error: undefined,
      publicReviewCommentError: '',
    };
  }

  switch (action.type) {
    case CreateReviewActionType.Request:
      return {
        ...state,
        submitting: true,
      };

    case CreateReviewActionType.RequestSuccess:
      return {
        ...state,
        submitting: false,
      };

    case CreateReviewActionType.RequestFailure:
      return {
        ...state,
        submitting: false,
        error: action.error,
      };

    case CreateReviewActionType.SetPublicReviewCommentError:
      return {
        ...state,
        publicReviewCommentError: action.error,
      };

    default:
      return state;
  }
}
