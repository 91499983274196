export enum BackButtonLabel {
  Home = 'home',
  Back = 'back',
}

export enum LogoPosition {
  Left = 'left',
  Center = 'center',
}

export interface LayoutHeaderConfig {
  hide?: boolean;
  backButton?: {
    show?: boolean;
    label?: BackButtonLabel;
    override?: boolean;
    href?: string;
  };
  logo?: {
    position?: LogoPosition;
  };
}

export interface LayoutFooterConfig {
  hide?: boolean;
}

export interface LayoutConfig {
  header?: LayoutHeaderConfig;
  footer?: LayoutFooterConfig;
}

export type LayoutConfigState = LayoutConfig[];
