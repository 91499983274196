import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  Venue = 'venue',
}

enum Property {
  Breadcrumb = 'breadcrumb',
}

enum Action {
  TreatwellClick = 'treatwell_click',
  VenueTypeClick = 'venueType_click',
  LocationClick = 'location_click',
}

function trackEvent(
  category: Category,
  property: Property,
  action: Action | string,
  label?: string,
  value?: number
): void {
  trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export function trackTreatwellBreadcrumbClickEvent(): void {
  trackEvent(
    Category.Venue,
    Property.Breadcrumb,
    Action.TreatwellClick,
    'Treatwell',
    1
  );
}

export function trackVenueTypeBreadcrumbClickEvent(
  itemId: string,
  depthOfBreadcrumb: number
): void {
  trackEvent(
    Category.Venue,
    Property.Breadcrumb,
    Action.VenueTypeClick,
    itemId,
    depthOfBreadcrumb
  );
}

export function trackLocationBreadcrumbClickEvent(
  itemId: string,
  depthOfBreadcrumb: number
): void {
  trackEvent(
    Category.Venue,
    Property.Breadcrumb,
    Action.LocationClick,
    itemId,
    depthOfBreadcrumb
  );
}
