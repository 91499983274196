import React from 'react';
import { PageSection, SectionType } from 'js/components/PageSection';
import { Breadcrumbs } from 'js/components/Breadcrumbs/Breadcrumbs';
import { StateData } from 'js/model/rainbow/StateData';
import { Context } from 'js/components/LocaleWrapper';

export function getBreadcrumbs(
  venuePageData: StateData['venue']
): { id?: number; name: string; uri: string }[] {
  return venuePageData.venueBreadcrumbs.map(item => ({
    id: item.entityId,
    name: item.name,
    uri: item.uri.desktopUri,
  }));
}

export function BreadcrumbsSection(props: {
  [key: string]: unknown;
}): React.ReactElement | null {
  return (
    <Context.Consumer>
      {value =>
        value.pageData.venue.hasBreadCrumbs ? (
          <PageSection id="breadcrumbs" type={SectionType.VeryShort}>
            <Breadcrumbs
              pageData={value.pageData}
              breadcrumbsParams={getBreadcrumbs(value.pageData.venue)}
              lastElementLink
              {...props}
            />
          </PageSection>
        ) : null
      }
    </Context.Consumer>
  );
}
