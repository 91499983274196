import format from 'date-fns/format';
import parse from 'date-fns/parse';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import it from 'date-fns/locale/it';
import lt from 'date-fns/locale/lt';
import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/el';

interface Time {
  from: string; // HHMM - for example 0800
  to: string; // HHMM - for example 1300
}

interface Format {
  dateFormat?: string;
  dateTimeFormat: string;
  timeRangeFormat: string;
}

// eslint-disable-next-line no-undef
const locales: { [key: string]: Locale } = {
  en: enGB,
  de,
  es,
  fr,
  nl,
  it,
  lt,
  pt,
  el,
};

export const FORMAT = 'yyyy-MM-dd';
export const BROWSE_URI_DATE_FORMAT = 'dd-MM-yyyy';

export const formatDate = (date: Date): string => format(date, FORMAT);

export const parseDate = (date: string): Date =>
  parse(date, FORMAT, new Date());

export function longAgo(date: string, locale: string) {
  return formatDistanceToNow(parseISO(date), {
    locale: locales[locale],
    addSuffix: true,
  });
}

export const transformDateFormat = (dateFormat?: string) => {
  const regex = /\b(?:DD|D|YYYY|YY)/g;
  if (!dateFormat) {
    return null;
  }
  const matchToLowerCase = (match: string) => match.toLowerCase();
  return dateFormat.replace(regex, matchToLowerCase);
};

export function formatDateTimeString(
  format: Format,
  date?: Date | null,
  time?: Time | null
): string {
  if (!date && !time) {
    return '';
  }
  const dateString = getDateString(format, date);
  const timeString = getTimeString(format, time);

  if (!dateString) {
    return timeString;
  }
  if (!timeString) {
    return dateString;
  }

  return format.dateTimeFormat
    .replace('{date}', dateString)
    .replace('{time}', timeString);
}

function getDateString(dateFormat: Format, date?: Date | null): string {
  if (!date) {
    return '';
  }

  return format(
    date,
    transformDateFormat(dateFormat.dateFormat) || 'dd/MM/yyyy'
  );
}

function getTimeString(format: Format, time?: Time | null): string {
  if (!time) {
    return '';
  }

  if (time.from === '0000' && time.to === '2400') {
    return '';
  }

  const from = timeKeyToLabel(time.from);
  const to = timeKeyToLabel(time.to);

  return format.timeRangeFormat.replace('{from}', from).replace('{to}', to);
}

// time:  HHMM e.g. "0900".
// returns: HH:MM "e.g 09:00"
function timeKeyToLabel(time: string): string {
  return `${time.slice(0, 2)}:${time.slice(2)}`;
}
