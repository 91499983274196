import React from 'react';
import { MarketplaceOutput } from 'js/model/rainbow/content/MarketplaceOutput';
import clsx from 'clsx';
import { MarketplacesList } from './MarketplacesList';
import styles from './styles.module.css';

const getSelectedCountry = (
  marketplaces: MarketplaceOutput,
  activeChannel: string
) =>
  marketplaces.find(marketplace => marketplace.channelCode === activeChannel);

interface Props {
  marketplaces: MarketplaceOutput;
  channelCode: string;
}

export function MarketplaceSelector(props: Props): React.ReactElement {
  const [active, setActive] = React.useState<boolean>(false);

  function handleClick(event: React.MouseEvent): void {
    event.preventDefault();
    setActive(prevState => !prevState);
  }

  const { marketplaces = [], channelCode } = props;
  const selectorClasses = clsx(styles.selector, {
    [styles.active]: active,
  });
  const selectedCountry = getSelectedCountry(marketplaces, channelCode);
  const selectedCountryName = selectedCountry?.countryName;

  return (
    <div className={selectorClasses}>
      {active && (
        <MarketplacesList
          marketplaces={marketplaces}
          active={active}
          currentChannelCode={channelCode}
          onClick={handleClick}
        />
      )}
      <a className={styles.label} onClick={handleClick}>
        {selectedCountryName}
      </a>
    </div>
  );
}
