import { Context } from '@growthbook/growthbook';

export const development: Context = {
  clientKey: 'sdk-drAfMf7Qd3jF9Cvc',
  enableDevMode: true,
  disableCache: true,
};

const enableDevModeInProduction =
  typeof window === 'object' &&
  typeof window.localStorage !== 'undefined' &&
  window.localStorage.getItem('growthbook_debug') === 'true';

export const production: Context = {
  clientKey: 'sdk-Cp30MODyJUeDHx',
  enableDevMode: enableDevModeInProduction,
};
