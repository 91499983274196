import { AccountDetailsApiResponse } from 'js/model/rainbow/AccountDetailsOutput';
import { AccountVerificationApiResponse } from 'js/model/rainbow/AccountVerifyOutput';
import { AccountPasswordApiResponse } from 'js/model/rainbow/AccountPasswordOutput';
import {
  LoginFormData,
  LoginRequestData,
  LoginApiResponse,
} from 'js/model/rainbow/LoginOutput';
import { httpGetJson, httpPost } from 'js/common/http';
import { RequestData } from 'js/helpers/service';

export async function fetchAccountDetails(
  state?: RequestData
): Promise<AccountDetailsApiResponse> {
  const cookies = state?.requestHeaders?.cookie;
  return await httpGetJson<AccountDetailsApiResponse>(
    '/api/v1/me/information',
    { requestHeaders: { cookie: cookies } }
  );
}

export async function postAccountVerify(
  email: string
): Promise<AccountVerificationApiResponse | null> {
  return await httpPost<AccountVerificationApiResponse>(
    '/api/v1/account-verify',
    {
      requestHeaders: {},
      data: { email },
    }
  );
}

export async function postAccountLogin(
  formData: LoginFormData
): Promise<LoginApiResponse | null> {
  const data: LoginRequestData = { ...formData, cookies: true, valid: true };
  return await httpPost<LoginApiResponse>('/api/v1/me/login', {
    requestHeaders: {},
    data,
  });
}

export async function postCreatePassword(
  token: string,
  password: string
): Promise<AccountPasswordApiResponse | null> {
  return await httpPost<AccountPasswordApiResponse>(
    `/api/v1/account-password?token=${token}`,
    {
      requestHeaders: {},
      data: { password },
    }
  );
}
