import React from 'react';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { HeaderContainer, Inline, MarketplaceHeader } from '@treatwell/ui';
import styles from './SimpleHeader.module.css';
import { HeaderLink, trackHeaderLink } from '../tracking';
import { isLanguageCodeRequired } from '../isLanguageCodeRequired';
import {
  VenueHeaderLinks,
  VenueHeaderLinksType,
} from '../VenuePageHeader/VenuePageHeaderMobileContainer';

type SimpleHeaderProps = {
  channel: Channel;
  headerLinks?: Omit<VenueHeaderLinksType, 'channel'>;
};
export const SimpleHeader = ({ channel, headerLinks }: SimpleHeaderProps) => {
  const shouldAddLangCode = isLanguageCodeRequired(channel);
  const homepageUri = `/${shouldAddLangCode ? `${channel.languageCode}/` : ''}`;
  const header = headerLinks ? (
    <Inline justify="between">
      <MarketplaceHeader
        logoHref={homepageUri}
        onLogoClick={async () => {
          await trackHeaderLink(HeaderLink.Logo);
        }}
      />
      <VenueHeaderLinks
        channel={channel}
        alternateLocaleUris={headerLinks.alternateLocaleUris}
        headerLinks={headerLinks.headerLinks}
        isAuthenticated={headerLinks.isAuthenticated}
        isConnectAccount={headerLinks.isConnectAccount}
      />
    </Inline>
  ) : (
    <MarketplaceHeader
      logoHref={homepageUri}
      onLogoClick={async () => {
        await trackHeaderLink(HeaderLink.Logo);
      }}
    />
  );

  return (
    <div className={styles.header} data-cy="SimpleHeader">
      <HeaderContainer>
        <div className={styles.wrapper}>{header}</div>
      </HeaderContainer>
    </div>
  );
};
