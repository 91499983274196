export const DeviceMobile = 'mobile';
export const DeviceDesktop = 'desktop';
export type DeviceType = typeof DeviceMobile | typeof DeviceDesktop;
export type DeviceOS =
  | 'windows'
  | 'android'
  | 'openbsd'
  | 'linux'
  | 'ios'
  | 'macos'
  | 'unknown'
  | 'unix';
export type DeviceReturnType = {type: DeviceType; os: DeviceOS};

const operatingSystems: {name: DeviceOS; regex: RegExp}[] = [
  {name: 'android', regex: /Android/},
  {name: 'ios', regex: /(iPhone|iPad|iPod)/},
  {name: 'windows', regex: /Windows/},
  {name: 'openbsd', regex: /OpenBSD/},
  {name: 'linux', regex: /(Linux|X11)/},
  {name: 'macos', regex: /Mac OS X/},
  {name: 'unix', regex: /UNIX/},
];

// Check of `Mobi` based on:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
export function detectDevice(userAgent: string): DeviceReturnType {
  const isMobile = /Mobi/.test(userAgent);
  let matchedOs: DeviceOS = 'unknown';
  for (const os of operatingSystems) {
    if (os.regex.test(userAgent)) {
      matchedOs = os.name;
      break;
    }
  }
  return {
    type: isMobile ? DeviceMobile : DeviceDesktop,
    os: matchedOs,
  };
}
