/* eslint-disable no-param-reassign */
import React from 'react';
import { CmsSiteWideBanner } from 'js/model/cms/cms-site-wide-banner';
import { parseCmsBoolean } from 'js/model/cms/boolean';
import { Inline, Text } from '@treatwell/ui';
import styles from './SiteWideBanner.module.css';
import { IconClose } from './icon-close';
import { Data, getData, saveData } from './storage';
import { Action, Property, trackEvent } from './tracking';

interface Props {
  cms: CmsSiteWideBanner;
  pageType: string;
}

const linkPlaceholder = '{link}';

export function SiteWideBanner({
  cms,
  pageType,
}: Props): React.ReactElement | null {
  const [closed, setClosed] = React.useState(false);

  const {
    enabled,
    'page-type-blacklist': pageTypeBlacklist = '',
    'max-page-views': maxPageViews,
    'campaign-id': campaignId,
    message,
    'link-text': linkText,
    'link-url': linkUrl,
    'background-colour': backgroundColor,
    colour,
  } = cms;

  const pageBlacklisted = pageTypeBlacklist
    .split(',')
    .map(pagetype => pagetype.trim())
    .includes(pageType);
  const disabled = !parseCmsBoolean(enabled);
  const maximumPageViews = parseInt(maxPageViews, 10);

  const data = getData();
  checkForMessageChange(data);
  const { autoDismissed, dismissed } = data;

  const showBanner = !(
    disabled ||
    pageBlacklisted ||
    closed ||
    autoDismissed ||
    dismissed
  );

  // This effect only runs once, on mount.
  // If it were allowed to run multiple times then the
  // views increment and tracking might occur more than
  // once for a single page view.
  React.useEffect(() => {
    const autoDismiss = data.views === maximumPageViews;
    if (autoDismiss) {
      setClosed(true);
    }

    // On auto-dismiss, track and persist.
    if (showBanner && autoDismiss) {
      trackEvent(Property.Banner, Action.AutoDismiss, campaignId, data.views);

      data.autoDismissed = true;
      saveData(data);
    }

    // If showing banner, increments views and track view.
    if (showBanner && !autoDismiss) {
      data.views++;
      saveData(data);

      trackEvent(Property.Banner, Action.View, campaignId, data.views);
    }
  }, []);

  // Check whether the configuration (cms) has changed.
  // If it has, reset the persisted information.
  //
  // For a new user, the persisted data will be empty.
  // So it will be detected as a message change, and the user
  // will get to see the banner.
  function checkForMessageChange(data: Data): void {
    const messageChanged =
      message !== data.messageText ||
      linkText !== data.linkText ||
      linkUrl !== data.linkUrl ||
      campaignId !== data.campaignId;

    if (messageChanged) {
      // capture new message data
      data.campaignId = campaignId;
      data.linkText = linkText;
      data.linkUrl = linkUrl;
      data.messageText = cms.message;

      // the user has not seen the new message
      data.views = 0;
      data.autoDismissed = false;
      data.dismissed = false;

      saveData(data);
    }
  }

  async function linkClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): Promise<void> {
    event.preventDefault();
    await trackEvent(Property.Link, Action.Click, campaignId, data.views);
    window.location.href = linkUrl;
  }

  function renderMessage(): React.ReactNode {
    const type = 'body';
    if (!cms.message.includes(linkPlaceholder)) {
      // Message contains no link.
      // Render the message as is.
      return (
        <Text type={type} className={styles.message}>
          {cms.message}
        </Text>
      );
    }

    // Message contains a link.
    // Render the message with the link placeholder replaced with an anchor.
    const [part1, part2] = cms.message.split(linkPlaceholder);

    return (
      <div className={styles.message}>
        <Text type={type}>{part1}</Text>
        <Text type={type}>
          <a onClick={linkClick} href={linkUrl} className={styles.link}>
            <Text type={type}>{linkText}</Text>
          </a>
        </Text>

        <Text type={type}>{part2}</Text>
      </div>
    );
  }

  function close(): void {
    // Cause a re-render, without the banner.
    setClosed(true);

    // Persist manual dismissal.
    data.dismissed = true;
    saveData(data);

    trackEvent(Property.Banner, Action.Dismiss, campaignId, data.views);
  }

  if (!showBanner) {
    return null;
  }

  return (
    <div style={{ backgroundColor, color: colour }} className={styles.banner}>
      <Inline justify="between">
        {/* Hidden element with same width as IconClose, to ensure that message is centred */}
        <div className={styles.button} style={{ visibility: 'hidden' }} />
        {renderMessage()}
        <IconClose colour={colour} onClick={close} />
      </Inline>
    </div>
  );
}
