import type {
  default as mParticleSDK,
  IdentityApiData,
  MPConfiguration,
  SDKEventAttrs,
  UserIdentities,
} from '@mparticle/web-sdk';
import * as Sentry from '@sentry/browser';
import {GET_INSTANCE_WAIT_DURATION} from './constants';
import {storage} from '../storage';

let initResolve: (value: typeof mParticleSDK) => void;
let initReject: () => void;
const initialiser = new Promise((resolve: typeof initResolve, reject) => {
  initResolve = resolve;
  initReject = reject;
});

const KEY = 'eu1-89229be06fa54243ab7413dd164af3a3';
class MParticleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MParticleError';
  }
}

type MPInitOptions = Omit<MPConfiguration, 'identifyRequest'> & {
  isDevelopmentMode: boolean;
};

type MPUserIdentities = Pick<UserIdentities, 'customerid' | 'email'>;

const mParticleEventType = {
  Unknown: 0,
  Navigation: 1,
  Location: 2,
  Search: 3,
  Transaction: 4,
  UserContent: 5,
  UserPreference: 6,
  Social: 7,
  Other: 8,
  Media: 9,
} as const;

type EventType = typeof mParticleEventType[keyof typeof mParticleEventType];

const mParticleInit = async (
  mParticle: typeof mParticleSDK,
  options: MPInitOptions,
  userIdentities: MPUserIdentities
) => {
  const knownErrors = [
    // Data plan not activated on (prod) environment:
    'Data plan version was not found',
    // Connection blocked by ad blocker:
    'Error sending event to mParticle servers',
    // Undefined response from mParticle server:
    'Error parsing JSON response from Identity server',
  ];

  try {
    // 404 expected
    await fetch('https://jssdkcdns.mparticle.com', {
      mode: 'no-cors',
    });
  } catch {
    return initReject();
  }

  const config: MPConfiguration = {
    logger: {
      error: (message) => {
        const isKnownError = knownErrors.some((error) =>
          message.includes(error)
        );
        if (!isKnownError) {
          Sentry.captureException(new MParticleError(message));
        }
      },
      verbose: console.log,
      warning: console.warn,
    },
    dataPlan: {
      planId: 'mparticle_web',
    },
    identifyRequest: {userIdentities},
    ...options,
  };

  mParticle.init(KEY, config);

  initResolve(mParticle);
};

const mParticleGetInstance: () => Promise<null | typeof mParticleSDK> =
  async () => {
    const initialisedInstance: Promise<null | typeof mParticleSDK> =
      new Promise((resolve) => {
        const timeout = setTimeout(
          () => resolve(null),
          GET_INSTANCE_WAIT_DURATION
        );
        initialiser
          .then((mParticleInstance) => {
            mParticleInstance.ready(() => {
              resolve(mParticleInstance);
            });
          })
          .catch(() => {
            resolve(null);
          })
          .finally(() => {
            clearTimeout(timeout);
          });
      });
    return initialisedInstance;
  };

const mParticleLogEvent = async <T extends SDKEventAttrs = SDKEventAttrs>(
  eventName: string,
  eventType?: EventType,
  attributes?: T
) => {
  const mParticle = await mParticleGetInstance();
  if (mParticle) {
    mParticle.logEvent(eventName, eventType, attributes);
  }
};

const mParticleLogPageView = async (
  eventName: string,
  attributes: SDKEventAttrs
) => {
  const mParticle = await mParticleGetInstance();
  if (mParticle) {
    mParticle.logPageView(eventName, attributes);
  }
};

const mParticleLogin = async (data: IdentityApiData) => {
  const mParticle = await mParticleGetInstance();
  if (mParticle) {
    mParticle.Identity.login(data);
  }
};

const mParticleLogout = async () => {
  const mParticle = await mParticleGetInstance();
  if (mParticle) {
    mParticle.Identity.logout({});
  }
};

const mParticleTrackSessionAttributes = async (
  countryCode: string,
  utmParameters?: Record<string, string>
) => {
  const mParticle = await mParticleGetInstance();
  if (!mParticle) {
    return;
  }

  const sessionId = mParticle.sessionManager.getSession();
  const sessionIdFromLocalStorage = storage.local.getItem('sessionId');

  if (sessionIdFromLocalStorage && sessionIdFromLocalStorage === sessionId) {
    return;
  }

  if (!sessionIdFromLocalStorage || sessionIdFromLocalStorage !== sessionId) {
    storage.local.setItem('sessionId', sessionId);

    mParticleLogEvent('session_start_info', mParticle.EventType.Other, {
      country: countryCode,
      platform: 'web',
      url: window.location.href,
      query_parameters: window.location.search,
      referrer: document.referrer,
      url_path: window.location.pathname,
      ...utmParameters,
    });
  }
};

export {
  mParticleInit,
  mParticleGetInstance,
  mParticleLogEvent,
  mParticleLogPageView,
  mParticleLogin,
  mParticleLogout,
  mParticleEventType,
  mParticleTrackSessionAttributes,
  MPInitOptions,
  MPUserIdentities,
};
