const SELECT_VENUE_MENU_GROUP =
  'venue-page/menu-groups/SELECT_VENUE_MENU_GROUP';
const UNSELECT_VENUE_MENU_GROUP =
  'venue-page/menu-groups/UNSELECT_VENUE_MENU_GROUP';
const UNSELECT_ALL_VENUE_MENU_GROUPS =
  'venue-page/menu-groups/UNSELECT_ALL_VENUE_MENU_GROUPS';

export const unselectVenueMenuGroup = groupId => ({
  type: UNSELECT_VENUE_MENU_GROUP,
  groupId,
});

export const unselectAllVenueMenuGroups = () => ({
  type: UNSELECT_ALL_VENUE_MENU_GROUPS,
});

export const selectVenueMenuGroup = groupId => ({
  type: SELECT_VENUE_MENU_GROUP,
  groupId,
});

export const selectedMenuGroups = (state = {}, action = {}) => {
  switch (action.type) {
    case SELECT_VENUE_MENU_GROUP:
      return {
        ...state,
        [action.groupId]: true,
      };
    case UNSELECT_VENUE_MENU_GROUP: {
      const newState = { ...state };

      delete newState[action.groupId];
      return newState;
    }
    case UNSELECT_ALL_VENUE_MENU_GROUPS:
      return {};
    default:
      return state;
  }
};
