/* eslint-disable no-redeclare */
import get from 'lodash.get';
import { isBrowser } from 'js/helpers/environment';
import {
  httpGetJson,
  httpGet,
  RequestHeaders,
  HttpError,
} from 'js/common/http';
import { generateRuid } from 'js/helpers/ruid';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { AccountDetailsApiResponse } from 'js/model/rainbow/AccountDetailsOutput';
import { PageMeta } from 'server/routes/page/types';
import { Dictionary } from 'js/types/generic-types';
import { FEATURE_FLAGS } from 'js/constants/headers';
import { i18n } from 'i18next';
import { MarketplaceOutput } from 'js/model/rainbow/content/MarketplaceOutput';

interface RequestCache {
  [key: string]: unknown;
}

export interface Environment {
  isDesktop: boolean;
  isMobile: boolean;
  twEnv: string;
  twEnvType: string;
}

export interface RequestData {
  requestHeaders: RequestHeaders;
  pageParameters?: Dictionary<[string]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cms?: any;
  accountDetails: AccountDetailsApiResponse;
  languageCode?: string;
  channel: Channel;
  ruid?: string;
  entryPointFileNames: {
    css: string[];
    embeddedScriptFilenames: string[];
    js: string[];
  };
  pageType: string;
  meta: PageMeta;
  environment: Environment;
  featureFlags: string;
  error?: HttpError;
  i18n?: i18n;
  isSentryPerformanceEnabled?: boolean;
  marketplaces: MarketplaceOutput;
}

const cache: RequestCache = {};
export const NOSWAP = 'do_not_swap_response_data_in_to_atom';

export function fetchHTTP(
  state: RequestData,
  path: string,
  ruidString?: string,
  returnRawResponse?: true
): Promise<Response>;
export function fetchHTTP<T>(
  state: RequestData,
  path: string,
  ruidString?: string,
  returnRawResponse?: false | undefined
): Promise<T | null>;
export function fetchHTTP<T>(
  state: RequestData,
  path: string,
  ruidString?: string,
  returnRawResponse?: boolean
): Promise<Response | T | null> {
  const { requestHeaders } = state;
  const options = {
    ruid: ruidString || generateRuid(),
    requestHeaders,
    data: null,
    // eslint-disable-next-line no-return-assign
    responseHeaders: (headers: Headers) =>
      // eslint-disable-next-line no-param-reassign
      (state.featureFlags = headers.get(FEATURE_FLAGS) || ''),
  };

  if (!returnRawResponse) {
    return httpGetJson<T>(path, options);
  }
  return httpGet(path, options);
}

export function fetchLastResponseOnly<T>(): (
  state: RequestData,
  path: string,
  ruidString?: string
) => Promise<T | null | string> {
  let requestNumber = 0;
  return async function fetch(
    state: RequestData,
    path: string,
    ruidString?: string
  ): Promise<T | null | string> {
    requestNumber += 1;
    const thisRequestNumber = requestNumber;
    const { requestHeaders } = state;
    const options = {
      ruid: ruidString || generateRuid(),
      requestHeaders,
      data: null,
    };
    const data = await httpGetJson<T>(path, options);
    if (thisRequestNumber !== requestNumber) {
      return NOSWAP;
    }
    return data;
  };
}

export async function fetchCached<T>(
  state: RequestData,
  path: string,
  ruidString?: string
): Promise<T | null> {
  const { languageCode, requestHeaders } = state;
  let host = get(state, 'requestHeaders.host', '');
  if (!host) {
    try {
      host = isBrowser ? window.location.host : '';
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  const qualifiedCacheKey = `${host}:${languageCode}:${path}`;

  if (path && cache[qualifiedCacheKey]) {
    return Promise.resolve(cache[qualifiedCacheKey] as T);
  }
  const options = {
    ruid: ruidString || generateRuid(),
    requestHeaders,
    data: null,
  };
  const data = await httpGetJson<T>(path, options);
  cache[qualifiedCacheKey] = data;
  return data;
}
