import {ReactElement, Reducer} from 'react';

export const actions = {
  refresh: 'REFRESH',
  setIndex: 'SET_INDEX',
  setElement: 'SET_ELEMENT',
  decrementIndex: 'DECREMENT_INDEX',
  incrementIndex: 'INCREMENT_INDEX',
  toggleVisibility: 'TOGGLE_VISIBILITY',
} as const;

export type InitialState = {
  initialSelected?: string;
  elementArray: ReactElement[];
  isOpen: boolean;
};

type State = {
  total: number;
  selectedElement: React.ReactElement;
  index: number;
  isOpen: boolean;
};

type RefreshAction = {
  type: typeof actions.refresh;
  payload: {
    children: ReactElement[];
    value?: string;
  };
};

type SetIndexAction = {
  type: typeof actions.setIndex;
  payload: {
    index: number;
  };
};

type SetElementAction = {
  type: typeof actions.setElement;
  payload: {
    element: ReactElement;
    index: number;
  };
};

type DecrementIndexAction = {
  type: typeof actions.decrementIndex;
};

type IncrementIndexAction = {
  type: typeof actions.incrementIndex;
};

type ToggleAction = {
  type: typeof actions.toggleVisibility;
  payload: {
    isOpen: boolean;
  };
};

export type Action =
  | RefreshAction
  | SetIndexAction
  | DecrementIndexAction
  | IncrementIndexAction
  | SetElementAction
  | ToggleAction;

const isRefreshAction = (action: Action): action is RefreshAction =>
  action.type === actions.refresh;
const isSetIndexAction = (action: Action): action is SetIndexAction =>
  action.type === actions.setIndex;
const isSetElementAction = (action: Action): action is SetElementAction =>
  action.type === actions.setElement;
const isDecrementIndexAction = (
  action: Action
): action is DecrementIndexAction => action.type === actions.decrementIndex;
const isIncrementIndexAction = (
  action: Action
): action is IncrementIndexAction => action.type === actions.incrementIndex;
const isToggleAction = (action: Action): action is ToggleAction =>
  action.type === actions.toggleVisibility;

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
) => {
  if (isRefreshAction(action)) {
    const {children, value} = action.payload;
    const selectedElement = getElementByValue(children, value);
    return {
      ...state,
      total: children.length,
      selectedElement,
      index: children.findIndex(
        (element: ReactElement) => element === selectedElement
      ),
    };
  }
  if (isSetIndexAction(action)) {
    return {
      ...state,
      index: action.payload.index,
    };
  }
  if (isSetElementAction(action)) {
    return {
      ...state,
      selectedElement: action.payload.element,
      index: action.payload.index,
    };
  }
  if (isDecrementIndexAction(action)) {
    const prevIndex = state.index;
    return {
      ...state,
      index: prevIndex < state.total - 1 ? prevIndex + 1 : prevIndex,
    };
  }
  if (isIncrementIndexAction(action)) {
    const prevIndex = state.index;
    return {
      ...state,
      index: prevIndex > 0 ? prevIndex - 1 : prevIndex,
    };
  }
  if (isToggleAction(action)) {
    return {
      ...state,
      isOpen: action.payload.isOpen,
    };
  }
  return state;
};

const getElementByValue = (elementArray: ReactElement[], value?: string) => {
  const defaultSelection = elementArray[0];
  if (typeof value === 'undefined') {
    return defaultSelection;
  }
  const selectedElement = elementArray.find((child) => {
    return child.props.value === value;
  });
  return selectedElement ? selectedElement : defaultSelection;
};

export const initialiseReducer = ({
  initialSelected,
  elementArray,
  isOpen,
}: InitialState): State => {
  const initialElement = getElementByValue(elementArray, initialSelected);
  return {
    total: elementArray.length,
    selectedElement: initialElement,
    index: elementArray.indexOf(initialElement),
    isOpen,
  };
};
