/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { i18n, i18nOptional } from 'js/helpers/i18n';
import { getMediaUri } from 'js/helpers/media-uri';
import { generateUri as makeUri } from 'js/helpers/uri-util';
import styles from 'assets/style/app.module.css';
import partial from 'lodash.partial';
import { hasChannelFeature } from 'js/helpers/channel-feature';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { StateData } from 'js/model/rainbow/StateData';

const generateUri = (
  channelCode: string,
  languageCode: string,
  pageType: string,
  values: { [index: string]: string[] | string }
) => makeUri(pageType, values, channelCode, languageCode) || '';
export type i18nFunction = <T = string>(
  key: string,
  count?: number | string,
  ...args: string[]
) => T;

export type GeneralContext = {
  channel: ChannelOutput;
  generateUri: any;
  i18n: i18nFunction;
  i18nOptional: () => void;
  isWidget: boolean;
  hasChannelFeature: (features: string[], feature: string) => boolean;
  getMediaUri: (url: string) => string;
  locale: string;
  pageData: StateData;
};

export const Context = React.createContext<GeneralContext>(
  {} as GeneralContext
);

interface Props {
  channel: ChannelOutput;
  languageCode?: string;
  cms: any;
  locale: string;
  children: React.ReactNode;
  pageData: StateData;
}

export function LocaleWrapper(props: Props): React.ReactElement {
  const { channel, languageCode, cms, locale, pageData, children } = props;
  const value = {
    channel,
    generateUri: partial(generateUri, channel.code, languageCode),
    i18n: partial(i18n, cms),
    i18nOptional: partial(i18nOptional, cms),
    isWidget: channel.type === 'widget',
    hasChannelFeature: (features: string[], feature: string) =>
      hasChannelFeature(channel.channelFeatures, feature),
    getMediaUri: partial(getMediaUri, channel),
    locale,
    pageData,
  };

  return (
    <Context.Provider value={value}>
      <div className={styles.contentStretch}>{children}</div>
    </Context.Provider>
  );
}
