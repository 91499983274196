import React from 'react';
import clsx from 'clsx';
import {Spacing} from '@treatwell/design-tokens';

import {Base, BaseProps} from '../../Internal/Base';
import styles from './Inline.module.css';

type JustifyValues = 'start' | 'end' | 'center' | 'between' | 'around';
type AlignValues =
  | 'stretch'
  | 'start'
  | 'end'
  | 'center'
  | 'baseline'
  | 'normal';

export const defaultTag = 'div';
export const allowedTags = [
  defaultTag,
  'aside',
  'section',
  'article',
  'header',
  'ul',
  'dl',
  'ol',
  'span',
  'p',
] as const;
export type AllowedTags = typeof allowedTags[number];

export type Props<T extends AllowedTags = typeof defaultTag> = {
  children: React.ReactNode;
  /** Adjusts the child elements on the main axis. */
  justify?: JustifyValues;
  /** Adjusts the child elements on the cross axis. */
  align?: AlignValues;
  /** Sets the space between elements based on values from the design-tokens by default. */
  space?: Spacing;
  /** Allows child elements to wrap onto another line. */
  wrap?: boolean;
  /** Reverse order of child elements. */
  reverse?: boolean;
  /**
   * Accepts a zero based index of a child element. Applies an auto margin to split
   * the elements - [More information](https://medium.com/hackernoon/flexbox-s-best-kept-secret-bd3d892826b6)
   */
  splitAfter?: number;
} & BaseProps<T>;

export const justifyMap: {[key in JustifyValues]: string} = {
  start: styles.justifyStart,
  end: styles.justifyEnd,
  center: styles.justifyCenter,
  between: styles.justifyBetween,
  around: styles.justifyAround,
};

export const alignMap: {[key in AlignValues]: string} = {
  start: styles.alignStart,
  end: styles.alignEnd,
  center: styles.alignCenter,
  stretch: styles.alignStretch,
  baseline: styles.alignBaseline,
  normal: styles.alignNormal,
};

const createChildren = (
  children: React.ReactNode,
  splitAfter?: number
): React.ReactNode => {
  if (typeof splitAfter === undefined) {
    return children;
  }
  return React.Children.map(children, (child, index) => {
    if (splitAfter === index && React.isValidElement(child)) {
      const props = {
        className: clsx(child.props.className, styles.splitAfter),
      };
      return React.cloneElement(child, props);
    }
    return child;
  });
};

export const Inline = <T extends AllowedTags>({
  justify = 'start',
  align = 'center',
  space,
  className,
  splitAfter,
  wrap = false,
  reverse = false,
  children,
  ...restProps
}: Props<T>) => {
  const justifyContent = justifyMap[justify];
  const alignItems = alignMap[align];
  const clusterClassName = clsx(
    styles.inline,
    className,
    space && styles[space],
    alignItems,
    justifyContent,
    {
      [styles.isWrap]: wrap,
      [styles.isReverse]: reverse,
    }
  );
  return (
    <Base className={clusterClassName} {...restProps}>
      {createChildren(children, splitAfter)}
    </Base>
  );
};

Inline.displayName = 'Inline';
