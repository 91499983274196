import pino from 'pino';

const level = process.env.LOG_LEVEL || 'info';
const isNodeEnvProduction = process.env.NODE_ENV === 'production';
const isCypress = process.env.CYPRESS === 'true';

const isPrettyPrint = isCypress || !isNodeEnvProduction;

export const logger = pino({
  level,
  // Ensure we don't add `pino-pretty` to production logs.
  // Check against NODE_ENV here (compared to runtime TW_ENV_TYPE) because
  // dev environments also run in production mode and we don't want
  // pretty logs there either otherwise Kibana will be empty
  transport: isPrettyPrint
    ? {
        target: 'pino-pretty',
        options: {
          colorize: true,
          translateTime: true,
          ignore: 'hostname,pid',
          levelFirst: true,
        },
      }
    : undefined,
  redact: {
    paths: ['req.headers.Cookie', 'req.headers.cookie'],
    censor: '**GDPR COMPLIANT**',
  },
});
