export function documentReady(cb) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', cb);
  } else {
    cb();
  }
}

export function windowLoaded(cb) {
  if (document.readyState !== 'complete') {
    window.addEventListener('load', cb);
  } else {
    cb();
  }
}
