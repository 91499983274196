import React from 'react';

import { Logo } from 'js/components/Logo';
import { getMediaUri } from 'js/helpers/media-uri';

import { Navigation } from 'js/components/Navigation';
import { LanguageSelector } from 'js/components/LanguageSelector';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { HeaderLinks, Links } from './HeaderLinks';
import { HeaderCmsOutput, Tab, ChildItem } from './HeaderCmsOutput';
import styles from './styles.module.css';
import { trackHeaderLink, HeaderLink } from './tracking';
import { HeaderNavigation } from '../Navigation/Navigation';
import { isLanguageCodeRequired } from './isLanguageCodeRequired';

interface Props {
  channel: Channel;
  alternateLocaleUris: {
    uri: string;
    locale: string;
  }[];
  isAuthenticated?: boolean | null;
  isConnectAccount: boolean;
  navigation: HeaderCmsOutput;
  headerLinks: Links;
  onLoginLinkClick?: () => void;
  isDefaultLanguageSelected?: boolean; // prop needed for old stack pages
}

export function Header(props: Props): React.ReactElement {
  const { channel, alternateLocaleUris, isDefaultLanguageSelected } = props;
  const shouldAddLangCode =
    isLanguageCodeRequired(channel) ||
    (isDefaultLanguageSelected !== undefined && !isDefaultLanguageSelected);
  const homepageUri = `/${shouldAddLangCode ? `${channel.languageCode}/` : ''}`;
  const headerNav = getHeaderNavigation();
  const countryNormalisedName = channel.country.normalisedName;

  function getHeaderNavigation(): HeaderNavigation {
    // Set CDN url on tab images and ensure enabled items only
    const headerNav = { ...props.navigation };
    headerNav.tabs = headerNav.tabs
      .filter((tab: Tab) => tab.enabled === 'true')
      .map((tab: Tab) => {
        const newTab = { ...tab };
        const children = tab['child-items'];

        if (newTab.promos) {
          newTab.promos = newTab.promos
            .filter(promo => promo.uri && promo.imageUri)
            .map(promo => ({
              ...promo,
              heading: promo.heading ? promo.heading.trim() : '',
              imageUri: getMediaUri(props.channel, promo.imageUri),
            }));
        }

        if (children && tab['child-items-enabled'] === 'true') {
          newTab['child-items'] = children.filter(
            (child: ChildItem) => child.enabled === 'true'
          );
        } else {
          newTab['child-items'] = [];
        }

        return newTab;
      });

    return headerNav;
  }

  function handleOnClick(): void {
    trackHeaderLink(HeaderLink.Logo);
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.section} data-cy="Header">
          <div className={styles.logoWrapper}>
            <Logo uri={homepageUri} onClick={handleOnClick} />
          </div>
          <LanguageSelector
            countryCode={channel.country.countryCode}
            languageCode={channel.languageCode}
            availableLanguages={channel.availableLanguages}
            alternateLocaleUris={alternateLocaleUris}
          />
          <HeaderLinks
            isAuthenticated={props.isAuthenticated}
            isConnectAccount={props.isConnectAccount}
            links={props.headerLinks}
            onLoginLinkClick={props.onLoginLinkClick}
          />
        </div>
        <div className={styles.headerNavigation}>
          <Navigation
            headerNavigation={headerNav}
            countryNormalisedName={countryNormalisedName}
            languageCode={props.channel.languageCode}
          />
        </div>
      </div>
    </div>
  );
}
