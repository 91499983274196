import {
  useDevice,
  useViewport,
  TreatsLogo,
  Text,
  Inline,
  Stack,
  VisuallyHidden,
  IconClose,
} from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { CmsHomePage } from 'js/model/cms/cms-home-page';
import React, { useState } from 'react';
import {
  LOYALTY_BANNER_DISMISSED_COOKIE,
  useCookieContext,
} from './CookieProvider';
import styles from './LoyaltyBanner.module.css';
import { setCookie } from '../../../../../rakuten/src/cookie';

type LoyaltyBannerProps = {
  cms: CmsHomePage;
};

export const LoyaltyBanner = ({ cms }: LoyaltyBannerProps) => {
  const loyaltyBannerDismissed = useCookieContext({
    cookieKey: LOYALTY_BANNER_DISMISSED_COOKIE,
  });
  const [dismissed, setIsDismissed] = useState(Boolean(loyaltyBannerDismissed));
  const { isMobile } = useDevice();
  const isMobileViewport = useViewport({
    device: ['mobile', 'tablet'],
    serverRender: isMobile,
  });
  const { pageData } = React.useContext(Context);
  const treatwellRewardsHref = new URL(
    'treatwell-rewards',
    pageData.homepage.canonicalUri
  ).href;

  if (dismissed) {
    return null;
  }

  const bannerContent = (
    <>
      <TreatsLogo />
      <p className={styles.text}>
        <Text type={isMobileViewport ? 'caption' : 'body'}>
          {cms.page.home['treatwell-rewards-banner'].text}
        </Text>{' '}
        <a
          href={treatwellRewardsHref}
          className={styles.text}
          data-cy="LinkTreatwellRewards"
        >
          <Text type={isMobileViewport ? 'captionHeavy' : 'bodyHeavy'}>
            {cms.page.home['treatwell-rewards-banner'].labels.link}
          </Text>
        </a>
      </p>
    </>
  );

  return (
    <div className={styles.root} data-cy="LoyaltyBanner">
      <div
        className={
          isMobileViewport ? styles.paddingMobile : styles.paddingDesktop
        }
      >
        <Inline
          space="xxs"
          align={isMobileViewport ? 'start' : 'center'}
          justify="center"
        >
          <div className={styles.container}>
            {isMobileViewport ? (
              <Stack align="center" space="xs">
                {bannerContent}
              </Stack>
            ) : (
              <Inline align="center" justify="center" space="xs">
                {bannerContent}
              </Inline>
            )}
          </div>
          <CloseButton
            closeLabel={
              cms.page.home['treatwell-rewards-banner'].labels.closeButton
            }
            onClose={() => {
              const dateNow = new Date();
              const expiryDate = new Date(dateNow.toUTCString());
              // expiry date set to a year from current day
              expiryDate.setDate(dateNow.getDate() + 365);
              setCookie({
                name: LOYALTY_BANNER_DISMISSED_COOKIE,
                value: 'true',
                expires: expiryDate.toUTCString(),
              });
              setIsDismissed(!dismissed);
            }}
          />
        </Inline>
      </div>
    </div>
  );
};

type CloseButtonProps = {
  closeLabel: string;
  onClose: () => void;
};

const CloseButton = ({ closeLabel, onClose }: CloseButtonProps) => {
  return (
    <button
      className={styles.closeButton}
      onClick={onClose}
      data-cy="CloseBanner"
    >
      <VisuallyHidden>{closeLabel}</VisuallyHidden>
      <IconClose className={styles.closeIcon} size={24} />
    </button>
  );
};
