import { yieldToMain } from 'js/common/yield-to-main';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  NavigationBar = 'navigation_bar',
}

enum Action {
  Click = 'click',
}

export enum HeaderLink {
  Logo = 'treatwell_logo',
  Business = 'list_your_business_button',
  Login = 'log_in_button',
  Connect = 'go_to_connect_button',
  Bookings = 'my_bookings_button',
  Account = 'my_account_button',
}

export async function trackHeaderLink(linkType: HeaderLink) {
  await yieldToMain();

  trackStructuredEvent({
    category: Category.NavigationBar,
    property: linkType,
    action: Action.Click,
    value: undefined,
    label: undefined,
  });
}
