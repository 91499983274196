import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './Button.module.css';
import { ButtonColour } from './ButtonColour';
import { ButtonCustomColour } from './ButtonCustomColour';
import { ButtonSize } from './ButtonSize';
import { InlineLoading } from '../InlineLoading/InlineLoading';

type ButtonType = 'button' | 'submit' | 'reset';
interface Props {
  positioningClassname?: string;
  disabled?: boolean;
  colour: ButtonColour | ButtonCustomColour;
  size?: ButtonSize;
  type?: ButtonType;
  label: string;
  leftAlignLabels?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  mobileLabel?: string;
  /*
    A render prop, allowing a user of Button to provide an icon as it sees fit.

    'classname' will be a css class name that has at least a 'fill'
    value. This allows the colour of an svg icon to controlled.
  */
  icon?: (classname: string) => React.ReactElement;

  /* NOTE Button width gets adjusted according to hiddenLabels longest text
    that is in an array or the "label" prop. Example hiddenLabels: ['Select', 'Selected']
    At the same button label is the only displayed button text */
  hiddenLabels?: string[];

  onClick?: (event: React.MouseEvent) => void;
  'data-cy'?: string;
}

interface DefaultProps {
  type: ButtonType;
  colour: ButtonColour | ButtonCustomColour;
  size: ButtonSize;

  // Hide label at mobile widths.
  // Probably only really makes sense when the "icon" prop is used.
  mobileHideLabel: boolean;
}

interface State {
  hover: boolean;
}

const buttonSizeToLoadingSize = {
  [ButtonSize.Small]: 3,
  [ButtonSize.OnBackground]: 3,
  [ButtonSize.Normal]: 5,
  [ButtonSize.Medium]: 5,
  [ButtonSize.Large]: 5,
};

export class Button extends React.PureComponent<Props & DefaultProps, State> {
  public static readonly defaultProps: Readonly<DefaultProps> = {
    colour: ButtonColour.Orange,
    size: ButtonSize.Normal,
    type: 'button',
    mobileHideLabel: false,
  };

  public state = {
    hover: false,
  };

  private renderHiddenLabels(): React.ReactNode {
    const { hiddenLabels } = this.props;
    if (!hiddenLabels || !hiddenLabels.length) {
      return;
    }

    return hiddenLabels.map((item: string, key: number) => (
      <span key={key} className={styles.hiddenLabels}>
        {item}
      </span>
    ));
  }

  private onMouseEnter = (): void => {
    this.setState({ hover: true });
  };

  private onMouseLeave = (): void => {
    this.setState({ hover: false });
  };

  private getSize(): string {
    switch (this.props.size) {
      case ButtonSize.Small:
        return styles.small;
      case ButtonSize.OnBackground:
        return styles.onbackground;
      case ButtonSize.Medium:
        return styles.medium;
      case ButtonSize.Large:
        return styles.large;
      case ButtonSize.Normal:
      default:
        return styles.normal;
    }
  }

  public render(): React.ReactNode {
    let classes = clsx(
      this.props.positioningClassname,
      styles.button,
      this.getSize(),
      { [styles.fullWidth]: this.props.fullWidth },
      { [styles.rounded]: this.props.rounded },
      { [styles.disabled]: this.props.disabled }
    );

    const onClick = this.props.disabled ? undefined : this.props.onClick;

    const style: CSSProperties = {};
    if (typeof this.props.colour === 'object') {
      const hoverColour =
        this.props.colour.hoverColour || this.props.colour.colour;
      style.backgroundColor = this.state.hover
        ? hoverColour
        : this.props.colour.colour;
      style.borderColor = this.state.hover
        ? hoverColour
        : this.props.colour.colour;
      style.color = this.props.colour.text;
    } else {
      classes += ` ${styles[this.props.colour]}`;
    }

    const isLoading = this.props.loading === true;
    const labelClasses = clsx(
      styles.label,
      { [styles.leftAlignLabels]: this.props.leftAlignLabels },
      { [styles.mobileHide]: this.props.mobileHideLabel }
    );

    return (
      <button
        className={classes}
        style={style}
        onClick={onClick}
        type={this.props.type}
        disabled={Boolean(this.props.disabled)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-cy={this.props['data-cy']}
      >
        {isLoading && (
          <InlineLoading
            size={buttonSizeToLoadingSize[this.props.size]}
            color="white"
          />
        )}
        {!isLoading && (
          <>
            {this.props.icon && (
              <div className={styles.icon}>{this.props.icon(classes)}</div>
            )}

            <div className={labelClasses}>
              {this.props.label}
              {this.renderHiddenLabels()}
            </div>

            {this.props.mobileLabel && (
              <div className={styles.mobileLabel}>{this.props.mobileLabel}</div>
            )}
          </>
        )}
      </button>
    );
  }
}
