import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  thunkRequest,
  ThunkActionFunctions,
  ThunkRequest,
} from 'js/common/thunk';
import { ReduxState } from 'js/model/state';
import { AccountDetailsApiResponse } from 'js/model/rainbow/AccountDetailsOutput';
import { fetchAccountDetails } from 'js/service/account';

export const ACCOUNT_DETAILS_REQUEST = 'ACCOUNT_DETAILS_REQUEST';
export const ACCOUNT_DETAILS_SUCCESS = 'ACCOUNT_DETAILS_SUCCESS';
export const ACCOUNT_DETAILS_FAILURE = 'ACCOUNT_DETAILS_FAILURE';

export interface AccountDetailsRequestAction {
  type: typeof ACCOUNT_DETAILS_REQUEST;
}

export interface AccountDetailsSuccessAction {
  type: typeof ACCOUNT_DETAILS_SUCCESS;
  response: AccountDetailsApiResponse;
}

export interface AccountDetailsFailureAction {
  type: typeof ACCOUNT_DETAILS_FAILURE;
  error: unknown;
}

export type AccountDetailsAction =
  | AccountDetailsRequestAction
  | AccountDetailsSuccessAction
  | AccountDetailsFailureAction;

const accountDetailsActions: ThunkActionFunctions = {
  request: () => ({ type: ACCOUNT_DETAILS_REQUEST }),
  success: <AccountDetailsApiResponse>(
    response: AccountDetailsApiResponse
  ) => ({ type: ACCOUNT_DETAILS_SUCCESS, response }),
  failure: <Object>(error: Object) => ({
    type: ACCOUNT_DETAILS_FAILURE,
    error,
  }),
};

function accountDetailsRequest(): ThunkRequest {
  return thunkRequest<AccountDetailsApiResponse>(
    fetchAccountDetails,
    accountDetailsActions
  );
}

/*
 * This action can be called multiple times at once (from different components)
 * so to prevent duplicate requests the promise is stored and returned for
 * subsequent calls
 */
let memoizedPromise: Promise<AccountDetailsApiResponse>;
export function loadAccountDetails(): ThunkAction<
  Promise<unknown>,
  ReduxState,
  unknown,
  Action
> {
  return (dispatch: ThunkDispatch<ReduxState, unknown, Action>) => {
    if (memoizedPromise) {
      return memoizedPromise;
    }
    const request = dispatch(accountDetailsRequest()) as Promise<
      AccountDetailsApiResponse
    >;
    memoizedPromise = request;
    return request;
  };
}
