import cookie from 'cookie';
import { fetch, Headers, Response } from 'cross-fetch';
import * as buildDate from 'js/helpers/build-date';
import {
  isBrowser,
  isServer,
  isTest,
  isProductionEnvironment,
  isCypress,
} from 'js/helpers/environment';
import { generateRuid } from 'js/helpers/ruid';
import get from 'lodash.get';
import { parseJson } from 'js/helpers/parse-json';
import { trackErrorRate } from './xhr-error-rates';
import { logRequest } from './log';
import { logger } from './pino';

/*
 * Add a CSRF token header if there is a cookie that identifies the user.
 */
function getCsrfCookie(): string | null {
  if (!isServer) {
    // Do not decode URI encoded cookies.
    // Rainbow AuthTokenService expects an exact match between the CSRF header
    // and the ident cookie. That's probably perfectly reasonable.
    const cookies = cookie.parse(document.cookie, { decode: value => value });
    const identCookieName = Object.keys(cookies).find(cookieName =>
      cookieName.endsWith('ITKT')
    );

    if (identCookieName) {
      return cookies[identCookieName];
    }
  }

  return null;
}

export interface RequestHeaders {
  Accept?: string;
  host?: string;
  'x-browse-elasticsearch'?: string;
  'x-forwarded-host'?: string;
  'x-language-code'?: string;
  'x-original-request-path'?: string;
  'Content-Type'?: string;
  cookie?: string;
  'User-Agent'?: string;
}

function buildHeaders(
  bodyData: unknown,
  ruid: string,
  requestHeaders: RequestHeaders = {}
): Record<string, string> {
  const csrfCookie = getCsrfCookie();

  const headers: Record<string, string> = {
    Accept: requestHeaders.Accept || 'application/json',
    'X-Accept-API-Version': buildDate,
  };

  if (bodyData) {
    headers['Content-Type'] =
      requestHeaders['Content-Type'] ?? 'application/json;charset=UTF-8';
  }

  if (csrfCookie) {
    headers['X-CSRF-TOKEN'] = csrfCookie;
  }

  if (isServer) {
    headers.ruid = ruid;
  }

  // Add request headers that are to be passed through to the API request.
  const passThroughHeaders: (keyof RequestHeaders)[] = [
    'host',
    'x-browse-elasticsearch',
    'x-forwarded-host',
    'x-language-code',
    'x-original-request-path',
    'cookie',
    'User-Agent',
  ];

  passThroughHeaders.forEach(name => {
    const value = requestHeaders[name];

    if (value) {
      headers[name] = value;
    }
  });

  // in browser overwrite x-language-code header with value from state when available
  if (isBrowser) {
    const languageCode: string | undefined = get(
      window,
      '__state__.channel.languageCode'
    );
    if (typeof languageCode === 'string') {
      headers['x-language-code'] = languageCode;
    }
  }

  return headers;
}

function trackError(response: Response): void {
  trackErrorRate(!(response.ok || response.status === 404));
}

function checkStatus(response: Response): void {
  if (!(response.ok || response.status === 422)) {
    throw response;
  }
}

function logResponse(
  method: 'GET' | 'POST',
  uri: string,
  ruid: string,
  startTime: number,
  response: Response
): void {
  const duration = new Date().getTime() - startTime;
  const originServer = response.headers.get('x-whn-origin') || '';

  logRequest('apiRequest', {
    ruid,
    method,
    url: uri,
    responseCode: response.status,
    originServer,
    duration,
  });
}

function getHost(): string {
  if (!isServer && isBrowser) {
    return window.location.host;
  }
  const apiEndpoint = process.env.RAINBOW_API_ENDPOINT;
  if (apiEndpoint) {
    return apiEndpoint;
  }
  console.error('Missing environment variable value for RAINBOW_API_ENDPOINT');
  return '';
}

function generateUri(path: string): string {
  if (path.startsWith('http')) {
    return path;
  }
  const scheme = isServer ? 'http:' : window.location.protocol;

  return `${scheme}//${getHost()}${path}`;
}

interface GetRequestOptions {
  ruid?: string;
  requestHeaders: RequestHeaders;
  responseHeaders?: (headers: Headers) => void;
}

interface PostRequestOptions extends GetRequestOptions {
  data?: unknown | null;
  dataRaw?: string;
  isTimeoutEnabled?: boolean;
}

export interface HttpError extends Error {
  notFound?: boolean;
  status?: number;
}

export async function request(
  method: 'GET' | 'POST',
  path: string,
  options: PostRequestOptions = {
    ruid: generateRuid(),
    requestHeaders: {},
    data: null,
    isTimeoutEnabled: false,
  }
): Promise<Response> {
  const {
    ruid = generateRuid(),
    requestHeaders,
    data,
    dataRaw,
    isTimeoutEnabled,
  } = options;
  const uri = generateUri(path);
  const headers = new Headers(
    buildHeaders(dataRaw ?? data, ruid, requestHeaders)
  );
  const startTime = new Date().getTime();

  // eslint-disable-next-line no-undef
  const fetchParams: RequestInit = {
    headers,
    method: method.toUpperCase(),
    credentials: 'same-origin',
  };

  const body = dataRaw ?? formatData(data);

  if (body) {
    fetchParams.body = body;
  }

  let timeout: NodeJS.Timeout | undefined;
  if (isTimeoutEnabled && (isProductionEnvironment || isCypress)) {
    const controller = new AbortController();
    const timeoutValue = 3000;
    timeout = setTimeout(() => {
      controller.abort();
    }, timeoutValue);
    fetchParams.signal = controller.signal;
  }

  try {
    const response = await fetch(uri, fetchParams);

    trackError(response);
    checkStatus(response);

    if (isServer && !isTest()) {
      logResponse(method, uri, ruid, startTime, response);
    }

    return response;
  } catch (err) {
    const error = err as Error;
    const httpError: HttpError = new Error(
      `request for ${uri} failed, ${error.stack}`
    );

    if (error instanceof Response) {
      const response = error;
      httpError.notFound = response.status === 404;
      httpError.status = response.status;

      if (!isTest()) {
        logResponse(method, uri, ruid, startTime, response);
      }
    } else if (error.name === 'AbortError') {
      if (!isTest()) {
        logger.error(
          { ruid, method, url: uri, message: 'AbortError' },
          'apiRequest'
        );
      }
    }

    throw httpError;
  } finally {
    () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }
}

function formatData(data: unknown | null): FormData | string | null {
  if (isBrowser && data instanceof FormData) {
    return data;
  }
  return data ? JSON.stringify(data) : null;
}

export async function httpGet(
  path: string,
  options: GetRequestOptions
): Promise<Response> {
  const response = await request('GET', path, options);
  options.responseHeaders?.(response.headers);

  return response;
}

export async function httpGetText(
  path: string,
  options: GetRequestOptions
): Promise<string> {
  return (await httpGet(path, options)).text();
}

export async function httpGetJson<T>(
  path: string,
  options: GetRequestOptions
): Promise<T> {
  return parseJson<T>(await httpGetText(path, options), options.ruid);
}

export async function httpPost<T>(
  path: string,
  options: PostRequestOptions
): Promise<T | null> {
  const textResponse = await (await request('POST', path, options)).text();
  return textResponse ? parseJson<T>(textResponse, options.ruid) : null;
}
