import { LayoutConfig } from 'js/model/state/layout';

export const LAYOUT_CONFIG_UPDATE = 'LAYOUT_CONFIG_UPDATE';

export const LAYOUT_HEADER_BACK_CLICKED = 'LAYOUT_HEADER_BACK_CLICKED';

export interface LayoutConfigUpdateAction {
  type: typeof LAYOUT_CONFIG_UPDATE;
  config: LayoutConfig;
}

export interface LayoutHeaderBackClickAction {
  type: typeof LAYOUT_HEADER_BACK_CLICKED;
}

export type LayoutAction =
  | LayoutConfigUpdateAction
  | LayoutHeaderBackClickAction;

export function layoutConfigUpdate(
  config: LayoutConfig
): LayoutConfigUpdateAction {
  return {
    type: LAYOUT_CONFIG_UPDATE,
    config,
  };
}

export function layoutHeaderBackClick(): LayoutHeaderBackClickAction {
  return {
    type: LAYOUT_HEADER_BACK_CLICKED,
  };
}
