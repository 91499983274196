import get from 'lodash.get';
import { getIsWidget, getVenueData } from 'js/helpers/page-data';
import {
  retrieveCurrentBasketItems,
  persistVenueBasket,
} from 'js/helpers/venue-basket';
import {
  isValidTreatmentType,
  getFirstMenuItemSkuId,
  getPrimaryMenuItem,
  filterGroupsByActiveTreatmentType,
} from 'js/helpers/venue-menu';
import { ALL_TREATMENT_TYPES_ID } from 'js/constants/treatment-types';
import {
  venueMenu,
  selectMenuOption,
  toggleVenueMenuService,
  setActiveTreatmentType,
} from './venue-menu';
import { selectedMenuGroups, selectVenueMenuGroup } from './menu-groups';

function processVenueMenuGroup(isMobileDevice, selectedMenuGroup) {
  // keep all menuGroups collapsed on mobile
  if (isMobileDevice) {
    return {};
  }

  return selectedMenuGroups({}, selectVenueMenuGroup(selectedMenuGroup));
}

function filterMenuGroups(state, venue, menuGroupId, treatmentTypeId) {
  const { menuGroups } = venue.menu;
  const { filteredMenuGroups, activeTreatmentTypeId } = state.venueMenu;
  let selectedMenuGroup = menuGroupId;
  let filteredGroups = menuGroups;
  let ttId = treatmentTypeId;
  if (!treatmentTypeId) {
    ttId = activeTreatmentTypeId;
  } else {
    filteredGroups =
      get(filteredMenuGroups, [ttId, 'groups']) ||
      filterGroupsByActiveTreatmentType(menuGroups, ttId);
  }
  if (!selectedMenuGroup) {
    selectedMenuGroup =
      get(filteredMenuGroups, [ttId, 'selected']) ||
      get(filteredGroups, [0, 'id']);
  }

  const newState = { ...state };

  newState.selectedMenuGroups = processVenueMenuGroup(
    newState.isMobileDevice,
    selectedMenuGroup
  );

  delete newState.isMobileDevice;

  newState.venueMenu = venueMenu(
    state.venueMenu,
    setActiveTreatmentType(
      treatmentTypeId || ALL_TREATMENT_TYPES_ID,
      filteredGroups,
      selectedMenuGroup
    )
  );

  return newState;
}

function processItemsFromStorage(venue) {
  return retrieveCurrentBasketItems(venue.id, venue.menu).reduce(
    (obj, item) => {
      const selectedItem = item.length && item[0];
      if (!selectedItem) {
        return obj;
      }
      const newObj = venueMenu(
        obj,
        selectMenuOption(
          selectedItem.menuId,
          selectedItem.serviceId,
          selectedItem.skuId,
          selectedItem.treatmentType
        )
      );
      if (!selectedItem.multi) {
        return newObj;
      }
      return venueMenu(
        newObj,
        toggleVenueMenuService(selectedItem.serviceId, true)
      );
    },
    {}
  );
}

function addItemFromHash(
  venueMenuData,
  venue,
  treatmentTypeId,
  menuGroupId,
  itemData
) {
  if (
    !itemData.multiOptionSelection &&
    itemData.optionGroups &&
    itemData.optionGroups.length === 1
  ) {
    const skuId = getFirstMenuItemSkuId(itemData);
    const newVenueMenu = venueMenu(
      {},
      selectMenuOption(menuGroupId, itemData.id, skuId, treatmentTypeId)
    );
    persistVenueBasket(
      venue.id,
      venue.menu.menuGroups,
      newVenueMenu.selected,
      ''
    );
    return newVenueMenu;
  }
  return venueMenuData;
}

function getActiveTreatmentTypeId(menuItem) {
  const treatmentTypeId = menuItem.item.data.primaryTreatmentCategoryGroupId;
  // catch any treatmentTypes without TreatmentTypeButton
  return isValidTreatmentType(treatmentTypeId)
    ? treatmentTypeId
    : ALL_TREATMENT_TYPES_ID;
}

export default function initVenuePage(pageData) {
  const venue = getVenueData(pageData);
  if (!venue) {
    return undefined;
  }
  const state = {
    isMobileDevice: pageData.pageParameters.isMobile[0] === 'true',
    venueMenu: {
      selected: {},
      expanded: {},
      selectedSkuIds: [],
      selectedTreatmentTypes: [],
      activeTreatmentTypeId: ALL_TREATMENT_TYPES_ID,
      ...processItemsFromStorage(venue),
    },
  };

  const menuItem =
    pageData.pageParameters.serviceIds &&
    getPrimaryMenuItem(venue.menu, pageData.pageParameters.serviceIds[0]);

  if (!menuItem) {
    // Default to ALL if no hash fragment
    return filterMenuGroups(state, venue);
  }

  const treatmentTypeId = getActiveTreatmentTypeId(menuItem);
  const menuGroupId = menuItem.menuGroup.id;

  if (getIsWidget(pageData)) {
    state.venueMenu = addItemFromHash(
      state.venueMenu,
      venue,
      treatmentTypeId,
      menuGroupId,
      menuItem.item.data
    );
  }

  if (treatmentTypeId === ALL_TREATMENT_TYPES_ID) {
    return filterMenuGroups(state, venue, menuGroupId);
  }
  return filterMenuGroups(state, venue, menuGroupId, treatmentTypeId);
}
