import React from 'react';
import clsx from 'clsx';
import { parseUri } from 'js/helpers/uri-util';
import { RequestData } from 'js/helpers/service';
import { IconCompass } from '@treatwell/ui';
import {
  trackTreatwellBreadcrumbClickEvent,
  trackVenueTypeBreadcrumbClickEvent,
  trackLocationBreadcrumbClickEvent,
} from './tracking';
import styles from './Breadcrumbs.module.css';
import { Context } from '../LocaleWrapper';

export interface BreadcrumbParam {
  id?: number;
  name: string;
  pageType?: string;
  uri?: string;
  uriParams?: {
    treatmentTypeId?: number;
    treatmentId?: number;
    normalisedName?: string;
  };
}

interface Props {
  pageData?: RequestData;
  breadcrumbsParams: BreadcrumbParam[];
  customColor?: string;
  customHoverColor?: string;
  showIcon?: boolean;
  center?: boolean;
  // Items are normally rendered as a link (anchor tag), except for the last
  // one which is rendered as a label.
  //
  // When this prop is true the last item will also be rendered as a link.
  lastElementLink?: boolean;
}

export function Breadcrumbs(props: Props): React.ReactElement | null {
  const {
    pageData,
    breadcrumbsParams,
    customColor,
    customHoverColor,
    showIcon = false,
    lastElementLink = false,
    center = false,
  } = props;

  function getItemLabel(value: string): React.ReactNode {
    return <span property="name">{value}</span>;
  }

  function getItemContent(
    item: BreadcrumbParam,
    depth: number,
    isLast: boolean,
    customColor: string,
    customHoverColor: string,
    generateUri: (pageType: string, uriParams: {}) => string,
    lastElementLink: boolean
  ): React.ReactNode {
    const itemLabel = getItemLabel(item.name);

    if (isLast && !lastElementLink) {
      return <span className={customColor}>{itemLabel}</span>;
    }

    const uri = item.uri || generateUri(item.pageType!, item.uriParams!);

    function onBreadcrumbClick(): void {
      if (!pageData) {
        return;
      }

      if (item.name === 'Treatwell') {
        trackTreatwellBreadcrumbClickEvent();
      } else {
        const parsedBreadcrumbUri = parseUri(
          new URL(uri).pathname,
          pageData.channel.code,
          pageData.channel.languageCode
        );

        if (
          parsedBreadcrumbUri?.values.venueType &&
          !parsedBreadcrumbUri.values.location
        ) {
          trackVenueTypeBreadcrumbClickEvent(item.id!.toString(), depth);
        }

        if (parsedBreadcrumbUri?.values.location) {
          trackLocationBreadcrumbClickEvent(item.id!.toString(), depth);
        }
      }
    }

    return (
      <>
        <a
          href={uri}
          onClick={onBreadcrumbClick}
          className={styles.link}
          property="item"
          typeof="WebPage"
        >
          <span className={clsx(customColor, customHoverColor)}>
            {itemLabel}
          </span>
        </a>
        {!isLast && (
          <span className={clsx(styles.chevron, customColor)}>&gt;</span>
        )}
      </>
    );
  }

  function getTreatmentGuideIcon(showIcon: boolean): React.ReactNode {
    return showIcon ? (
      <div className={styles.icon}>
        <IconCompass size={24} />
      </div>
    ) : null;
  }

  if (!breadcrumbsParams || !breadcrumbsParams.length) {
    return null;
  }

  const breadcrumbsSize = breadcrumbsParams.length - 1;
  const items = breadcrumbsParams.map((item, index) => {
    const isLastItem = index === breadcrumbsSize;

    const breadcrumbListItemIndex = index + 1;

    return (
      <Context.Consumer key={item.name}>
        {value => (
          <li
            className={styles.item}
            property="itemListElement"
            typeof="ListItem"
          >
            <meta property="position" content={`${breadcrumbListItemIndex}`} />
            {getItemContent(
              item,
              index + 1,
              isLastItem,
              customColor!,
              customHoverColor!,
              value.generateUri,
              lastElementLink
            )}
          </li>
        )}
      </Context.Consumer>
    );
  });

  return (
    <div className={styles.breadcrumbWrapper}>
      {getTreatmentGuideIcon(showIcon)}
      <ul
        className={clsx({
          [styles.breadcrumbs]: true,
          [styles.center]: center,
        })}
        vocab="http://schema.org/"
        typeof="BreadcrumbList"
      >
        {items}
      </ul>
    </div>
  );
}
