import {Flag} from './FeatureFlag';
import {parseCookie} from '../../../utils';

const SEPARTOR = '__';
const PREFIX = `FEATURE${SEPARTOR}`;

export const getFeatureFlagsFromCookieString = (
  cookieString: string
): Flag[] => {
  const cookies = parseCookie(cookieString);
  return Object.keys(cookies)
    .filter((key) => key.startsWith(PREFIX))
    .reduce((acc: Flag[], feature) => {
      acc.push({
        name: feature.split(SEPARTOR)[1],
        enabled: cookies[feature] === 'true',
      });
      return acc;
    }, []);
};

const isFeatureEnabled = (featureFlags: Flag[], flag: string) => {
  return featureFlags.some((featureFlag) => {
    return featureFlag.name === flag && featureFlag.enabled;
  });
};

export const isFeatureEnabledFromCookie = (
  feature: string,
  cookie: string = document.cookie
) => {
  return isFeatureEnabled(getFeatureFlagsFromCookieString(cookie), feature);
};
