import {
  mParticleLogPageView,
  mParticleTrackSessionAttributes,
} from '@treatwell/ui';
import React from 'react';
import { isTest } from 'js/helpers/environment';
import { Context } from '../components/LocaleWrapper';

const UTM_FIELDS = [
  'utm_campaign',
  'utm_content',
  'utm_marketing',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_campaign_id',
  'utm_ad_id',
  'gclid',
];

export function getUtmParameters(parameters: string) {
  const utmParameters = Object.fromEntries(
    [...new URLSearchParams(parameters).entries()].filter(([key]) =>
      UTM_FIELDS.includes(key)
    )
  );
  return utmParameters;
}

type PageLoadMetrics = {
  ['full_page_load_time']: string;
  ['server_response_time']: string;
  ['dom_content_load_time']: string;
  ['ttfb_load_time']: string;
  ['dom_complete_load_time']: string;
};

export const useReportPageLoadMetrics = (venueId?: number) => {
  const [metrics, setMetrics] = React.useState<PageLoadMetrics | null>(null);

  React.useEffect(() => {
    if (!venueId) {
      return undefined;
    }
    const observer = new PerformanceObserver(observedEntries => {
      const navigation = observedEntries.getEntriesByType(
        'navigation'
      )[0] as PerformanceNavigationTiming;
      // navigation needs to be forced to type PerformanceNavigationTiming
      // https://github.com/microsoft/TypeScript/issues/58644

      if (navigation) {
        setMetrics({
          full_page_load_time: (
            navigation.loadEventEnd - navigation.startTime
          ).toFixed(2),
          server_response_time: (
            navigation.responseEnd - navigation.fetchStart
          ).toFixed(2),
          dom_content_load_time: (
            navigation.domContentLoadedEventEnd - navigation.startTime
          ).toFixed(2),
          ttfb_load_time: (
            navigation.responseStart - navigation.startTime
          ).toFixed(2),
          dom_complete_load_time: (
            navigation.domComplete - navigation.startTime
          ).toFixed(2),
        });
      }
    });

    observer.observe({ type: 'navigation', buffered: true });

    return () => observer.disconnect();
  }, [venueId]);

  return metrics;
};

export function useTrackPageAndSessionView(
  venueId?: number,
  treatment?: string,
  treatmentType?: string,
  hasPopularTreatments?: boolean
) {
  const { channel } = React.useContext(Context);

  const loadSpeedMetrics = useReportPageLoadMetrics(venueId);

  React.useEffect(() => {
    if (isTest()) {
      return;
    }

    const utmParameters = getUtmParameters(window.location.search);
    const eventName = venueId ? 'venue_menu' : 'page_view';

    if (
      (venueId &&
        loadSpeedMetrics &&
        loadSpeedMetrics?.full_page_load_time !== '0.00') ||
      !venueId
    ) {
      // if on venue page we need to wait for the page metrics to fully load
      mParticleLogPageView(eventName, {
        country: channel?.country?.countryCode,
        referrer: document.referrer,
        url: window.location.href,
        url_path: window.location.pathname,
        query_parameters: window.location.search,
        platform: 'web',
        section: venueId ? 'booking_flow' : undefined,
        venue_id: venueId ? venueId.toString() : undefined,
        treatment,
        treatment_type: treatmentType,
        popular_treatments_displayed: hasPopularTreatments,
        ...utmParameters,
        ...loadSpeedMetrics,
      });

      mParticleTrackSessionAttributes(
        channel?.country?.countryCode,
        utmParameters
      );
    }
  }, [
    venueId,
    channel?.country?.countryCode,
    loadSpeedMetrics,
    treatment,
    treatmentType,
    hasPopularTreatments,
  ]);
}

interface Props {
  children: React.ReactNode;
  treatment?: string;
  treatmentType?: string;
  hasPopularTreatments?: boolean;
  venueId?: number;
}

export const TrackPageView = ({
  children,
  venueId,
  treatment,
  treatmentType,
  hasPopularTreatments,
}: Props) => {
  useTrackPageAndSessionView(
    venueId,
    treatment,
    treatmentType,
    hasPopularTreatments
  );
  return children;
};
