import { isServer } from 'js/helpers/environment';

async function componentFromCommonJSModule(
  componentModule,
  moduleName = 'default'
) {
  return (await componentModule)[moduleName];
}

/* eslint-disable max-len */
const mapping = {
  accountVerify: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "AccountVerifyPage" */ './AccountVerifyPage')
    ),
  accountPassword: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "AccountPasswordPage" */ './AccountPasswordPage'
      )
    ),
  bestForBrowse: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "BestForBrowsePage" */ './BestForBrowsePage/BestForBrowsePage'
      ),
      'BestForBrowsePage'
    ),
  cancellationFeedback: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "CancellationFeedbackPage" */ './CancellationFeedbackPage'
      )
    ),
  noShowFeedback: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "NoShowFeedbackPage" */ './NoShowFeedbackPage'
      )
    ),
  homepage: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "HomePage" */ './HomePage')
    ),
  question: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "QuestionPage" */ './QuestionPage')
    ),
  venue: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "VenuePage" */ './VenuePage')
    ),
  venueType: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "VenueTypePage" */ './VenueTypePage')
    ),
  venueReviewCreate: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "CreateReviewPage" */ './CreateReviewPage')
    ),
  treatment: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "TreatmentPage" */ './TreatmentPage')
    ),
  treatmentGuide: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "TreatmentGuidePage" */ './TreatmentGuidePage'
      )
    ),
  treatmentType: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "TreatmentTypePage" */ './TreatmentTypePage')
    ),
  browse: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "BrowsePage" */ './BrowsePage'),
      'BrowsePage'
    ),
  venueReviews: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "VenueReviewsPage" */ './VenueReviewsPage'),
      'VenueReviewsPage'
    ),
  lastResortError: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "LastResortErrorPage" */ './error/LastResortErrorPage/LastResortErrorPage'
      ),
      'LastResortErrorPage'
    ),
  clientError: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "ClientErrorPage" */ './error/ClientErrorPage'
      ),
      'ClientErrorPage'
    ),
  treatwellRewards: () =>
    componentFromCommonJSModule(
      import(
        /* webpackChunkName: "TreatwellRewardsPage" */ './TreatwellRewardsPage'
      ),
      'TreatwellRewardsPage'
    ),
  nearMe: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "NearMePage" */ './NearMePage'),
      'NearMePage'
    ),
  referral: () =>
    componentFromCommonJSModule(
      import(/* webpackChunkName: "ReferralPage" */ './ReferralPage'),
      'ReferralPage'
    ),
}; /* eslint-enable max-len */

const componentCache = {};

export async function loadComponent(pageType) {
  try {
    componentCache[pageType] = await mapping[pageType]();
  } catch (e) {
    console.log(e);
    // Fail silently on unknown pageTypes as these are handled elsewhere
  }
}

if (isServer) {
  Object.keys(mapping).forEach(loadComponent);
}

export default function asyncComponent(pageType) {
  return componentCache[pageType];
}
