import { DiscountOutput } from 'js/model/rainbow/create-review-page/DiscountOutput';
import { CreateReviewUserState } from 'js/model/state/CreateReviewUserState';
import { EmployeeDetailsOutput } from 'js/model/rainbow/create-review-page/EmployeeDetailsOutput';
import { ReferralEntryPoint } from 'js/redux-modules/create-review/selectors';
import { ReviewOutput } from '../ReviewOutput';

export enum UnavailableReviewType {
  VenueAlreadyReviewed = 'venue_already_reviewed',
  ReviewRequestExpired = 'review_request_expired',
  OrderAlreadyReviewed = 'order-already-reviewed',
  AppointmentAlreadyReviewed = 'appointment-already-reviewed',
}

export interface CreateReviewDataState {
  readonly venue: {
    readonly id: number;
    readonly name: string;
    readonly image: string;
  };
  readonly employee: EmployeeDetailsOutput;
  readonly multipleEmployees: boolean;
  readonly referenceToken: string;
  readonly isGoldenTicketReview: boolean;
  readonly rating: number;
  readonly unavailableReviewType?: UnavailableReviewType;
  readonly suggestedReviewerName?: string;
  readonly mainTreatmentGroupId: number;
  readonly treatmentCategories: readonly {
    readonly id: number;
    readonly name: string;
    readonly groupId: number;
  }[];
  readonly supportedVenueReviewRatingTypes: {
    type: string;
    name: string;
  }[];
  readonly discount?: Readonly<DiscountOutput>;
  readonly loyaltyReviewRewardPoints?: number;
  readonly minCharactersForEarningLoyaltyPoints?: number;
  readonly referralEntryPoint: ReferralEntryPoint;
}

export interface CreateReviewTransactionalState {
  readonly submitting: boolean;
  readonly error: unknown;
  readonly publicReviewCommentError: string;
}

export interface CreateReviewState {
  data: CreateReviewDataState;
  user: CreateReviewUserState;
  transactional: CreateReviewTransactionalState;
}

export interface CreateReviewPayload {
  reviewer: {
    name: string;
    anonymous: boolean;
    marketingOptIns: {
      venueOptIn: boolean;
      treatwellOptIn: boolean;
    };
  };
  rating: number;
  venueRatings: {
    [facet: string]: number;
  };
  treatmentCategoryRatings: {
    [index: number]: number;
  };
  sentiments?: {
    [sentimentType: string]: {
      [sentimentTypeId: number]: {
        id: number;
      }[];
    };
  };
  content: string;
  referenceToken: string;
  notifyOnReply: boolean;
}

export type ReviewConfirmationModel = ReviewOutput & {
  referralEntryPoint: ReferralEntryPoint;
};
