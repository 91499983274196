import { yieldToMain } from 'js/common/yield-to-main';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  NavigationBar = 'navigation_bar',
}

export enum Action {
  Expand = 'expand',
  Collapse = 'collapse',
  Click = 'click',
  Select = 'select',
}

function trackNavigationEvent(
  property: string,
  action: Action,
  label?: string,
  value?: string | number
): void {
  trackStructuredEvent({
    category: Category.NavigationBar,
    property,
    action,
    label,
    value,
  });
}

export const trackToggleNavigationTab = async (
  tabText: string,
  action: Action.Collapse | Action.Expand
) => {
  await yieldToMain();

  return trackNavigationEvent(tabText, action);
};

export function TrackSubNavLinkSelect(
  parentTabLabel: string,
  linkLabel: string,
  linkIndex: number
): void {
  // Because consumers of data prefer one-based indexing
  const oneBasedLinkIndex = linkIndex + 1;
  trackNavigationEvent(
    parentTabLabel,
    Action.Select,
    linkLabel,
    oneBasedLinkIndex
  );
}

export function TrackMerchSlot(slotNumber: number, slotLabel: string): void {
  trackNavigationEvent(`merch_slot_${slotNumber}`, Action.Click, slotLabel);
}
