import { AccountVerificationState } from 'js/model/state/account/verification';
import {
  LayoutHeaderBackClickAction,
  LAYOUT_HEADER_BACK_CLICKED,
} from 'js/redux-modules/layout/actions';
import {
  AccountVerificationAction,
  ACCOUNT_VERIFICATION_REQUEST,
  ACCOUNT_VERIFICATION_SUCCESS,
  ACCOUNT_VERIFICATION_FAILURE,
  ACCOUNT_VERIFICATION_CLEAR_ERROR,
} from './actions';

export function verification(
  state: AccountVerificationState = {},
  action: AccountVerificationAction | LayoutHeaderBackClickAction
): AccountVerificationState {
  switch (action.type) {
    case ACCOUNT_VERIFICATION_REQUEST:
      return {
        ...state,
        email: action.email,
        isSubmitting: true,
      };

    case ACCOUNT_VERIFICATION_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    case ACCOUNT_VERIFICATION_SUCCESS:
      return {
        ...state,
        ...action.response,
        isSubmitting: false,
        isSubmitted: true,
      };

    case ACCOUNT_VERIFICATION_CLEAR_ERROR: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { error, ...newState } = state;
      return newState;
    }
    case LAYOUT_HEADER_BACK_CLICKED:
      return {
        ...state,
        isSubmitted: false,
      };

    default:
      return state;
  }
}
