import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

export enum Action {
  View = 'view',
  Dismiss = 'dismiss',
  AutoDismiss = 'auto-dismiss',
  Click = 'click',
}

export enum Property {
  Banner = 'site_wide_banner',
  Link = 'site_wide_banner_link',
}

export async function trackEvent(
  property: Property,
  action: Action,
  campaignId: string,
  viewCount: number
): Promise<void> {
  trackStructuredEvent({
    category: 'site_wide_banner',
    property,
    action,
    label: campaignId,
    value: viewCount,
  });
}
