import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

const category = 'account_header';

export function onAccountHeaderLogoClick(): Promise<void> {
  return trackStructuredEvent({
    category,
    property: 'logo_button',
    action: 'click',
  });
}

export function onAccountHeaderBackClick(): Promise<void> {
  return trackStructuredEvent({
    category,
    property: 'home_button',
    action: 'click',
  });
}
