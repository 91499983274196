import { isTest } from 'js/helpers/environment';
import { logger } from './pino';

interface RequestFields {
  ruid: string;
  host?: string;
  method: string;
  url: string;
  responseCode: number;
  remoteIp?: string;
  originServer?: string;
  duration: number;
  contentLength?: string;
  userAgent?: string;
  message?: string;
}

export function logRequest(
  type: 'pageRequest' | 'apiRequest' | 'assetRequest',
  fields: RequestFields
): void {
  logger.info(fields, type);
}

export function logStack(ruid: string, error: Error): void {
  if (isTest()) {
    console.error(error);
    return;
  }

  logger.error(
    {
      ruid,
      error: error.stack || error,
    },
    'stack'
  );
}
