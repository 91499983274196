import { httpGetJson, httpPost } from 'js/common/http';
import { CreateReviewPayload } from 'js/model/state/createReview';
import { ReviewOutput } from 'js/model/ReviewOutput';
import { VenueReviewCreatorOutputWrappedPageOutput } from 'js/model/rainbow/create-review-page/VenueReviewCreatorOutputWrappedPageOutput';
import { VenueReviewCreatorOutput } from 'js/model/rainbow/create-review-page/VenueReviewCreatorOutput';
import {
  ApiErrorOutput,
  isApiErrorOutput,
} from 'js/model/rainbow/page/ApiErrorOutput';

interface CreateReviewParameters {
  normalisedName?: string;
  reference?: string;
  orderToken?: string;
  rating?: string;
}

export async function getReviewData(
  ruid: string,
  requestHeaders: object,
  parameters: CreateReviewParameters
): Promise<VenueReviewCreatorOutput | ApiErrorOutput> {
  const normalisedName = parameters.normalisedName;
  const type = parameters.reference ? 'reference' : 'order';
  const token = parameters.reference || parameters.orderToken;
  const rating = parameters.rating ? `?rating=${parameters.rating}` : '';
  const path = `/api/v1/page/venue/${normalisedName}/review/create/${type}/${token}/${rating}`;

  const json = await httpGetJson<VenueReviewCreatorOutputWrappedPageOutput>(
    path,
    {
      ruid,
      requestHeaders,
    }
  );

  if (isApiErrorOutput(json)) {
    return json;
  }

  if (json.pageData[0].type !== 'review_creator') {
    throw new Error('invalid api response');
  }

  return json.pageData[0].data;
}

export async function postReview(
  reviewPayload: CreateReviewPayload,
  venueId: number
): Promise<ReviewOutput | null> {
  return httpPost<ReviewOutput>(`/api/v1/venue/${venueId}/review/create`, {
    requestHeaders: {},
    data: reviewPayload,
  });
}
