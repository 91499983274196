import { TranslatedError } from './getTranslatedError';

export const translatedError: { [key: string]: TranslatedError } = {
  en: {
    title: 'This isn’t what you’re looking for, right?',
    message:
      'Sorry something went wrong with this page. But don’t worry, there is still a brighter way to go.',
    buttonLabel: 'Go to homepage',
  },
  de: {
    title: 'Das ist nicht das, wonach du suchst, oder?',
    message:
      'Hoppla, beim Laden dieser Seite ist etwas schiefgelaufen. Aber keine Sorge, es gibt immer noch einen anderen Weg.',
    buttonLabel: 'Zur Website',
  },
  es: {
    title: 'Esto no es lo que estás buscando, ¿verdad?',
    message:
      'Lo sentimos, algo no funcionó con esta página. Pero no te preocupes, aún hay otra manera más brillante de llegar.',
    buttonLabel: 'Ir a la homepage',
  },
  fr: {
    title: "Ce n'est pas ce que vous cherchez, n'est-ce pas?",
    message:
      "Une erreur s'est produite sur cettepage. Pas d'inquiétude, il y a une solution pour tout !",
    buttonLabel: "Retourner sur la page d'accueil",
  },
  it: {
    title: 'Questo non è quello che stai cercando, giusto?',
    message:
      "Spiacenti, qualcosa non ha funzionato in questa pagina. Ma non preoccuparti, c'è un altro modo.",
    buttonLabel: 'Vai alla homepage',
  },
  lt: {
    title: 'Tai yra ne tai, ko ieškojai, ar ne?',
    message:
      'Atsiprašome, kažkas yra ne taip su šiuo puslapiu. Bet tikrai neverta jaudintis- galima išmėginti kitą kelią.',
    buttonLabel: 'Eiti į pagrindinį puslapį',
  },
  nl: {
    title: 'Dit is niet waar je op zoek naar bent, toch?',
    message:
      'Sorry er is iets misgegaan met deze pagina. Geen zorgen, je kunt altijd terug naar de startpagina.',
    buttonLabel: 'Ga naar de startpagina',
  },
  lv: {
    title: 'Ne gluži tas, ko meklējāt? ',
    message:
      'Atvainojamies. Kaut kas ir nogājis greizi ar šo lapu. Neuztraucieties, joprojām ir iespēja nokļut tur, kur vēlējāties.  ',
    buttonLabel: 'Doties uz mājas lapu',
  },
  dk: {
    title: 'Det her var ikke det du ledte efter, vel?',
    message:
      'Beklager, men det ser ud til at der opstod en fejl på siden. Bare rolig, der er stadig en smartere vej at gå.',
    buttonLabel: 'Gå til startsiden',
  },
};
