/* eslint-disable @typescript-eslint/explicit-module-boundary-types, no-return-assign */

type CookieSetter = typeof cookieSetter;
type CookieGetter = typeof cookieGetter;

// these are used so we can easily inject mocks in the tests
// and not need to mock `document.cookie`
const cookieSetter = (value: string) => {
  document.cookie = value;
};
const cookieGetter = () => document.cookie;

export const setCookie = (
  {
    name,
    value,
    expires,
    domain,
  }: {
    name: string;
    value: string;
    expires: string;
    domain?: string;
  },
  set: CookieSetter = cookieSetter
) => {
  set(
    `${name}=${encodeURIComponent(value)}; expires=${expires}; ${
      domain ? `domain=${domain};` : ''
    } path=/; secure;`
  );
};

export const getCookie = (
  cookieKey: string,
  get: CookieGetter = cookieGetter
) => {
  const cookieName = `${cookieKey}=`;
  const cookieParts = get().split(';');

  for (let cookie of cookieParts) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookie.substring(cookieName.length, cookie.length)
      );
    }
  }
};

export type CookieValue = {
  ranSiteID: string;
  ranMID: string;
  timestamp: string;
  formattedDate: string;
};

export const createCookieValue = ({
  ranSiteID,
  ranMID,
  timestamp,
  formattedDate,
}: CookieValue) => {
  return `amid:${ranMID}|ald:${formattedDate}|auld:${timestamp}|atrv:${ranSiteID}`;
};

export const parseCookieValue = (value: string): CookieValue => {
  const parsed: CookieValue = {
    ranSiteID: '',
    ranMID: '',
    timestamp: '',
    formattedDate: '',
  };
  const parts = value.split('|');

  parts.forEach(part => {
    const [key, value] = part.split(':');
    switch (key) {
      case 'amid':
        parsed.ranMID = value;
        break;
      case 'ald':
        parsed.formattedDate = value;
        break;
      case 'auld':
        parsed.timestamp = value;
        break;
      case 'atrv':
        parsed.ranSiteID = value;
        break;
    }
  });

  return parsed;
};
