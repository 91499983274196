/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M8 0a8 8 0 110 16A8 8 0 018 0zM2.004 8.222L2 8a6 6 0 005.777 5.996L7 10.5 5 11l.5-2-3.496-.778zm11.992.002L10.5 9l.5 2-2-.5-.777 3.495a6 6 0 005.773-5.771zm-5.772-6.22L9 5.5l2-.5-.5 2 3.495.777a6 6 0 00-5.771-5.773zm-.447 0L8 2a6 6 0 00-5.996 5.776L5.5 7 5 5l2 .5.777-3.496z',
  ],

  24: [
    'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zM9.5 13l-5.498-.846L4 12a8 8 0 007.846 7.999L11 14.5 8 16l1.5-3zm10.498-.846L14.5 13l1.5 3-3-1.5-.846 5.498.095-.002A8 8 0 0020 12.154zM11 9.5l.846-5.498L12 4a8 8 0 00-7.999 7.846L9.5 11 8 8l3 1.5zm1.154-5.499L13 9.5 16 8l-1.5 3 5.498.846-.002-.095A8 8 0 0012.154 4z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconCompass = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconCompass.displayName = 'IconCompass';
