import React from 'react';
import { ErrorPage } from 'js/pages/error/ErrorPage';

interface State {
  hasError?: boolean;
  error?: Error;
  info?: React.ErrorInfo;
  children?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<State> {
  public state: State = {};

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error(error, info);
    this.setState({ error, info });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} info={this.state.info} />;
    }

    return this.props.children;
  }
}
