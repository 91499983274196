import React from 'react';
import get from 'lodash.get';
import { LayoutConfig } from 'js/model/state/layout';

import { Context } from 'js/components/LocaleWrapper';
import * as track from './tracking';
import { AccountHeader } from './AccountHeader';

interface Props {
  layoutConfig?: LayoutConfig;
  onBackClick: () => unknown;
}

export function AccountHeaderContainer(props: Props): React.ReactElement {
  const { layoutConfig } = props;
  const context = React.useContext(Context);

  async function onLogoClick(
    event: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> {
    const { href } = event.target as HTMLAnchorElement;
    event.preventDefault();
    await track.onAccountHeaderLogoClick();
    if (href) {
      window.location.assign(href);
    }
  }

  async function onBackClick(
    event: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> {
    event.preventDefault();
    await track.onAccountHeaderBackClick();
    const backButton = layoutConfig?.header?.backButton;
    props.onBackClick();
    if (!backButton?.override && backButton?.href) {
      window.location.assign(backButton.href);
    }
  }

  function getBackButtonText(): string {
    const { cms } = context.pageData;
    const label = layoutConfig?.header?.backButton?.label;
    return (label && get(cms, `common.header-links.${label}`)) || '';
  }

  function getBackButtonLink(): string {
    return layoutConfig?.header?.backButton?.href || '#';
  }

  const backButtonLink = getBackButtonLink();
  const backButtonText = getBackButtonText();
  const homeUrl = context.generateUri('homepage');

  return (
    <AccountHeader
      showBackButton={layoutConfig?.header?.backButton?.show}
      backUri={backButtonLink}
      backLabel={backButtonText}
      onBackClick={onBackClick}
      logoUri={homeUrl}
      onLogoClick={onLogoClick}
    />
  );
}
