import { isDictionary } from 'js/types/generic-types';
import { isString } from 'js/types/primitive-predicates';
import { assertExhausted } from 'js/types/assert-unreachable';

export enum ApiErrorType {
  BusinessLogic = 'business_logic',
  InputData = 'input_data',
  Unexpected = 'unexpected',
}

export interface ApiErrorInstanceOutput {
  type: ApiErrorType;
  name: string;
  field?: string;
  message?: string;
  /**
   * String representation of the underlying exception stack trace.
   * Optional in cases there is no underlying error, or that the system configuration blocks it.
   * This may not be exposed in production
   */
  stackTrace?: string;
}

export function isApiErrorType(candidate: unknown): candidate is ApiErrorType {
  const assertCandidate = candidate as ApiErrorType;
  switch (assertCandidate) {
    case ApiErrorType.BusinessLogic:
    case ApiErrorType.InputData:
    case ApiErrorType.Unexpected:
      return true;
    default:
      assertExhausted(assertCandidate);
      return false;
  }
}

export function isApiErrorInstanceOutput(
  candidate: unknown
): candidate is ApiErrorInstanceOutput {
  return (
    isDictionary(candidate) &&
    isApiErrorType(candidate.type) &&
    isString(candidate.name) &&
    (candidate.field === undefined || isString(candidate.field)) &&
    (candidate.message === undefined || isString(candidate.message)) &&
    (candidate.stackTrace === undefined || isString(candidate.stackTrace))
  );
}
