import {TreatwellAttributes} from '../types';

export type MParticleAttributes = {
  country: string;
  platform: 'web';
  'Experiment name': string;
  'Variant name': string;
  is_authenticated: boolean;
  is_web_mobile: boolean;
};

export type MParticleIntegration = {
  type: 'mparticle';
  send: (attributes: MParticleAttributes) => void;
};

export function getMParticleEventAttributes(
  experimentKey: string,
  variantKey: string,
  attributes: TreatwellAttributes,
): MParticleAttributes {
  return {
    country: attributes.country ?? '',
    platform: 'web',
    'Experiment name': experimentKey,
    'Variant name': variantKey,
    is_authenticated: attributes.is_authenticated ?? false,
    is_web_mobile: attributes.is_web_mobile,
  };
}
