import * as React from 'react';

import ToastContext from './ToastContext';
import {Toast, Notify, Dismiss, NotifyOptions} from './types';

export const useToast = () => {
  const [toasts, setToasts] = React.useState<Toast[]>([]);

  const notify: Notify = (toast) => {
    const lastToast = toasts[toasts.length - 1];
    const newId = lastToast ? lastToast.id + 1 : toasts.length;

    setToasts((state) => [...state, {id: newId, ...toast}]);
  };

  const dismiss: Dismiss = (toastId) => {
    setToasts((state) => state.filter((toast) => toast.id !== toastId));
  };

  const dismissAll = () => {
    setToasts([]);
  };

  return {toasts, notify, dismiss, dismissAll};
};

export const useNotify = () => {
  const {notify, dismissAll} = React.useContext(ToastContext);

  function notifySuccess(message: string, options?: NotifyOptions): void;
  function notifySuccess(options: NotifyOptions): void;
  function notifySuccess(
    messageOrOptions: string | NotifyOptions,
    options?: NotifyOptions
  ) {
    if (typeof messageOrOptions === 'string') {
      notify({message: messageOrOptions, type: 'success', ...options});
    } else {
      notify({type: 'success', ...messageOrOptions});
    }
  }

  const notifyError = (message: string, options?: NotifyOptions) =>
    notify({message, type: 'error', ...options});

  return {notifySuccess, notifyError, notify, dismissAll};
};
