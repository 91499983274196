import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'js/components/ErrorBoundary';
import styles from 'assets/style/app.module.css';
import { ClientErrorPage } from './error/ClientErrorPage';
import { Error500Page } from './error/Error500Page';
import asyncComponent from './async-component';

const widgetPageTypes = ['venue', '500', '404'];

export default function Routes(props) {
  const { pageType, isWidget } = props;

  let Component;
  if (pageType === '500') {
    Component = Error500Page;
  } else {
    Component = asyncComponent(pageType);
  }

  if (
    (isWidget && !widgetPageTypes.includes(pageType)) ||
    pageType === '404' ||
    !Component
  ) {
    Component = ClientErrorPage;
  }

  return (
    <ErrorBoundary>
      <div className={styles.content}>
        <Component />
      </div>
    </ErrorBoundary>
  );
}

Routes.propTypes = {
  pageType: PropTypes.string.isRequired,
  isWidget: PropTypes.bool.isRequired,
};
