import { storage } from '@treatwell/ui';
import { getFragmentValue } from './uri-util';

function shouldHideOptIn(isWidget) {
  if (!isWidget) {
    return false;
  }

  return (
    getFragmentValue('utm_source') === 'email' ||
    getFragmentValue('utm_medium') === 'email'
  );
}

export default function storeHideEmailOptIn(isWidget) {
  if (shouldHideOptIn(isWidget)) {
    storage.session.setItem('hideEmailOptIn', 'true');
  }
}
