import * as React from 'react';

import {Toast, Notify, Dismiss} from './types';

interface ToastContext {
  toasts: Toast[];
  notify: Notify;
  dismiss: Dismiss;
  dismissAll: () => void;
}

const noop = (): void => undefined;

export const ctx = React.createContext<ToastContext>({
  toasts: [],
  notify: noop,
  dismiss: noop,
  dismissAll: noop,
});

export const ToastContextProvider = ctx.Provider;
export const ToastContextConsumer = ctx.Consumer;

export default ctx;
