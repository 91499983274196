import React from 'react';
import clsx from 'clsx';

import {Text} from '../../Atoms/Text';
import type {Icon} from '../../Atoms/Icon';
import {Stack} from '../../Layout/Stack';
import styles from './Message.module.css';
import {MediaObject, MediaObjectProps} from '../MediaObject';
import {Spacing} from '@treatwell/design-tokens';

export interface Props {
  children: React.ReactNode;
  /** Sets the icon on the left of the message. */
  icon?: Icon | React.ReactElement;
  /** Sets the value of the element's class attribute. */
  className?: string;
  /** Reverse order of child elements. */
  reverse?: boolean;
  /** Adjusts the child elements on the main axis. */
  justify?: MediaObjectProps['justify'];
  /** Adds optional call to action element to the right of main content  */
  cta?: React.ReactElement;
  space?: Spacing;
}

export const Message = React.forwardRef(
  (
    {
      icon,
      children,
      className,
      justify,
      reverse = false,
      cta,
      space = 'xs',
    }: Props,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div className={clsx(styles.root, className)} ref={ref}>
        <MediaObject
          justify={justify}
          align="center"
          space={space}
          reverse={reverse}
        >
          {icon &&
            React.cloneElement(icon, {
              className: clsx(icon.props.className, styles.icon),
            })}
          <Stack space="md">{children}</Stack>
          {cta && <div className={styles.cta}>{cta}</div>}
        </MediaObject>
      </div>
    );
  }
);

Message.displayName = 'Message';

export type MessageProps = {
  text: string;
  icon?: Icon;
  cta?: React.ReactElement;
  ref?: React.Ref<HTMLDivElement>;
};

export const MessageInfo = ({text, icon, cta, ref}: MessageProps) => (
  <Message icon={icon} className={styles.info} cta={cta} ref={ref}>
    <Text as="p" type="caption">
      {text}
    </Text>
  </Message>
);

MessageInfo.displayName = 'MessageInfo';

export const MessageSuccess = ({icon, text, cta, ref}: MessageProps) => (
  <Message icon={icon} className={styles.success} cta={cta} ref={ref}>
    <Text as="p" type="caption">
      {text}
    </Text>
  </Message>
);

MessageSuccess.displayName = 'MessageSuccess';

export const MessageWarning = ({icon, text, cta, ref}: MessageProps) => (
  <Message icon={icon} className={styles.warning} cta={cta} ref={ref}>
    <Text as="p" type="caption">
      {text}
    </Text>
  </Message>
);

MessageWarning.displayName = 'MessageWarning';

export const MessageError = ({icon, text, cta, ref}: MessageProps) => (
  <Message icon={icon} className={styles.error} cta={cta} ref={ref}>
    <Text as="p" type="caption">
      {text}
    </Text>
  </Message>
);

MessageError.displayName = 'MessageError';
