import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Function to check if the component being wrapped has the method defined
 * @param  {Object}   scope    scope object where the wrapped component is set in its ref
 * @param  {String}   handler  method name to check
 * @return {Boolean}           check boolean
 */
const checkHandler = (scope, handler) =>
  scope.refs &&
  scope.refs.wrappedComponent &&
  typeof scope.refs.wrappedComponent[handler] === 'function';

/**
 * Higher Order Component to enhance any Component with the clicking outside and window resize events
 * This enhancer will only add the events if the Component has the respective handlers
 * Based on https://github.com/kentor/react-click-outside
 * @param  {Object} Component  React class component to be enhanced
 * @return {Object}            React class component
 */
export default Component => {
  const componentName = Component.displayName || Component.name;

  return class extends React.Component {
    static displayName = `clickOutside-${componentName}`;

    componentDidMount() {
      if (checkHandler(this, 'handleClickOutside')) {
        document.addEventListener('click', this.handleClickOutside, true);
      }

      if (checkHandler(this, 'handleWindowResize')) {
        window.addEventListener('resize', this.handleWindowResize, true);
      }
    }

    componentWillUnmount() {
      if (checkHandler(this, 'handleClickOutside')) {
        document.removeEventListener('click', this.handleClickOutside, true);
      }
      if (checkHandler(this, 'handleWindowResize')) {
        window.removeEventListener('resize', this.handleWindowResize, true);
      }
    }

    handleClickOutside = evt => {
      const domNode = ReactDOM.findDOMNode(this);

      if (!domNode) {
        return;
      }

      const isOutside =
        !domNode.contains(evt.target) &&
        !domNode.parentNode.contains(evt.target);
      const isOutsideWindow = evt.target.tagName === 'HTML';

      if (!domNode || (isOutside && !isOutsideWindow)) {
        this.refs.wrappedComponent.handleClickOutside(evt);
      }
    };

    handleWindowResize = evt => {
      this.refs.wrappedComponent.handleWindowResize(evt);
    };

    render() {
      return <Component {...this.props} ref="wrappedComponent" />;
    }
  };
};
