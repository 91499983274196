import { AccountDetailsState } from 'js/model/state/account/details';
import { AccountVerificationState } from 'js/model/state/account/verification';
import get from 'lodash.get';
import { AccountRelevantReduxState } from 'js/redux-modules/account/AccountRelevantReduxState';

export const getAccountDetails = (
  state: AccountRelevantReduxState
): AccountDetailsState =>
  get(state, ['account', 'details']) || { isFetching: false };
export const getAccountVerification = (
  state: AccountRelevantReduxState
): AccountVerificationState => get(state, ['account', 'verification']) || {};
