import {detectDevice, DeviceMobile, DeviceDesktop} from './detectDevice';
import {useDeviceContext} from './DeviceProvider';

export function useDevice() {
  const {type, os} = detectDevice(useDeviceContext());
  return {
    isMobile: type === DeviceMobile,
    isDesktop: type === DeviceDesktop,
    os,
    type,
  };
}
