import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {Toast} from './Toast';
import ToastContext from './ToastContext';

type Props = {root: string};

const ToastPortal = ({root}: Props): React.ReactPortal | null => {
  let container;
  const {toasts, dismiss} = React.useContext(ToastContext);

  if (typeof window !== 'undefined') {
    let rootContainer = document.querySelector('#toast-portal');

    if (!rootContainer) {
      rootContainer = document.createElement('div');
      rootContainer.setAttribute('id', 'toast-portal');
      rootContainer.setAttribute('role', 'alert');
      rootContainer.setAttribute('data-cy', 'ToastPortal');
      const parentElem = document.querySelector(`#${root}`);
      parentElem?.appendChild(rootContainer);
    }

    container = rootContainer;
  }

  const element = (
    <>
      {toasts.map((toast) => (
        <Toast key={toast.id} dismiss={dismiss} {...toast} />
      ))}
    </>
  );

  // or else it will break during SSR
  return container ? ReactDOM.createPortal(element, container) : null;
};

export default ToastPortal;
