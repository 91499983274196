export enum SERVICE_POPUP_ACTION_TYPE {
  OPEN = 'venue-page/popup/OPEN_SERVICE_POPUP',
  CLOSE = 'venue-page/popup/CLOSE_SERVICE_POPUP',
}

interface ServicePopupState {
  serviceId?: string;
}

interface ServicePopupAction {
  type: SERVICE_POPUP_ACTION_TYPE;
  serviceId?: string;
}

export const openServicePopup = (serviceId: string): ServicePopupAction => ({
  type: SERVICE_POPUP_ACTION_TYPE.OPEN,
  serviceId,
});

export const closeServicePopup = (): ServicePopupAction => ({
  type: SERVICE_POPUP_ACTION_TYPE.CLOSE,
});

export const servicePopup = (
  state: ServicePopupState | null = null,
  action: ServicePopupAction
): ServicePopupState | null => {
  switch (action.type) {
    case SERVICE_POPUP_ACTION_TYPE.CLOSE:
      return null;
    case SERVICE_POPUP_ACTION_TYPE.OPEN:
      return { serviceId: action.serviceId };
    default:
      return state;
  }
};
