export function hasChannelFeature(
  features: string[],
  feature: string
): boolean {
  return features.includes(feature);
}

// The exported names match these enum values in
// com.wahanda.service.bean.common.CodedEnumeration.ChannelFeatureCode
export const feature = {
  ONETRUST_COOKIE_BANNER: 'onetrust_cookie_banner',
};
