import React from 'react';
import clsx from 'clsx';

import {Spacing} from '@treatwell/design-tokens';

import styles from './MediaObject.module.css';
import {Inline, InlineProps} from '../../Layout/Inline';

export type Props = {
  children: React.ReactNode;
  /** Sets the space between elements based on values from the design-tokens by default. */
  space?: Spacing;
  /** Adjusts the child elements on the cross axis. */
  align?: 'center' | 'start';
  /** Adjusts the child elements on the main axis. */
  justify?: InlineProps['justify'];
  /** Reverse order of child elements. */
  reverse?: boolean;
  mediaItemClass?: string;
};

const wrapChildren = (
  children: React.ReactNode,
  className?: string
): React.ReactNode => {
  return React.Children.map(children, (child, index) => {
    if (index !== 0) {
      return child;
    }
    const isChildAFragment =
      React.isValidElement(child) && child.type === React.Fragment;

    if (isChildAFragment) {
      return wrapChildren((child.props as Props).children, className);
    }
    return <div className={clsx(styles.mediaItem, className)}>{child}</div>;
  });
};

export const MediaObject = ({
  children,
  justify,
  space = 'md',
  align = 'start',
  reverse = false,
  mediaItemClass,
}: Props) => {
  const inlineChildren = wrapChildren(children, mediaItemClass);
  return (
    <Inline justify={justify} align={align} space={space} reverse={reverse}>
      {inlineChildren}
    </Inline>
  );
};

MediaObject.displayName = 'MediaObject';
