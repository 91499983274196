/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Allows to compile-time assert that a variable has been exhausted.
 * assertUnreachable should be returned.
 * In the following example the type system would warn when a new union type is added.
 *
 * @param exhaustedVariable: a variable that has been completely exhausted
 *
 * @example
 * const foo: 'a' | 'b';
 * switch (foo) {
 *   case 'a': return;
 *   case 'b': return;
 *   default: return assertUnreachable(foo);
 * }
 *
 * @example
 * enum Foo {
 *   A,
 *   B
 * }
 *
 * const foo: Foo;
 * switch (foo) {
 *   case Foo.A: return;
 *   case Foo.B: return;
 *   default: return assertUnreachable(foo);
 * }
 */

export function assertUnreachable(exhaustedVariable: never): never {
  throw new Error('This can not be reached at runtime');
}

/**
 * Same as assertUnreachable but without throwing or returning never.
 * This is helpful in type guards.
 *
 * @param exhaustedVariable: a variable that has been completely exhausted
 */
export function assertExhausted(exhaustedVariable: never): void {}
