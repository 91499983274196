import { storage } from '@treatwell/ui';

const key = 'site-wide-banner';

export interface Data {
  autoDismissed: boolean;
  dismissed: boolean;
  campaignId: string;
  linkText: string;
  linkUrl: string;
  messageText: string;
  views: number;
}

export function getData(): Data {
  const data = storage.local.getItem(key);
  if (data === null) {
    return JSON.parse('{}');
  }
  return data;
}

export function saveData(data: Data): void {
  storage.local.setItem(key, data);
}
