import { useDevice, Viewport } from '@treatwell/ui';
import React from 'react';
import HeaderWrapper from '../HeaderWrapper';
import VenuePageHeaderMobileContainer from './VenuePageHeaderMobileContainer';

export const VenuePageHeader = ({ pageType }: { pageType: string }) => {
  const { isMobile, isDesktop } = useDevice();

  return (
    <>
      <Viewport device={['mobile', 'tablet']} serverRender={isMobile}>
        <VenuePageHeaderMobileContainer />
      </Viewport>
      <Viewport device="desktop" serverRender={isDesktop}>
        <HeaderWrapper pageType={pageType} />
      </Viewport>
    </>
  );
};
