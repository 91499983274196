import React from 'react';
import clsx from 'clsx';
import { ButtonColour, ButtonSize } from 'js/components/Button';
import baseStyles from 'js/components/Button/Button.module.css';
import anchorStyles from './ButtonAnchor.module.css';

interface Props {
  colour: ButtonColour;
  label: string;
  href: string;
  icon?: (classname: string) => React.ReactElement;
  size?: ButtonSize;
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
}

export class ButtonAnchor extends React.PureComponent<Props> {
  private getSize(): string {
    switch (this.props.size) {
      case ButtonSize.Small:
        return baseStyles.small;
      case ButtonSize.OnBackground:
        return baseStyles.onbackground;
      case ButtonSize.Medium:
        return clsx(baseStyles.medium, anchorStyles.medium);
      case ButtonSize.Normal:
      default:
        return baseStyles.normal;
    }
  }

  public render(): React.ReactNode {
    const classes = clsx(
      baseStyles.button,
      baseStyles[this.props.colour],
      anchorStyles.button,
      this.getSize()
    );

    const iconStyle = clsx(baseStyles.icon, anchorStyles.icon);

    return (
      <a
        className={classes}
        href={this.props.href}
        onClick={this.props.onClick}
      >
        {this.props.icon && (
          <div className={iconStyle}>{this.props.icon(baseStyles.button)}</div>
        )}
        <div className={baseStyles.label}>{this.props.label}</div>
      </a>
    );
  }
}
