import { logStack } from 'js/common/log';

const hijackPreventionPrefix = ")]}',";

export function parseJson<T>(text: string, ruid = ''): T {
  try {
    if (text.startsWith(hijackPreventionPrefix)) {
      return JSON.parse(text.slice(hijackPreventionPrefix.length));
    }
    if (text.trim()) {
      return JSON.parse(text);
    }
    throw 'expected JSON, but empty response';
  } catch (error) {
    if (error instanceof Error) {
      logStack(ruid, error);
    }
    throw error;
  }
}
