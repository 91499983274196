import { httpGetJson, httpPost } from 'js/common/http';
import { RequestData } from 'js/helpers/service';

const mpApiHost = process.env.MP_API_URI;
const mpApiBaseUrl = `http://${mpApiHost}`;
const mpApiUrl = `api/v2`;

export type PromotionInfoResponse = {
  minimumSpendAmount: number;
  rewardAmountForReferrer: number;
  rewardAmountForReferee: number;
};
export const composeFetchPromotionInfo = (baseUrl = mpApiBaseUrl) => (
  state?: RequestData
) => {
  const path = state ? `${baseUrl}/${mpApiUrl}` : `/${mpApiUrl}`;
  const requestHeaders = state
    ? {
        'x-forwarded-host':
          state.requestHeaders['x-forwarded-host'] || state.requestHeaders.host,
      }
    : {};

  return httpGetJson<PromotionInfoResponse>(`${path}/referral/promotion-info`, {
    requestHeaders,
  });
};

export type ReferrerNameResponse = {
  name?: string;
};
export const composeFetchReferrerName = (baseUrl = mpApiBaseUrl) => (
  state: RequestData,
  referralCode?: string
) =>
  httpGetJson<ReferrerNameResponse>(
    `${baseUrl}/${mpApiUrl}/referral/referrer/${referralCode}/name`,
    {
      requestHeaders: {
        'x-forwarded-host': state.requestHeaders['x-forwarded-host'],
      },
    }
  );

export type DynamicLinkResponse = {
  dynamicLink: string;
};
export const fetchDynamicLink = (state: RequestData, referralCode?: string) =>
  httpGetJson<DynamicLinkResponse>(
    `${mpApiBaseUrl}/${mpApiUrl}/referral/referrer/${referralCode}/dynamic-link`,
    {
      requestHeaders: {
        'x-forwarded-host': state.requestHeaders['x-forwarded-host'],
      },
    }
  );

export type ReferralFAQsResponse = {
  question: string;
  answer: string;
}[];
export const composeFetchReferralFAQs = (baseUrl = mpApiBaseUrl) => (
  state: RequestData
) =>
  httpGetJson<ReferralFAQsResponse>(
    `${baseUrl}/${mpApiUrl}/referral/landing-page/faqs`,
    {
      requestHeaders: {
        'x-forwarded-host': state.requestHeaders['x-forwarded-host'],
      },
    }
  );

export type ReferralEligibility = {
  eligibleToRefer: boolean;
};
export const composeFetchReferralEligibility = (baseUrl = mpApiBaseUrl) => (
  state: RequestData
) =>
  httpGetJson<ReferralEligibility>(
    `${baseUrl}/${mpApiUrl}/referral/eligibility`,
    {
      requestHeaders: {
        'x-forwarded-host': state.requestHeaders['x-forwarded-host'],
        cookie: state.requestHeaders.cookie,
      },
    }
  );

export const INVITE_URL_SUCCESS_RESPONSE = 'SUCCESS';
const INVITE_URL_FAILED_RESPONSE = 'FAILED';
const INVITE_URL_INELIGIBLE_RESPONSE = 'ACCOUNT_INELIGIBLE';

export type InviteUrlApiResponse =
  | {
      result: typeof INVITE_URL_SUCCESS_RESPONSE;
      inviteUrl: string;
    }
  | {
      result:
        | typeof INVITE_URL_FAILED_RESPONSE
        | typeof INVITE_URL_INELIGIBLE_RESPONSE;
    };

export const composeFetchReferralInviteUrl = (baseUrl = mpApiBaseUrl) => (
  state?: RequestData
) => {
  const path = state ? `${baseUrl}/${mpApiUrl}` : `/${mpApiUrl}`;
  const requestHeaders = state
    ? {
        'x-forwarded-host': state.requestHeaders['x-forwarded-host'],
      }
    : {};

  return httpPost<InviteUrlApiResponse>(`${path}/referral/invite-url`, {
    requestHeaders,
  });
};
