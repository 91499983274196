import { CreateReviewSelector } from 'js/redux-modules/create-review/selectors';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { ReduxState } from 'js/model/state';
import {
  CreateReviewState,
  CreateReviewPayload,
  ReviewConfirmationModel,
} from 'js/model/state/createReview';
import {
  INVITE_URL_SUCCESS_RESPONSE,
  composeFetchPromotionInfo,
  composeFetchReferralInviteUrl,
} from 'js/service/referralService';
import { postReview } from 'js/service/createReview';

export enum CreateReviewActionType {
  Request = 'CREATE_REVIEW_REQUEST',
  RequestSuccess = 'CREATE_REVIEW_REQUEST_SUCCESS',
  RequestFailure = 'CREATE_REVIEW_REQUEST_FAILURE',
  SetOverallRating = 'CREATE_REVIEW_SET_OVERALL_RATING',
  SetVenueRating = 'CREATE_REVIEW_SET_VENUE_RATING',
  SetTreatmentRating = 'CREATE_REVIEW_SET_TREATMENT_RATING',
  SetEmployeeRating = 'CREATE_REVIEW_SET_EMPLOYEE_RATING',
  SetPublicReviewComment = 'CREATE_REVIEW_SET_PUBLIC_REVIEW_COMMENT',
  SetPublicReviewCommentError = 'CREATE_REVIEW_SET_PUBLIC_REVIEW_COMMENT_ERROR',
  SetPublicReviewName = 'CREATE_REVIEW_SET_PUBLIC_REVIEW_NAME',
  SetPublicReviewIsAnonymous = 'CREATE_REVIEW_SET_PUBLIC_REVIEW_IS_ANONYMOUS',
  SetEmployeeSentiment = 'CREATE_REVIEW_SET_EMPLOYEE_SENTIMENT',
  UpdateScreen = 'CREATE_REVIEW_UPDATE_SCREEN',
  ReplaceUserState = 'CREATE_REVIEW_REPLACE_USER_STATE',
  RestoreUserStateFromLocalStorage = 'CREATE_REVIEW_RESTORE_USER_STATE_FROM_LOCAL_STORAGE',
  SetPublicReviewNameError = 'CREATE_REVIEW_SET_PUBLIC_REVIEW_NAME_ERROR',
}

export interface CreateReviewRequestAction {
  type: CreateReviewActionType.Request;
  reviewDetails: CreateReviewState;
}

export interface CreateReviewSuccessAction {
  type: CreateReviewActionType.RequestSuccess;
  response: ReviewConfirmationModel | null;
}

export interface CreateReviewFailAction {
  type: CreateReviewActionType.RequestFailure;
  error: unknown;
}

export interface CreateReviewSetOverallRating {
  type: CreateReviewActionType.SetOverallRating;
  rating: number;
}

export interface CreateReviewSetVenueRating {
  type: CreateReviewActionType.SetVenueRating;
  facet: string;
  rating: number;
}

export interface CreateReviewSetTreatmentRating {
  type: CreateReviewActionType.SetTreatmentRating;
  treatmentId: number;
  rating: number;
}

export interface CreateReviewSetEmployeeRating {
  type: CreateReviewActionType.SetEmployeeRating;
  rating: number;
}

export interface CreateReviewSetPublicReviewComment {
  type: CreateReviewActionType.SetPublicReviewComment;
  comment: string;
}

export interface CreateReviewSetPublicReviewCommentError {
  type: CreateReviewActionType.SetPublicReviewCommentError;
  error: string;
}

export interface CreateReviewSetPublicReviewName {
  type: CreateReviewActionType.SetPublicReviewName;
  name: string;
}

export interface CreateReviewSetPublicReviewerNameError {
  type: CreateReviewActionType.SetPublicReviewNameError;
  error: boolean;
}

export interface CreateReviewSetPublicReviewIsAnonymous {
  type: CreateReviewActionType.SetPublicReviewIsAnonymous;
  isAnonymous: boolean;
}

export interface CreateReviewSetEmployeeSentiment {
  type: CreateReviewActionType.SetEmployeeSentiment;
  sentimentId: number;
}

export interface CreateReviewChangeScreen {
  type: CreateReviewActionType.UpdateScreen;
  showPreviousScreen: boolean;
}

export interface CreateReviewReplaceUserState {
  type: CreateReviewActionType.ReplaceUserState;
  state: CreateReviewState['user'];
}

export interface CreateReviewRestoreUserStateFromLocalStorage {
  type: CreateReviewActionType.RestoreUserStateFromLocalStorage;
}

export type CreateReviewAction =
  | CreateReviewRequestAction
  | CreateReviewSuccessAction
  | CreateReviewFailAction
  | CreateReviewSetOverallRating
  | CreateReviewSetVenueRating
  | CreateReviewSetTreatmentRating
  | CreateReviewSetEmployeeRating
  | CreateReviewSetPublicReviewComment
  | CreateReviewSetPublicReviewCommentError
  | CreateReviewSetPublicReviewName
  | CreateReviewSetPublicReviewIsAnonymous
  | CreateReviewSetEmployeeSentiment
  | CreateReviewChangeScreen
  | CreateReviewReplaceUserState
  | CreateReviewSetPublicReviewerNameError;

export function mapStateToPayload(
  reviewState: CreateReviewSelector
): CreateReviewPayload {
  const name = reviewState.reviewer.name.trim();
  const treatmentRatings: { [treatmentId: number]: number } = {};
  const venueRatings: { [facet: string]: number } = {};
  const sentiments: { [sentimentType: string]: {} } = {};

  Object.keys(reviewState.treatmentRatings).forEach(treatmentId => {
    const rating = reviewState.treatmentRatings[+treatmentId];

    if (rating !== 0) {
      treatmentRatings[+treatmentId] = rating!;
    }
  });

  Object.keys(reviewState.venueRatings).forEach(facet => {
    venueRatings[facet.toUpperCase()] = reviewState.venueRatings[facet]!;
  });

  if (
    reviewState.employee &&
    reviewState.sentiments.employee &&
    reviewState.sentiments.employee.length > 0
  ) {
    const selectedSentiments = reviewState.sentiments.employee.filter(
      sentiment => sentiment.selected
    );

    sentiments.employee = {
      [reviewState.employee.id]: selectedSentiments.map(selected => ({
        id: selected.id,
      })),
    };
  }

  return {
    reviewer: {
      name,
      anonymous: false,
      marketingOptIns: {
        venueOptIn: false,
        treatwellOptIn: false,
      },
    },
    rating: reviewState.rating,
    venueRatings,
    sentiments,
    treatmentCategoryRatings: treatmentRatings,
    content: reviewState.publicReviewComment.content.trim(),
    referenceToken: reviewState.referenceToken,
    notifyOnReply: true,
  };
}

export function createReviewRequest(
  reviewState: CreateReviewSelector
): ThunkAction<Promise<unknown>, ReduxState, unknown, Action> {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, Action>) => {
    const reviewPayload = mapStateToPayload(reviewState);
    const venueId = reviewState.venue.id;

    dispatch({
      type: CreateReviewActionType.Request,
      reviewPayload,
    });

    try {
      const review = await postReview(reviewPayload, venueId);
      const rating = review?.rating ?? 0;
      const canRefer = reviewState.referralEntryPoint.canRefer && rating >= 4;
      const response: ReviewConfirmationModel = {
        ...review!,
        referralEntryPoint: { canRefer },
      };

      if (canRefer) {
        const fetchPromotionInfo = composeFetchPromotionInfo();
        const fetchReferralInviteUrl = composeFetchReferralInviteUrl();
        const [inviteUrlResponse, promotionInfo] = await Promise.allSettled([
          fetchReferralInviteUrl(),
          fetchPromotionInfo(),
        ]);
        const canShowReferralCard =
          inviteUrlResponse.status === 'fulfilled' &&
          promotionInfo.status === 'fulfilled';

        if (
          canShowReferralCard &&
          inviteUrlResponse.value?.result === INVITE_URL_SUCCESS_RESPONSE
        ) {
          response.referralEntryPoint = {
            ...response.referralEntryPoint,
            shareLink: inviteUrlResponse.value.inviteUrl,
            promotionInfo: promotionInfo.value,
          };
        } else {
          response.referralEntryPoint = {
            ...response.referralEntryPoint,
            canRefer: false,
          };
        }
      }

      return dispatch({
        type: CreateReviewActionType.RequestSuccess,
        response,
      });
    } catch (error) {
      return dispatch({
        type: CreateReviewActionType.RequestFailure,
        error,
      });
    }
  };
}

export function createReviewSetOverallRating(
  rating: number
): CreateReviewSetOverallRating {
  return {
    type: CreateReviewActionType.SetOverallRating,
    rating,
  };
}

export function createReviewSetVenueRating(
  facet: string,
  rating: number
): CreateReviewSetVenueRating {
  return {
    type: CreateReviewActionType.SetVenueRating,
    facet,
    rating,
  };
}

export function createReviewSetTreatmentRating(
  treatmentId: number,
  rating: number
): CreateReviewSetTreatmentRating {
  return {
    type: CreateReviewActionType.SetTreatmentRating,
    treatmentId,
    rating,
  };
}

export function createReviewSetEmployeeRating(
  rating: number
): CreateReviewSetEmployeeRating {
  return {
    type: CreateReviewActionType.SetEmployeeRating,
    rating,
  };
}

export function createReviewSetPublicReviewComment(
  comment: string
): CreateReviewSetPublicReviewComment {
  return {
    type: CreateReviewActionType.SetPublicReviewComment,
    comment,
  };
}

export function createReviewSetPublicReviewCommentError(
  error: string
): CreateReviewSetPublicReviewCommentError {
  return {
    type: CreateReviewActionType.SetPublicReviewCommentError,
    error,
  };
}

export function createReviewSetPublicReviewName(
  name: string
): CreateReviewSetPublicReviewName {
  return {
    type: CreateReviewActionType.SetPublicReviewName,
    name,
  };
}

export function createReviewSetPublicReviewerNameError(
  error: boolean
): CreateReviewSetPublicReviewerNameError {
  return {
    type: CreateReviewActionType.SetPublicReviewNameError,
    error,
  };
}

export function createReviewSetPublicReviewIsAnonymous(
  isAnonymous: boolean
): CreateReviewSetPublicReviewIsAnonymous {
  return {
    type: CreateReviewActionType.SetPublicReviewIsAnonymous,
    isAnonymous,
  };
}

export function createReviewSetEmployeeSentiment(
  sentimentId: number
): CreateReviewSetEmployeeSentiment {
  return {
    type: CreateReviewActionType.SetEmployeeSentiment,
    sentimentId,
  };
}

export function createReviewChangeScreen(
  showPreviousScreen: boolean
): CreateReviewChangeScreen {
  return {
    type: CreateReviewActionType.UpdateScreen,
    showPreviousScreen,
  };
}

export function createReviewReplaceUserState(
  state: CreateReviewState['user']
): CreateReviewReplaceUserState {
  return {
    type: CreateReviewActionType.ReplaceUserState,
    state,
  };
}

export function createReviewRestoreUserStateFromLocalStorage(): CreateReviewRestoreUserStateFromLocalStorage {
  return {
    type: CreateReviewActionType.RestoreUserStateFromLocalStorage,
  };
}
