type PushState = typeof history.pushState;

type Callback = () => void;
type Unsubscribe = () => void;

const subscriptions: Callback[] = [];
let subscribedToPopstate = false;

function callSubscribers(): void {
  subscriptions.forEach(subscription => subscription());
}

export function historySubscribe(callback: Callback): Unsubscribe {
  subscriptions.push(callback);

  if (!subscribedToPopstate) {
    subscribedToPopstate = true;
    window.addEventListener('popstate', callSubscribers);
  }

  return () => {
    subscriptions.splice(subscriptions.indexOf(callback), 1);
  };
}

export const historyPush: PushState = (data, title, url) => {
  history.pushState(data, title, url);

  callSubscribers();
};
