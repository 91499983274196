import React from 'react';
import {HookProps, useViewport, useViewportLayout} from './useViewport';

export type Props = HookProps & {children: React.ReactNode};

export const Viewport = ({children, ...restProps}: Props) => {
  const isMatched = useViewport({...restProps});
  return isMatched ? <>{children}</> : null;
};

export const ViewportLayout = ({children, ...restProps}: Props) => {
  const isMatched = useViewportLayout({...restProps});
  return isMatched ? <>{children}</> : null;
};
