/* eslint-disable @typescript-eslint/no-explicit-any */
import { isBrowser } from 'js/helpers/environment';
import { createReviewPersistUnfinishedMiddleware } from 'js/redux-modules/create-review/persist-unfinished-middleware';
import * as redux from 'redux';
import { combineReducers, compose, applyMiddleware, Store } from 'redux';
import venuePage, { initVenuePage } from 'js/redux-modules/venue-page';
import { account } from 'js/redux-modules/account';
import {
  createReviewReducer,
  initCreateReview,
} from 'js/redux-modules/create-review';
import { layout, initLayout } from 'js/redux-modules/layout';
import { venuesModule } from 'js/redux-modules/browse-page/venues-module';
import thunk from 'redux-thunk';
import { PageData } from 'server/routes/page/types';

export function createStore(data: PageData): Store {
  const appReducer = combineReducers({
    venuePage,
    account,
    layout,
    createReview: createReviewReducer,
    venuesModule,
  });

  const state = {
    venuePage: initVenuePage(data),
    layout: initLayout(data),
    createReview: initCreateReview(data.createReview),
  };

  const middlewares = applyMiddleware(
    thunk,
    createReviewPersistUnfinishedMiddleware
  );

  const composeMiddlewares: typeof compose =
    isBrowser && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: true,
        })
      : compose;
  // tslint:enable:no-any

  const store = redux.createStore(
    appReducer,
    state,
    composeMiddlewares(middlewares)
  );

  return store;
}
