import React from 'react';
import Url from 'url-parse';
import { parseUri } from 'js/helpers/uri-util';
import { historySubscribe } from 'js/helpers/history';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';

export const urlContext = React.createContext<UrlWithTreatwell>(
  {} as UrlWithTreatwell
);

export interface UrlWithTreatwell extends Url {
  treatwell: {
    pageType: string;
    values: {
      [key: string]: string[];
    };
  };
}

interface Props {
  channel: Channel;
  url: string;
  children: React.ReactNode;
}

interface State {
  url: string;
}

export class UrlProvider extends React.Component<Props, State> {
  private unsubscribeFromHistory: (() => void) | undefined;

  public state = {
    url: this.props.url,
  };

  public componentDidMount(): void {
    this.unsubscribeFromHistory = historySubscribe(() => {
      this.setState({
        url: window.location.href,
      });
    });
  }

  public componentWillUnmount(): void {
    if (this.unsubscribeFromHistory) {
      this.unsubscribeFromHistory();
    }
  }

  public render(): React.ReactNode {
    const url = new Url(this.state.url, true) as UrlWithTreatwell;
    const query = String(new Url(this.state.url, false).query);
    const treatwellUrl = parseUri(
      url.pathname + query,
      this.props.channel.code,
      this.props.channel.languageCode
    );

    if (treatwellUrl) {
      url.treatwell = treatwellUrl;
    }

    return (
      <urlContext.Provider value={url}>
        {this.props.children}
      </urlContext.Provider>
    );
  }
}
