import {createContext, useContext} from 'react';
import useMedia, {useMediaLayout} from 'use-media';

type Device = 'mobile' | 'tablet' | 'desktop';
export type HookProps = {
  device: Device | Device[];
  serverRender?: boolean;
};

const createMediaQueryLookup = (mobileMax: number, tabletMax: number) => {
  return {
    mobile: `(max-width: ${mobileMax}px)`,
    tablet: `(min-width: ${mobileMax + 1}px) and (max-width: ${tabletMax}px)`,
    desktop: `(min-width: ${tabletMax + 1}px)`,
  };
};

const viewportMatchesMediaQuery = (
  {device, serverRender = false}: HookProps,
  mediaQueryListener: (mediaQuery: string, defaultState?: boolean) => boolean,
  {mobileMax, tabletMax}: {mobileMax: number; tabletMax: number}
): boolean => {
  const mediaQueryLookup = createMediaQueryLookup(mobileMax, tabletMax);
  const devices: Device[] = Array.isArray(device) ? device : [device];
  const mediaQuery = devices.map((item) => mediaQueryLookup[item]).join(',');
  const isMatched = mediaQueryListener(mediaQuery, serverRender);

  if (serverRender && typeof window === 'undefined') {
    return true;
  }

  return isMatched;
};

const ViewportContext = createContext({mobileMax: 520, tabletMax: 991});
export const ViewportProvider = ViewportContext.Provider;

export function useViewport({device, serverRender}: HookProps): boolean {
  const {mobileMax, tabletMax} = useContext(ViewportContext);
  return viewportMatchesMediaQuery({device, serverRender}, useMedia, {
    mobileMax,
    tabletMax,
  });
}

export function useViewportLayout({device, serverRender}: HookProps): boolean {
  const {mobileMax, tabletMax} = useContext(ViewportContext);
  const useIsomorphicMediaQueryListener =
    typeof window !== 'undefined' ? useMediaLayout : useMedia;

  return viewportMatchesMediaQuery(
    {device, serverRender},
    useIsomorphicMediaQueryListener,
    {
      mobileMax,
      tabletMax,
    }
  );
}
