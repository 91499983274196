import get from 'lodash.get';
import { isTest } from 'js/helpers/environment';
import { Dictionary } from 'js/types/generic-types';

type i18nValue = string | object | null;

export function substitute(s: string, ...args: string[]): string {
  return args
    .map((arg, index) => ({ x: `{${index}}`, y: arg }))
    .reduce((acc, { x, y }) => acc.replace(x, y), s || '');
}

export function substituteWithDictionary(
  template: string,
  dictionary: Dictionary<string>
): string {
  return template.replace(
    /{(.+?)}/g,
    (match: string, key: string) => dictionary[key] ?? match
  );
}

export function interpolate<T>(
  key: string,
  value: T,
  optional: boolean,
  args: string[]
): T {
  if (value === null && !optional) {
    console.warn(new Error(`No value found for cms item "${key}"`).stack);
    return value;
  }
  if (typeof value === 'string') {
    if (args && args.length > 0) {
      // through type guard we know that value type T is string therefore we can return string as T
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (substitute(value, ...args) as any) as T;
    }
    return value;
  }
  return value;
}

function resolve(
  pageData: Object,
  stringKey: string | null,
  optional: boolean,
  args: string[]
): i18nValue {
  if (stringKey === null) {
    return null;
  }

  const value: unknown = get(pageData, stringKey);

  if (
    typeof value !== 'string' &&
    typeof value !== 'object' &&
    !isTest() &&
    !optional
  ) {
    console.warn(
      `retrieved value "${value}" for "${stringKey}" from pageData is not string or object`
    );
  }

  return interpolate(stringKey, value as string, optional, args);
}

export function i18n(
  pageData: Object,
  stringKey: string | null,
  ...args: string[]
): i18nValue {
  return resolve(pageData, stringKey, false, args);
}

export function i18nOptional(
  pageData: Object,
  stringKey: string | null,
  ...args: string[]
): i18nValue {
  return resolve(pageData, stringKey, true, args);
}
