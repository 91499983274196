import { HeaderContainer, Inline } from '@treatwell/ui';

import React from 'react';
import styles from './VenuePageHeaderMobile.module.css';

type VenueExperimentProps = {
  navigateAnchorLink: React.ReactElement;
  venueHeaderLinks: React.ReactElement;
};

export const VenuePageHeaderMobile = ({
  navigateAnchorLink,
  venueHeaderLinks,
}: VenueExperimentProps) => {
  return (
    <div className={styles.root}>
      <HeaderContainer data-cy="VenueExperimentHeader">
        <div className={styles.venueExperimentHeaderContainer}>
          <Inline justify="between" align="center" className={styles.header}>
            {navigateAnchorLink}
            {venueHeaderLinks}
          </Inline>
        </div>
      </HeaderContainer>
    </div>
  );
};
