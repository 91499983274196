import {
  ApiErrorInstanceOutput,
  isApiErrorInstanceOutput,
} from 'js/model/rainbow/page/ApiErrorInstanceOutput';
import { isDictionary, isTypedArray } from 'js/types/generic-types';
import { isString } from 'js/types/primitive-predicates';

export interface ApiErrorOutput {
  errors: ApiErrorInstanceOutput[];
  origin: string;
}

export function isApiErrorOutput(
  candidate: unknown
): candidate is ApiErrorOutput {
  return (
    isDictionary(candidate) &&
    isTypedArray(candidate.errors, isApiErrorInstanceOutput) &&
    isString(candidate.origin)
  );
}
