import React from 'react';
import { Colors, colors } from '@treatwell/design-tokens';
import clsx from 'clsx';

export type ChevronColour = keyof Pick<
  Colors,
  | 'white'
  | 'bloomBaseCoral600'
  | 'bloomBaseNavy800'
  | 'bloomGreyNavy300'
  | 'bloomGreyNavy500'
  | 'bloomGreyNavy700'
  | 'bloomBlue700'
>;

interface Props {
  className?: string; // Used only as an escape hatch to work with Button's icon prop. Other uses must be avoided.
  containerStyle?: {};
  colour?: ChevronColour;
  size?: Size;
  direction?: Direction;
  animated?: boolean;
  disabled?: boolean;
}

export enum Size {
  Small = 16,
  Medium = 24,
}

export enum Direction {
  Down = 0,
  Left = 90,
  Up = 180,
  Right = 270,
}

export function Chevron(props: Props): React.ReactElement {
  const {
    className,
    containerStyle,
    colour,
    size,
    direction,
    animated,
    disabled,
  } = props;

  function getInlineStyles(): { [key: string]: string } {
    const inlineStyles: { [key: string]: string } = {
      cursor: 'pointer',
      transform: `rotate(${direction}deg)`,
    };

    if (animated) {
      inlineStyles.transition = 'transform 0.2s ease-out';
    }

    if (disabled) {
      inlineStyles.cursor = 'default';
      inlineStyles.pointerEvents = 'none';
      inlineStyles.opacity = '0.6';
    }

    return inlineStyles;
  }

  function getStrokeColour(): string | undefined {
    return colour && colors[colour];
  }

  return (
    <svg
      data-cy="chevron"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={{ ...getInlineStyles(), ...containerStyle }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          style={{ stroke: getStrokeColour() }}
          className={clsx(className)}
          strokeWidth="2"
          d="M5 8l7 7 7-7"
        />
      </g>
    </svg>
  );
}

Chevron.defaultProps = {
  className: '',
  colour: 'bloomBaseNavy800',
  size: Size.Medium,
  direction: Direction.Down,
  animated: false,
  disabled: false,
};
