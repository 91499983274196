export const ALL_TREATMENT_TYPES_ID = 0;
export const BODY_ID = 2;
export const FACE_ID = 4;
export const HAIR_ID = 6;
export const NAILS_ID = 36;
export const MASSAGE_ID = 37;
export const HAIR_REMOVAL_ID = 43;
export const MEDICAL_DENTAL_ID = 42;
export const YOGA_PILATES_ID = 44;
export const DANCE_ID = 32;
export const FITNESS_ID = 38;
export const PHYSICAL_THERAPY_ID = 45;
export const COUNSELLING_HOLISTIC_ID = 41;
