import { CreateReviewDataState } from 'js/model/state/createReview';
import {
  CreateReviewAction,
  CreateReviewActionType,
} from 'js/redux-modules/create-review/actions';
import { initCreateReviewDataState } from 'js/redux-modules/create-review/initial';

export function dataReducer(
  state: CreateReviewDataState | undefined,
  action: CreateReviewAction
): CreateReviewDataState {
  if (!state) {
    return initCreateReviewDataState(undefined);
  }

  switch (action.type) {
    case CreateReviewActionType.RequestSuccess:
      return {
        ...state,
        discount: action.response?.discount,
        referralEntryPoint: action.response?.referralEntryPoint ?? {
          canRefer: false,
        },
      };

    default:
      return state;
  }
}
