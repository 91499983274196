import React from 'react';
import {createContext, useContext} from 'react';
const DeviceContext = createContext<string | null>(null);

export const DeviceProvider = ({
  userAgent,
  children,
}: {
  userAgent: string;
  children: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <DeviceContext.Provider value={userAgent}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (context === null) {
    throw new Error('useDeviceContext must be used within a DeviceProvider');
  }
  return context;
};
