import React from 'react';
import {Inline} from '../../Layout';
import MarketplaceLogo from './treatwell-marketplace-logo.inline.svg';
import styles from './MarketplaceHeader.module.css';

export type MarketplaceHeaderProps = {
  onLogoClick?: (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => void | Promise<void>;
  logoHref: string;
  logoAriaLabel?: string;
  'data-cy'?: string;
};

export const MarketplaceHeader = ({
  onLogoClick,
  logoHref,
  logoAriaLabel,
  'data-cy': dataCy = 'MarketplaceLogo',
}: MarketplaceHeaderProps) => {
  return (
    <Inline justify="start" data-cy={dataCy}>
      <a
        href={logoHref}
        onClick={onLogoClick}
        aria-label={logoAriaLabel}
        className={styles.link}
      >
        <div className={styles.logo}>
          <MarketplaceLogo />
        </div>
      </a>
    </Inline>
  );
};

export type HeaderContainerProps = {
  children: React.ReactNode;
  'data-cy'?: string;
};

export const HeaderContainer = ({
  children,
  'data-cy': dataCy,
}: HeaderContainerProps) => {
  return (
    <header className={styles.headerContainer} data-cy={dataCy}>
      {children}
    </header>
  );
};

export {MarketplaceLogo};
